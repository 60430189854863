@import '../../flags.scss';

.SportEvent {
    font-size: 14px;
    padding: 0 4px;
    margin-top: 20px;

    .odd-button {
        width: 66px;
        height: 100%;
        //margin-left: 2px;

        &.active {
            .odd-button__inner {
                position: absolute;
                border-bottom: 1px solid var(--white);
                width: 100%;
                z-index: 3;
                height: calc(100% + 1px);
            }
        }
    }
    &__market:last-child {
        border-bottom: 1px solid #ddd;
    }
    &__market {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1px solid #ddd;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;

        &__row {
            display: flex;
            width: 100%;
            //height: 41px;
            height: 43px;
            align-items: center;
            background: var(--white);
            //padding: 1px 2px 1px 11px;
            position: relative;
            color: var(--list-text);
            //border-bottom: 1px solid var(--border);
            //transition: background-color ease 0.3s;
        }
        //&__row:hover {
        //    background-color: #EEE;
        //}

        &__title {
            height: 100%;
            //width: calc(100% - 136px);
            display: flex;
            padding-right: 4px;
            font-size: 14px;
            justify-content: space-between;
            // min-height: 43px;
            align-items: center;
            font-weight: 700;
            padding-left: 5px;
            margin-right: auto;


            &__text {
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: row;
                font-weight: 700;
            }

            &__exposure {
                display: flex;
                padding-left: 10px;
                font-family: 'Lato';
                font-weight: 700;
                font-size: 12px;

                .positive {
                    color: #519D43;
                }

                .negative {
                    color: #CA5C54;
                }
            }
        }

        &__status {
            width: 134px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 2px;
            font-family: 'Lato', sans-serif !important;
            color: #1e3f5a;
            background: rgba(30, 63, 90, 0.1);
            font-weight: 700;
            font-size: 12px;
        }

        &__statuslabel {
            width: 136px;
            position: absolute;
            right: 0;
            text-align: center;
            z-index: 1;
            opacity: .5;
            font-size: 12px;
        }
    }
    .SportEvent__market__title__text div span:last-child {
        padding-left: 10px;
    }
}

@media screen and (max-width: 1024px) {
    .favourites-header__rules-button-wrapper {
        margin: 0 0 0 auto!important;
    }
    .SportEvent__market__title {
        width: calc(100% - 136px);
    }
}
