.LiveSection {
    margin-bottom: 10px;

    .not-auth {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        //height: 300px;
        //background: #142326;
        height: 100%;
        background-color: var(--non-auth-bg);
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        min-height: 309px;


        h1 {
            color: #8D8D8D;
            font-size: 30px;
            line-height: 36px;
        }

        h2 {
            color: #B3B3B3;
            font-size: 35px;
            line-height: 42px;
            border-bottom: 1px solid #B3B3B3;
        }
    }

    &__btn {
        margin: 0;
        width: 100%;
        border-radius: 0;
        --border-radius: 0;
        text-transform: capitalize;

        &:hover {
            --background: #327eff;
        }
    }

    &__tv-icon {
        width: 12px;
        height: 10px;
        margin-left: 7px;
        font-family: 'apollo';

        &:before {
            content: "";
        }
    }

    &__content {
        background-color: var(--event-visualisation-background);
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;
        height: calc(100vw / 16 * 9);
        max-height: 309px;
        //max-height: 352px;
        overflow-y: auto;

        &__video {
            width: 100%;
            max-width: 550px;
            // min-height: 211px;
            height: calc(100vw / 16 * 9);
            max-height: 309px;

            &.tennis {
                height: 363px;
                max-height: unset;
                @media (min-width: 380px) {
                    height: 400px;
                }
                @media (min-width: 600px) {
                    height: 471px;
                }
            }
            &.cricket {
                height: 149px;
            }
            &.football {
                height: 363px;
                max-height: unset;
                @media (min-width: 380px) {
                    height: 400px;
                }
                @media (min-width: 600px) {
                    height: 471px;
                }
            }
            //max-height: 352px;

            // @media(max-width: 500px) {
            //     min-height: 270px;
            // }

            // @media(max-width: 430px) {
            //     // min-height: 225px;
            //     min-height: 211px;
            // }

            // @media(max-width: 400px) {
            //     // min-height: 225px;
            //     min-height: 211px;
            // }

            // @media(max-width: 375px) {
            //     // min-height: 210px;
            //     min-height: 211px;
            // }

            // @media(max-width: 350px) {
            //     min-height: 195px;
            // }

            video {
                height: auto;
                height: calc(100vw / 16 * 9);
                max-height: 308px;
                //max-height: 352px;
            
            }
        }
    }

    .LiveSection__content.LiveSection__content-withStats {
        .cricket-match-stats {
            //width: 50%;

            //width: 50%;
            .cricket-match-stats-over__title {
                white-space: unset;
                overflow: unset;
                text-overflow: unset;
            }
        }

        @media (min-width: 1024px) {
            iframe.LiveSection__content__video {
                width: 50%;
            }
        }

        .cricket-match-stats.statsInVideoTab {
            .cricket-match-stats-slides {
                height: 100%;
                display: flex;
                align-items: center;

                .cricket-match-stats-over {
                    border: none;
                }
            }
        }
    }
    //
    .LiveSection__content.LiveSection__content-withStats {
        .cricket-match-stats.statsInVideoTab {
            .cricket-match-stats-slides {
                .swiper-wrapper {
                    padding-bottom: 19px;
                }
            }
        }
    }

    .LiveSection__content__video.LiveSection__content__video-withStats {
        //height: auto!important;
        //max-height: none;
        max-height: 212px;
    }
}

.LiveSection.nonAuthorizedLiveSection {
    background-color: var(--event-visualisation-background);
    height: calc(100vw / 16 * 9);
    max-height: 309px;

    .cricket-match-stats {
        height: 100%;
        width: 100%;
        max-width: 550px;
        margin: 0 auto;
    }
}

.widgets {
    max-width: 550px;
    width: 100%;
}

.sr-widget-title {
    font-weight: bold;
    padding-bottom: 4px;
}

@media screen and (max-width: 1024px) {
    .LiveSection.virtual {
        // height: 380px;

        .LiveSection__content {
            height: 100%;
            height: 380px;
            max-height: unset;

            .LiveSection__content__video {
                max-width: unset;
                height: 380px;
                max-height: unset;
            }
        }
    }

    .LiveSection.virtual.dragon {
        height: 383px;
    }
}

@media (max-width: 767px) {
    .LiveSection {
        .not-auth {
            img {
                width: 40px;
                height: 40px;
            }

            h1 {
                font-size: 24px;
            }

            h2 {
                font-size: 30px;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .widgets {
        max-width: unset;
    }

    .LiveSection.virtual {
        // height: 380px;

        .LiveSection__content {
            height: 100%;
            height: 380px;

            .LiveSection__content__video {
                max-width: unset;
                height: 380px;
            }
        }
    }
}

.eventPageFixLiveSection {
    height: auto !important;
    max-height: none;
}
