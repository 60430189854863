@media (min-width: 1024px) {
  .MyOpenBets {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: bold;
    color: black;
    padding: 0 10px 0 15px;
    .page-title {
      padding-bottom: 15px;
      border-bottom: 1px solid #9AA1A4;
      font-size: 24px;
      margin: 15px auto 12px 0;
      width: 100%;
      max-width: calc(100% - 292px);
    }

    &__page {
      &-title-wrapper {
        position: relative;
        margin: 0 !important;

      }
      &-menu {
       display: flex;
       margin-bottom: 60px;
       min-height: 72px;
        &-buttons-wrapper {
          margin-right: 60px;
        }
        &-buttons-row {
          display: flex;
          margin-top: 10px;
          .MyOpenBets__page-menu-button {
            width: 100px;
            height: 26px;
            padding: 0 10px;
            border: 1px solid #0F2327;
            box-sizing: border-box;
            border-radius: 3px;
            cursor: pointer;
            span {
              font-family: 'Lato';
              font-weight: 400;
              font-size: 12px;
              color: #0F2327;
            }
          }
          .MyOpenBets__page-menu-button:last-child {
            margin-left: 20px;
          }
          .MyOpenBets__page-menu-button:hover {
            background: #CAE0E8;
          }
          .MyOpenBets__page-menu-button.active {
            background: #CAE0E8;
          }
        }
        &-buttons-row-time {
          .MyOpenBets__page-menu-button:hover {
            background: #0F2327;
            span {
              color: #FFFFFF;
            }
          }
          .MyOpenBets__page-menu-button.active {
            background: #0F2327;
            span {
              color: #FFFFFF;
            }
          }
        }
      }
      //date block Start
      &-menu-filter-content {
        display: flex;
        align-items: flex-end;
        position: relative;
        &-error {
          position: absolute;
          bottom: -20px;
          left: 0;
          font-size: 12px;
          color: #d01901;
        }
        &-date-input {
          margin-right: 23px;
        }
        p {
          margin: 0 0 10px 0;
          font-family: 'Lato';
          font-weight: 400;
          font-size: 12px;
        }
        ion-item {
          padding: 0 !important;
          padding-inline-start: 0 !important;
          padding-inline-end: 0 !important;
          --padding-start: 0px !important;
          --inner-padding-end: 0 !important;
          --padding-top: 0 !important;
          --padding-end: 0 !important;
          --padding-bottom: 0 !important;
          --min-height: 29px;
          .item-native {
            padding: 0 !important;
            padding-inline-start: 0 !important;
            padding-inline-end: 0 !important;
            --padding-start: 0 !important;
            --inner-padding-end: 0 !important;
          }
        }

        ion-datetime {
          --padding-start: 20px !important;
          --inner-padding-end: 0 !important;
          --padding-top: 0 !important;
          --padding-end: 0 !important;
          --padding-bottom: 0 !important;
          font-family: 'Lato';
          font-weight: 400;
          font-size: 11px;
          color: #686868;
        }
        .item-datetime {
          width: 100px;
          height: 29px;
          .in-item {
            width: 100%;
          }
          .item-native {
            padding-left: 0;
          }
          button {
            width: 100%;
          }
        }
      }
      &-menu-date-search-button {
        background: var(--cta-primary-darken);
        border-right: 2px solid rgba(0, 0, 0, 0.25);
        border-bottom: 2px solid rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        width: 73px;
        height: 29px;
        font-weight: 700;
        font-size: 12px;
        color: #FFFFFF;
        padding: 0 5px;
        display: inline-flex;
        align-items: center;
        justify-content: space-evenly;
        &:hover{
          background-color: var(--secondary);
          box-shadow: 1.5px 1.5px 0px 0px var(--cta-primary-darken);
        }
        &:active{
          background-color: var(--secondary);
          border-top: 2px solid rgba(0, 0, 0, 0.25);
          border-left: 2px solid rgba(0, 0, 0, 0.25);
          box-shadow: none;
        }
      }
      &-menu-date-search-button:disabled {
        background: #DDDDDD;
        box-shadow: none;
      }
      //date block END 1
    }
    ion-backdrop {
      --ion-backdrop-color: rgba(0, 0, 0, 0.3) !important;
    }
    ion-picker {
      transform: none !important;

      .picker-wrapper.sc-ion-picker-md {
        top: 30%;
        bottom: auto;
      }
    }
    //date block END 2
  }
}

@media (max-width: 1024px) {
  .MyOpenBets {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: bold;
    &__list {
      margin-top: 32px;
      background: transparent;
      padding: 0 15px;
      &__header {
        font-size: 18px;
        padding-inline-start: 0;
      }
      &__empty {
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
      }
      &__item {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        position: relative;
        &__icon {
          position: relative;
          top: -3px;
          margin-right: 15px;
        }
      }
    }
  }
}
