.RulesRegs {
    padding: 0 20px;
    color: #000000;

    .example {
        display: block;
        font-style: italic;
        color: #666;
        margin: 13px 0;
    }

    li {
        margin: 5px 0;
    }
}

