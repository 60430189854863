@import '../../styles.scss';

.right-side-menu {
  --width: var(--menu-width);
  pointer-events: auto !important;
  cursor: auto;
  // margin-right: -245px; // var(--menu-width); fix for bug with menu

  &__top {
    min-height: calc(100vh - 49px);
  }

  &__username {
    margin: 0 15px;
    padding: 24px 0 19px;
    font-weight: 700;
    border-bottom: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
    text-align: center;
    font-size: 20px;
  }

  &__list {
    >:first-child  {
      --border-width: 1px 0 0 0;
    }
  }

  &__item {
    @include menu-item();

    &:first-child {
      padding-top: 0 !important;
    }

    .item-inner {
      padding-inline-start: 15px;
    }

    &__icon {
      margin-left: 10px;
      font-family: 'apollo';
      color: var(--menu-icon-color);
    }

    &__label {
      font-weight: bold !important;
    }
    
    &__inCompleteProfile{
      width: 40%;
      height: 19px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(109deg, rgba(222, 73, 89, 0.10) 0.31%, rgba(243, 93, 82, 0.10) 100.31%);
      span{
        background: linear-gradient(109deg, #DE4959 0.31%, #F35D52 100.31%);

        font-family: 'Lato';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
      }
      &.completed{
        background: rgba(0, 90, 82, 0.10);
        color: #005A52;
        span{
          -webkit-text-fill-color: unset;
          background: unset;
        }
      }
    }

    &--balance {
      cursor: default;
      --inner-border-width: 0;
      img.right-side-menu__item__icon {
        width: 16px !important;
        height: 14px;
        margin-top: 2px;
        margin-right: 3px;
      }
      .right-side-menu__item__icon-wrapper {
        display: flex;
        position: relative;
        img {
          opacity: 0;
        }
      }
      .right-side-menu__item__icon-wrapper:after {
        content: '';
        position: absolute;
        left: 0;
        transform: translateX(50%);
        width: 16px;
        height: 15px;
        background-color: var(--menu-icon-color);
        mask-image: url("../../assets/images/menu-icons/gift1.svg");
        mask-size: 15px;
        mask-repeat: no-repeat;
        mask-position: center;
      }
      .popUpButton {
        width: 21px;
        height: 21px;
        border-radius: 50%;
        border: 2px solid var(--primary);
        color: var(--primary);
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        position: relative;
      }
      .popUpButton:after {
        content: "";
        position: absolute;
        width: 9px;
        height: 9px;
        background-color: var(--negative-secondary-exchange);
        border-radius: 50%;
        right: -14%;
        top: -14%;
      }
    }

    &--bottom {
      border-top: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
    }
  }

  &__account__info {
    padding: 5px 20px 0px;
    margin: 0 7px;
    font-size: 14px;

    &__item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      &__value {
        &--green {
          color: var(--positive);
          &::before{
            content: '+'
          }
        }

        &--red {
          color: var(--negative);
          // &::before{
          //   content: '-'
          // }
        }
      }
    }
  }

  &__depositButtons{
    display: flex;
    justify-content: space-between;
    //margin: 0 20px 20px 20px;
    margin: 20px 20px 0 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    &-dep{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35px;
      width: 96px;
      background: var(--alt-secondary);
      // background: #86AA16;
      border-radius: 4px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: var(--white);
      //&::before{
      //  content: "";
      //  width: 23px;
      //  height: 23px;
      //  background-color: var(--icon-color-in-buttons);
      //  mask-image: url("../../assets/images/deposit-Icon.svg");
      //  margin: 0px 2px;
      //  position: relative;
      //  left: -4px;
      //  top: 2px;
      //  mask-size: auto;
      //  mask-repeat: no-repeat;
      //}
    }
    &-with{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35px;
      width: 108px;
      // background: #86AA16;
      background: var(--alt-secondary);
      border-radius: 4px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: var(--white);

      //&::before{
      //  content: '';
      //  content: "";
      //  width: 23px;
      //  height: 23px;
      //  background-color: var(--icon-color-in-buttons);
      //  mask-image: url("../../assets/images/withdrawal-icon.svg");
      //  margin: 0px 2px;
      //  position: relative;
      //  left: -3px;
      //  mask-size: auto;
      //  mask-repeat: no-repeat;
      //}
    }
  }
  .newTogglerBonusMenu {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    padding: 5px 20px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 7px;
    margin-bottom: 10px;

    &__oneClick{
      font-size: 14px;

    }
    .switcher {
      position: relative;
      display: inline-block;
      width: 55px;
      height: 23px;
    }

    /* Hide default HTML checkbox */
    .switcher input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgb(255, 255, 255);
      border: 1px solid #969696;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: '';
      height: 21px;
      width: 21px;
      left: -1px;
      bottom: -1px;
      background-color: white;
      border: 1px solid #969696
      ;
      -webkit-transition: 0.4s;
      transition: 0.2s;
    }

    input:checked + .slider {
      background-color: inherit;
      &::after {
        position: relative;
        z-index: 0;
        float: left;
        top: 5px;
        left: 6px;
        content: 'ON';
        color: #257B24;
      }
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #3c3f42;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(32px);
      -ms-transform: translateX(32px);
      transform: translateX(32px);
      background-color: #257B24;
      border: 1px solid #257B24
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
      &::after {
        content: 'OFF';
        position: relative;
        top: 5px;
        right: 5px;
        z-index: 0;
        float: right;
        transition-delay: 0.1s;
        color: #656565;
      }
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }

  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      margin: 5px;
      width: 160px;
      height: 38px;
      background-repeat: 3px;
    }
  }
  .right-side-menu__item__icon.menu-icon {
      position: relative;
      margin: 0 10px 0 5px;
    &:before {
      content: '';
      position: absolute;
      display: inline-block;
      width: 25px;
      height: 25px;
      background-color: #FFFFFF;
      background-repeat: no-repeat;
      background-position: center;
   }
    &.policy {
      &:before {
        background-color: var(--menu-icon-color);
        mask-image: url("../../assets/images/menu-icons/policyNew.svg");
        -webkit-mask-image: url("../../assets/images/menu-icons/policyNew.svg");
        mask-size: 19px;
        mask-repeat: no-repeat;
        -webkit-mask-position-x: 3px;
        mask-position-x: 3px;
      }
    }
    &.privacy-policy {
      &:before {
        background-color: var(--menu-icon-color);
        mask-image: url("../../assets/images/menu-icons/privacyPolicy.svg");
        -webkit-mask-image: url("../../assets/images/menu-icons/privacyPolicy.svg");
        mask-size: 19px;
        mask-repeat: no-repeat;
        -webkit-mask-position-x: 3px;
        mask-position-x: 3px;
      }
    }
    &.responsible_gambling {
      &:before {
        background-color: var(--menu-icon-color);
        mask-image: url("../../assets/images/menu-icons/gambling.svg");
        -webkit-mask-image: url("../../assets/images/menu-icons/gambling.svg");
        mask-repeat: no-repeat;
        -webkit-mask-position-x: 3px;
        mask-position-x: 3px;
        mask-size: 18px;
        mask-repeat: no-repeat;
        mask-position: center;
      }
    }
    &.kyc {
      &:before {
        background-color: var(--menu-icon-color);
        mask-image: url("../../assets/images/menu-icons/searching.svg");
        -webkit-mask-image: url("../../assets/images/menu-icons/searching.svg");
        mask-size: 17px;
        mask-repeat: no-repeat;
        -webkit-mask-position-x: 3px;
        mask-position-x: 3px;
      }
    }
    &.account-statement {
      &:before {
        background-color: var(--menu-icon-color);
        mask-image: url("../../assets/images/menu-icons/account-statement.svg");
        -webkit-mask-image: url("../../assets/images/menu-icons/account-statement.svg");
        mask-repeat: no-repeat;
        -webkit-mask-position-x: 3px;
        mask-position-x: 3px;
        mask-size: 18px;
        mask-repeat: no-repeat;
        mask-position: center;
      }
    }
    &.bonus-statement {
      &:before {
        background-color: var(--menu-icon-color);
        mask-image: url("../../assets/images/menu-icons/bonus-statement.svg");
        -webkit-mask-image: url("../../assets/images/menu-icons/bonus-statement.svg");
        mask-repeat: no-repeat;
        -webkit-mask-position-x: 3px;
        mask-position-x: 3px;
        mask-size: 18px;
        mask-repeat: no-repeat;
        mask-position: center;
      }
    }
    &.profileMenuIcon {
      &:before {
        background-color: var(--menu-icon-color);
        mask-image: url("../../assets/images/menu-icons/userIcon.svg");
        -webkit-mask-image: url("../../assets/images/menu-icons/userIcon.svg");
        mask-repeat: no-repeat;
        -webkit-mask-position-x: 3px;
        mask-position-x: 3px;
        mask-size: 18px;
        mask-repeat: no-repeat;
        mask-position: center;
      }
    }
  }


}

