@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');

.ChangePassword {
  --padding-top: 15px;
  --padding-start: 15px;
  --padding-end: 15px;

  &__form {
    width: 100%;
    margin-top: 10px;
  }

  &__list {
    background: none !important;

    ion-item {
      --min-height: 36px;
      --padding-start: 0px;
      --padding-end: 0px;
      --inner-padding-end: 0px;
      --inner-border-width: 0px;
    }

    ion-item+ion-item {
      margin-top: 12px;
    }
  }

  &__button {
    width: 50%;
    margin: 20px 0 auto auto;
    text-transform: none;
    background: var(--secondary);
    color: #fff;
    border-radius: 2px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: box-shadow .3s cubic-bezier(.4, 0, .2, 1), background-color .3s cubic-bezier(.4, 0, .2, 1), color .3s cubic-bezier(.4, 0, .2, 1), -webkit-box-shadow .3s cubic-bezier(.4, 0, .2, 1);
    height: 36px;
    font-weight: 500;
    margin-top: 24px;

    &:hover {
      background: var(--cta-primary-darken);
    }

    &:disabled {
      background: var(--cta-disabled);
      cursor: default;
    }

    &:focus {
      outline: none;
    }
  }

  &__form-error {
    padding: 0 10px 5px;
    margin-top: -2px;
    font-size: 10px;
    color: var(--negative);

    ion-item+& {
      padding: 0 10px 10px;
      margin-top: 9px;
    }
  }

  ion-item {
    --highlight-background: var(--login-highlight-bg);
    --border-radius: 2px;
  }

  ion-input {
    border-radius: 2px;
    height: 100%;
  }

  .native-input {
    border-radius: 2px;
    height: 100%;

    &:focus {
      border: 1px solid var(--login-item-focused-border);
    }
  }
}
.timer-wrapper {
  display: flex;
  justify-content: space-between;
  height: 70%;
  flex-direction: column;
  align-items: center;
  margin-top: 93px;
}

.ssss{
  height: 104px;
  width: 104px;
  border-radius: 50%;
  border: 7px solid var(--secondary);
  display: flex;
  justify-content: center;
  align-items: center;
}
.hideSVG{
  height: 0.1px;
  width: 0.1px;
}
.timerDepositAmount{
  margin: 50px 0 30px 0;
  &-currency{
    height: 30px;
    position: relative;
    top: 1px;
    right: 2px;
  }
  &-sum{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 43px;
    line-height: 52px;
  }

}
.timerUTR{
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #40424F;
  margin-bottom: 30px;
}
.timerAllDone{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.timerButtons{
  display: flex;
  margin-top: 20px;
  &-item{
    width: 102px;
    height: 37px;
    background: var(--secondary);
    border-radius: 1px;
    margin: 0 5px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: white;
  }
}
.transactionDone{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;

  &-id{
    text-align: center;
  }
  &-date{
    text-align: center;
  }
  &-time{
    color: #ABABAB;
  }
}
.timerSuccess{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #40424F;

}
.countdownDeposit {
  height: 100px;
  width: 100px;
  text-align: center;
}

.countdownDeposit-number {
  position: relative;
  display: inline-block;
  line-height: 40px;
  top: 34px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  color: #40424F;

}

.countdownDeposit-svg {
  position: relative;
  right: 7px;
  top: -28px;
  width: 100px;
  height: 100px;
  transform: rotateY(-180deg) rotateZ(-90deg);
}

.countdownDeposit-circle {
  stroke-dasharray: 232px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 6px;
  fill: none;
  animation: countdown 62s linear infinite forwards;
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 232px;
  }
}
.mmmm{
  border-radius: 50%;
  border: 6px solid var(--secondary);
  height: 98px;
  width: 98px;
}
.timerTransaction-status {
  width: 81px;
  height: 22px;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 33px;
  text-align: center;

  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;

  border-radius: 2px;
  color: #ffffff;

  &.approved {
    background: #86aa16;
  }

  &.pending {
    background: #e1d800;
  }

  &.rejected {
    background: #ef4848;
  }
}

@media screen and (min-width: 1024px) {
  .timer-wrapper{
    background: #fff;
    box-shadow: -1px 1px 10px rgb(56, 56, 56);
    width: 80%;
    margin: 5% 10%;
  }
  .timerDepositAmount{
    .timerDepositAmount-currency{
      height: 32px;
      width: 21px;
    }
    .timerDepositAmount-sum{
      font-size: 67.8077px;
      line-height: 81px;
    }
  }
  .timerUTR{
    font-size: 18px;
    line-height: 22px;
  }
  .timerAllDone{
    font-size: 18px;
    line-height: 21px
  }
  .timerButtons{
    margin-top: 40px;
    .timerButtons-item{
      width: 130px;
      height: 39px;
      font-size: 16px;
      line-height: 24px;
      background: var(--secondary);
    }
  }

  .timerButtons {
    // -------------------------
    .pushable {
      position: relative;
      border: none;
      background: transparent;
      padding: 0;
      cursor: pointer;
      outline-offset: 4px;
      transition: filter 250ms;
      margin-top: 4px;
    }
    
    .front {
      display: block;
      position: relative;
      width: 130px;
      height: 39px;

      background: var(--secondary);

      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
      margin: 0 2px;
      padding-top: 6px;
    
      border-radius: 3px;
      will-change: transform;
      transform: translateY(-4px);
      transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
    }
    
    .pushable:hover {
      filter: brightness(110%);
    
      .shadow {
        position: absolute;
        top: -2px;
        left: 2px;

        width: 130px;
        height: 39px;

        border-radius: 3px;
        background: rgba(0, 0, 0, 0.25);
        will-change: transform;
        transform: translateY(2px);
        transition:
          transform 600ms cubic-bezier(.3, .7, .4, 1);
      }
    
      .edge {
        position: absolute;
        top: -2px;
        left: 2px;

        width: 130px;
        height: 39px;

        border-radius: 3px;

          background: var(--secondary);

    
        filter: brightness(75%)
      }
    }
    
    .pushable:hover .front {
      transform: translateY(-6px);
      transition:
        transform 250ms cubic-bezier(.3, .7, .4, 1.5);
    }
    
    .pushable:active .front {
      transform: translateY(-2px);
      transition: transform 34ms;
    }
    
    .pushable:hover .shadow {
      transform: translateY(4px);
      transition:
        transform 250ms cubic-bezier(.3, .7, .4, 1.5);
    }
    
    .pushable:active .shadow {
      transform: translateY(1px);
      transition: transform 34ms;
    }
    
    .pushable:focus:not(:focus-visible) {
      outline: none;
    }
  }
}


.stoPP{
  stop-color: var(--secondary);
}
.stoPPP{
  stop-color: var(--secondary);
  stop-opacity: 0.6;

}

.timer-wrapper-poveredBox{
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 100px;
}

@media screen and (max-height: 750px) {
  .animation-container{
    height: 300px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .timer-wrapper-poveredBox{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    &-title{
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #787C81;
    }
  }
  .timerDepositAmount{
    margin: unset;
  }
  .timerTransaction-status{
    margin-bottom: 10px;
  }
  .timerButtons{
    margin-top: 10px;
  }
}
.wallet-depositInfo__icons-wrapper {
  display: flex;
  justify-content: space-evenly;
  margin: 0 0 20px 0;
  .wallet-depositInfo__icon-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 63px;
    text-align: -webkit-center;

    span {
      font-size: 12px;
    }
  }
  .wallet-depositInfo__icon-inner {
    height: 44px;
    margin-bottom: 5px;
    text-align: -webkit-center;

    img {
      height: 40px;
      width: 40px;
    }
  }
}
