.promotion-image {
  padding-top: 14px;
  padding-bottom: 12px;

  width: 100% !important;
  height: 114px;
  -o-object-fit: cover;
  object-fit: cover;
}

.promotion-buttons {
  display: -ms-grid;
  justify-content: space-between;
  grid-row-gap: 5px;
  margin: 12px 10px 0;
  display: grid;
  grid-template-rows: auto auto;
  grid-auto-flow: column;
  overflow-x: scroll;
  grid-column-gap: 6px;

  .promotion-button {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    height: 36px !important;
    margin: 0 !important;
    width: 175px;

    font-family: 'Lato', sans-serif !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-transform: capitalize !important;

    .promotion-button__icon {
      width: 16px;
      height: 16px;
      margin-right: 3px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 100%;
    }

    .promotion-button__content {
      width: 100%;
      height: 100%;

      display: -webkit-box;

      display: -ms-flexbox;

      display: flex;
      align-items: center;
      /* justify-content: space-between; */

      padding-left: 8px;
      padding-right: 16px;

      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 100%;
    }
  }
}





@media (min-width: 1024px) {
  .promotion-buttons {
    grid-template-rows: 1fr 1fr;
    grid-auto-columns: 45%;
    grid-column-gap: 1vw;
    grid-auto-flow: column;
    grid-row-gap: 7px;
    width: auto;
    overflow-x: auto;


    & .promotion-button {
      flex: none;
      // width: 23vw;
      width: 100%;

      & .promotion-button__content {
        background-size: 100% auto;
      }
    }
    //&::-webkit-scrollbar {
    //  height: 1em;
    //}
      &::-webkit-scrollbar {
        display: none;
      }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
  }
}

// @media (min-width: 1440px) {
//   .promotion-buttons {
//     // display: flex;
//     // flex-wrap: wrap;
//     justify-content: space-between;


//     & .promotion-button {
//       flex: none;
//       width: 48%;
//       & .promotion-button__content {
//         background-size: 100% auto;
//       }
//     }
//   }
// }
