@media screen and (min-width: 1024px) {
  .CasinoGames.casinoLive__slot.ion-page {
    overflow: hidden !important;

    .CasinoGames__content__video {
      height: calc(100vh - 174px) !important;
    }
  }
}

.CasinoGames{
  .menu-btn {
    width: 50px !important;
  }
}


.CasinoGames__toolbar {
  .menu-icon2.go-back-icon2:before {
    content: "";
    width: 19px;
    height: 13px;
    position: absolute;
    background-color: var(--icon-color-in-buttons);
    mask-image: url("../../assets/images/goback.svg");
    mask-size: cover;
    mask-position: center;
    mask-repeat: no-repeat;
    top: 0;
    left: 0;
  }
}
