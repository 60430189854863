.bonus-pop-up {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    pointer-events: all;
    &__content {
      width: 90%;
      padding: 0 0 20px 0;
      background-color: #FFFFFF;
      font-size: 16px;
      font-weight: 500;
      color: black;
      border-radius: 10px;
    }
    &__header {
      width: 100%;
      background-color: #F5F5F5;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 25px 0 30px;
      border-radius: 10px;
      span {
        font-weight: 700;
        padding: 0 15px;
      }
      img {
        width: 16px;
        height: 14px;
      }
      &-closeButton {
        cursor: pointer;
        width: 11px;
        height: 18px;
      }
    }
    &__info {
      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #EFEFEF;
        margin: 0;
        padding: 8px 25px;
        font-weight: 600;
        span:first-child {
          font-weight: 600;
        }
      }
    }
    &__info-list-title {
      display: flex;
      align-items: center;
      padding: 8px 25px;
      span:first-child {
        font-weight: 600;
      }
      img {
        cursor: pointer;
        margin-left: 15px;
        transition: all 0.5s;
      }
      img.close {
        transform: rotate(180deg);
        margin-top: 3px;
      }
    }
    &__info-list-block {
      margin: 0;
      padding: 0;
      list-style: none;
      transition: all 0.4s;
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: max-height 0.4s, opacity 300ms ease-in-out;
      li {
        padding: 5px 25px 5px 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-weight: 400;
        span:first-child {
          max-width: 70%;
        }
        span:last-child {
          max-width: 30%;
          text-align: right;
          white-space: nowrap;
        }
      }
      li:nth-child(odd) {
        background-color: #F8F8F8;
      }
      li.different-align {
        align-items: flex-end;
      }
    }
    &__info-list-block.active {
      max-height: 208px;
      opacity: 1;
    }
    .info-item-color {
      color: #00A950;
    }

    /* The slider */
    .newTogglerBonusPopUp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__oneClick{
        font-size: 16px;
      }
      .switcher {
        position: relative;
        display: inline-block;
        width: 55px;
        height: 23px;
      }

      /* Hide default HTML checkbox */
      .switcher input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      /* The slider */
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgb(255, 255, 255);
        border: 1px solid #969696;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      .slider:before {
        position: absolute;
        content: '';
        height: 21px;
        width: 21px;
        left: -1px;
        bottom: -1px;
        background-color: white;
        border: 1px solid #969696
      ;
        -webkit-transition: 0.4s;
        transition: 0.2s;
      }

      input:checked + .slider {
        background-color: inherit;
        &::after {
          position: relative;
          z-index: 0;
          float: left;
          top: 4px;
          left: 6px;
          content: 'ON';
          color: #257B24;
          font-size: 11px;
        }
      }

      input:focus + .slider {
        box-shadow: 0 0 1px #3c3f42;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(32px);
        -ms-transform: translateX(32px);
        transform: translateX(32px);
        background-color: #257B24;
        border: 1px solid #257B24
      }

      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
        &::after {
          content: 'OFF';
          position: relative;
          top: 4px;
          right: 5px;
          z-index: 0;
          float: right;
          transition-delay: 0.1s;
          color: #656565;
          font-size: 11px;
        }
      }

      .slider.round:before {
        border-radius: 50%;
      }
    }
  }
  .bonusRules{
    max-height: 40vh;
    overflow: auto;
    padding: 10px 10px 0 10px;
  }
  .bonusTermsAndConds{
    text-align: center;
    text-decoration: underline;
    color: #1f9bf9;
    margin-top: 10px;
    cursor: pointer;
  }
  .backArrow{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 10px 0;
    cursor: pointer;
    img{
      transform: rotate(90deg);
      margin-right: 5px;
    }
  }
.bonus-pop-up__header-title-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 16px;
    height: 16px;
    background-color: var(--menu-icon-color);
    mask-image: url("../../assets/images/menu-icons/gift1.svg");
    mask-size: 15px;
    mask-repeat: no-repeat;
    mask-position: center;
  }
}
  @media(min-width: 1024px) {
    .bonus-pop-up__info{
        margin: 0 auto;
        width: 351px;
    }
    .bonus-pop-up__header-title{
      margin-left: 40%;
    }
    .bonus-pop-up__content{
        width: 896px;
        border-radius: 10px;
    }
    .bonus-pop-up__header{
        border-radius: 10px;
    }
    .bonusRules{
        margin: 10px auto;
        width: 470px;
        border: 10px solid #F8F8F8;
        border-radius: 8px;
    }
    .bonus-pop-up__header-closeButton{
      width: 27px !important;
      height: 27px !important;
    }
    }
