.desktopLogin-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;

  flex: 1;

  ion-button {
    margin: 29px 0 0 0;
    text-transform: none;
    --border-radius: 2px;
  }

  .native-input {
    height: 100%;
    border-radius: 2px;
    color: var(--black);
  }

  .logo {
    background: center center no-repeat url('../../assets/images/LogoLotusBig.svg');
    background-size: contain;
    position: relative;
    width: var(--login-page-logo-width);
    height: var(--login-page-logo-height);
    margin: var(--login-page-logo-margin);
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--black);
  }

  ion-item {
    --highlight-background: var(--login-highlight-bg);
  }
  .native-input {
    &:focus {
      border: 1px solid var(--login-item-focused-border);
    }
  }
  ion-input {
    --color: white !important;

    --padding-top: 0px !important;
    --padding-bottom: 0px !important;
    --padding-start: 13px !important;
    --padding-end: 0px !important;

    height: 100%;

    border-radius: 2px;

    background-color: white !important;

    font-family: 'Lato', sans-serif !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 17px !important;
  }
}

.desktopLogin-ion-form {
  width: 320px;
  padding: 0 20px 20px;
}

.desktopLogin-content {
  background: url('../../assets/images/DesktopLoginBG.png') !important;
  // background-position: var(--login-position) !important;
  background-size: 20% !important;
}

.desktopLogin-content__list {
  background: 0 !important;

  ion-item {
    --min-height: 36px;
    --padding-start: 0px;
    --padding-end: 0px;

    --inner-padding-end: 0px;

    --inner-border-width: 0px;

  }

  ion-item+ion-item {
    margin-top: 12px;
  }
}



ion-button {
  --color: white;
  --background: var(--secondary);
  --background: var(--bet-form-stake-buttons-bg-color);

  --padding-start: 0;
  --padding-end: 0;

  --border-radius: 3px;

  margin-inline-start: 0;
  margin-inline-end: 0;

  height: 38px;

  font-family: 'Lato', sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
}

.desktopLogin-button {
  background: #183f45;
  color: var(--login-text-color);
  border-radius: 2px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: box-shadow .3s cubic-bezier(.4, 0, .2, 1), background-color .3s cubic-bezier(.4, 0, .2, 1), color .3s cubic-bezier(.4, 0, .2, 1), -webkit-box-shadow .3s cubic-bezier(.4, 0, .2, 1);
  height: 36px;
  font-weight: 500;
  width: 100%;
  border-bottom: 2px solid #0b1c1f;
    border-right: 2px solid #0b1c1f;
  margin-top: 20px;

  &:hover {
    background: #1f5058;
    box-shadow: 1.5px 1.5px 0px 0px #040b0c;
  }
  &:active {
    border-top: 2px solid #0b1c1f;
    border-left: 2px solid #0b1c1f;
    border-bottom: none;
    border-right: none;
    box-shadow: none;

  }

  &:disabled {
    background: var(--cta-disabled);
    cursor: default;
    border: none;
  }

  &:focus {
    outline: none;
  }
}

ion-img {
  width: 216px;
  height: 69px;
}

.button-disabled {
  opacity: 1;
  --background: var(--cta-disabled);
  border-color: var(--cta-disabled) !important;
}

.content-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.desktopLogin__footer-logos {
  display: flex;
  justify-content: center;

  padding: 0 18px 18px 18px;
}

.desktopLogin__footer-logo {
  width: 120px;
  height: 12px;
}

.form-error {
  position: absolute;
  top: 0;
  left: 0;

  height: 52px;

  width: 100%;

  background-color: var(--negative);

  span {
    color: white;
    position: absolute;
    margin: 18px 0 18px 20px;

    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }
}

.blur {
  filter: blur(6px);
}

.grecaptcha-badge {
  bottom: 100px !important;
}

.redirect-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;

  .forgot {
    color: white;
    margin-bottom: 11px;
    cursor: pointer;

    a {
      color: inherit;
      text-decoration: inherit;
    }
  }
  .sign {
    color: #FACD40;
    cursor: pointer;
    text-decoration-line: underline;

    a {
      color: inherit;
      text-decoration: inherit;
    }
  }
}

.b2c {
  &_desktopLogin {
    position: relative;
    margin-bottom: 5px;
    .placeholder {
      position: absolute;
      z-index: 3;
      font-family: 'Lato', sans-serif ;
      font-style: normal ;
      font-weight: normal ;
      font-size: 14px ;
      line-height: 17px ;
      left: 13px;
    }
    ion-input {
      --padding-top: 0px !important;
      --padding-bottom: 0px !important;
      --padding-start: 40px !important;
      --padding-end: 0px !important;

      height: 100%;

      border-radius: 3px;

      background-color: white !important;

      font-family: 'Lato', sans-serif !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 14px !important;
      line-height: 17px !important;
    }
  }
}
