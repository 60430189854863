.editStakesPopUp_wrapperr {
  position: absolute;
  top: 135px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3;
  // overflow: auto;
  margin-bottom: 50px;
  pointer-events: all;
}

.editStakesPopUp_wrapperrr {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 3;
  // overflow: auto;
  margin-bottom: 50px;
  pointer-events: all;
}

.editStakesPopUp {
  pointer-events: all;
  height: 100vh;
  max-width: 1280px;
  position: relative;
  margin: 0 auto;

  .editStakesPopUp_wrapper {
    position: absolute;
    top: 115px;
    width: 313px;
    height: 110%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 10;
    overflow: auto;
    padding: 20px 21px 0 21px;
    min-height: 280px;
    right: 10px;

    .editStakesPopUp_place {
      width: 100%;
      background: #ffffff;
      // border-radius: 10px;
      padding: 8px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // top: 40px;
      z-index: 11;
    }
  }

  &__fields {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin: 8px 0 13px;
    &-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 7px;
    }
    &-bottomContainer {
      display: flex;
      justify-content: space-evenly;

      align-items: center;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      .pushable {
        position: relative;
        border: none;
        background: transparent;
        padding: 0;
        cursor: pointer;
        outline-offset: 4px;
        transition: filter 250ms;

        margin-top: 4px;
      }

      .front {
        display: block;
        position: relative;
        width: 80px;
        height: 28px;

        &.refreshBtn {
          background: #6A9772;
        }

        &.editBtn {
          background: #40424f;
        }

        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 26px;
        text-align: center;
        color: #ffffff;

        border-radius: 3px;
        will-change: transform;
        transform: translateY(-4px);
        transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
      }

      .pushable:hover {
        filter: brightness(110%);

        .shadow {
          position: absolute;
          top: -2px;
          left: 2px;
          width: 80px;
          height: 28px;
          border-radius: 3px;
          background: rgba(0, 0, 0, 0.25);
          will-change: transform;
          transform: translateY(2px);
          transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
        }

        .edge {
          position: absolute;
          top: -2px;
          left: 2px;
          width: 80px;
          height: 28px;
          border-radius: 3px;

          &.refreshBtn {
            background: #6A9772;
          }

          &.editBtn {
            background: #40424f;
          }

          &.cancelBtn {
            background: #fff;
            color: #969696;
          }

          filter: brightness(75%);
        }
      }

      .pushable:hover .front {
        transform: translateY(-6px);
        transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
      }

      .pushable:active .front {
        transform: translateY(-2px);
        transition: transform 34ms;
      }

      .pushable:hover .shadow {
        transform: translateY(4px);
        transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
      }

      .pushable:active .shadow {
        transform: translateY(1px);
        transition: transform 34ms;
      }

      .pushable:focus:not(:focus-visible) {
        outline: none;
      }
    }
  }

  &__input {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    line-height: 14px;
    color: #000000;
    height: 31px;
    border: 2px solid #dddddd;
    flex: 0 1 calc(1 / 3 * 100% - 10px);
    --placeholder-color: var(--one-click-stake);
    --placeholder-opacity: 1;

    > input:disabled {
      background: #fff;
      color: var(--one-click-stake) !important;
      opacity: 1 !important;
    }

    &.active-stake {
      border: 2px solid var(--one-click-stake);
      align-items: flex-start;
      padding-top: 5px !important;
      min-width: 0;
      font-weight: 700 !important;

      &:after {
        content: 'Active Stake';
        font-weight: bold;
        font-size: 13px;
        color: var(--one-click-stake);
        position: absolute;
        bottom: 5px;
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }
    }
  }

  .on-edit &__input {
    --placeholder-color: var(--gray);
    --placeholder-opacity: 1;
    border: 1px solid var(--gray);
    color: var(--gray);

    &.has-focus {
      border: 2px solid var(--gray);
    }

    &.active-stake {
      align-items: center;
      padding-top: unset !important;
      min-width: 0;
      font-weight: 700 !important;

      &:after {
        display: none;
      }
    }
  }

  &__btn {
    height: 34px;
    width: 57px;
    background: #6a9772;
    box-shadow: inset -4px -4px 0px rgba(0, 0, 0, 0.25),
      inset -2px -2px 0px #54785b;
    border-radius: 3px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: white;
    &:hover {
      box-shadow: inset -2px -2px 0px #54785b;
    }
    &:active {
      position: relative;
      // top: 4px;
      // left: 4px;
      box-shadow: inset 2px 2px 0px rgba(0, 0, 0, 0.25);
    }
  }

  ion-input {
    --padding-start: 0px !important;
    font-size: 12px !important;
    font-weight: 700 !important;
  }
  .native-input.sc-ion-input-md{
    padding: 0;
  }
}
