
.sportPageItem:last-child {
  border-bottom: 1px solid #ddd;
}
.sportPageItem {
  .inplay-item:last-child {
    border-bottom: none;
  }
}

.sportPageItem {
  .inplay-item {
    --min-height: 39px;
    --padding-start: 0px;
    --padding-end: 0px;
    --inner-padding-end: 0px;
    --inner-border-width: 0px;
    --background: white;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    display: flex;
    flex-direction: column;
    //border-top: 1px solid var(--border);
    cursor: pointer;

    &__content {
      width: 100%;
      min-height: 43px;
      //padding: 1px 0;
      display: flex;
      flex-direction: column;
    }

    &__row {
      width: 100%;
      height: 43px;
      display: flex;

      div+div {
        border-left: 1px solid var(--border);
      }
    }

    &.item-interactive {
      --border-width: 0 0 0 0;
    }
  }

  .inplay-content+.inplay-content {
    padding-top: 15px;
  }

  .inplay-item__score {
    z-index: 1;
    flex-shrink: 0;
    background: var(--white);
    height: 100%;
    width: 60px;
  }

  .inplay-item__players {
    position: relative;

    z-index: 1;

    //background: var(--white);

    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    min-width: 90px;
    width: calc(100% - 52px * 4 - 12px);
  }

  .inplay-item__player {
    font-family: 'Lato', sans-serif !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 11px !important;
    line-height: 14.4px !important;
    color: var(--text-color);
    position: relative;
    width: 98%;
    padding-left: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 540px) {
      font-size: 10px !important;
      line-height: 10px !important;
      padding-left: 6px;
    }
    span {
      overflow: hidden;
      // white-space: nowrap;
      white-space: pre-wrap;
      text-overflow: ellipsis;
      display: block;
    }

    .inplay-item__player-name {
      color: #005ba2;
      max-width: 75%;
    }

    .inplay-item__player-date {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: auto;
      // margin-left: auto;
    }

    .inplay-item__player-inPlay {
      display: flex;
      align-items: center;
      color: #1d7f1e;
    }
  }

  .inplay-item__player-batting {
    display: inline-block;
    position: absolute;

    top: 6px;
    left: 6px;

    width: 4px;
    height: 4px;

    border-radius: 50%;

    background-color: var(--in-play-block-color);
  }

  .inplay-item__broadcast-icon {
    width: 13px;
    height: 11px;

    position: absolute;
    top: 3px;
    right: 6px;
  }

  .inplay-content__logo-icon--casino,
  .inplay-content__logo-icon--exchange-games {
    height: 18px;
  }

  .inplay-item__back {
    position: relative;

    height: 100%;
    width: calc(52px * 3 + 12px);

    display: flex;
  }

  .inplay-item__back-inner {
    z-index: 0;

    position: relative;

    width: calc(52px * 6);
    height: 100%;
    display: flex;

    span {
      width: 52px;
      min-width: 52px;
      //margin: 0 2px 0 0;
    }

    transition:left .2s ease-in-out;

    span+span:not(.odd-button) {
      border-left: 1px solid var(--border);
    }
  }

  .inplay-item__back-inner.inplay-item__back-inner-left {
    left: 0;
  }

  .inplay-item__back-inner.inplay-item__back-inner-right {
    left: calc(-52px * 3 + 3px)
  }

  .inplay-item__back-inner.inplay-item__status-inner-left {
    left: 0;
  }

  .inplay-item__back-inner.inplay-item__status-inner-right {
    left: -12px
  }

  .inplay-item__status {
    width: calc(52px * 3 + 15px);
    //color: var(--dark-grey);
    //background: var(--light-grey);

    font-family: 'Lato', sans-serif !important;
    //font-style: normal;
    //font-weight: normal;
    //font-size: 14px;
    line-height: 17px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #1e3f5a;
    background: rgba(30, 63, 90, 0.1);
    font-weight: 700;
    font-size: 12px;
  }

  .score-content.cricket {
    height: 100%;

    display: flex;
    flex-direction: column;

    justify-content: center;

    span {
      font-family: 'Lato', sans-serif !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 10px !important;
      line-height: 14.4px !important;

      text-align: center;
      color: var(--in-play-block-color);
    }
  }

  .score-content.empty {
    font-family: 'Lato', sans-serif !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 19px !important;

    color: var(--in-play-block-color);

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    height: 100%;
  }

  .score-content.football {
    height: 100%;

    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 8px 0 8px;

    font-family: 'Lato', sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 14px !important;
  }

  .score-content__football-score-group {
    display: flex;
    flex-direction: column;

    span {
      color: var(--text-color);
    }

    &.won {
      span {
        color: var(--in-play-block-color);
      }
    }
  }

  .score-content__football-time {
    color: var(--in-play-block-color);
  }

  .date-content {
    font-family: 'Lato', sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 11px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;
  }

  .date-content__date {
    color: var(--most-popular-block-color);
  }

  .date-content__time {
    color: var(--most-popular-block-color);
  }

  @media screen and (min-width: 1024px) {
    .inplay-item__desktop {
      width: 50%;
      height: 41px;
      display: flex;

      .inplay-item__players {
        width: 100%;
      }

      .empty {
        margin-right: 0;
        // display: none;
      }

      .inplay-item__player-batting {
        display: none;
      }
    }

    .inplay-item__back {
      width: 50%;
    }

    .inplay-item__back-inner {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .inplay-item__back-inner span {
      width: 100%;
      min-width: 42px;
    }

    .inplay-item__status {
      width: 100%;
    }

    // .inplay-item__player {
    //   padding-left: 0;
    // }

    .starImg {
      display: block;
      width: 20px;
      height: 20px;

      margin-left: 7px;
      margin-right: 7px;
    }
  }
}

//.race-page__content-upcoming-races-table-row .race-page__content-upcoming-races-table-row-header-text span{
//  font-weight: normal !important;
//}


.inplay-item__player span.inplay-item__player-date span {
  font-size: 9.6px !important;
  display: block;
  font-weight: normal;
  margin-right: 3px;
}
