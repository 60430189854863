.inPlayItemLoaderPoUp {
  .inPlayItemLoaderPoUp_wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.85);

    .inPlayItemLoaderPoUp {
      width: 60%;
      // background: #ffffff;
      border-radius: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      padding: 2px 5px 5px;
    }

    .inPlayItemLoaderPoUp-content {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;

      &_title {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #000000;
        margin-top: 5px;
        margin-bottom: 16px;
      }

      &-closeIcon {
        display: block;
        margin-left: auto;
        width: 16px;
        height: 16px;
      }
    }
  }
}