@mixin banner-height() {
    height: 75px;

    @media (max-width: 425px) {
      height: 85px;
    }
    @media (max-width: 375px) {
      height: 76px;
    }
  
    @media (max-width: 320px) {
      height: 65px;
    }
  }

.mostPopularSlider-item {
   @include banner-height();
    cursor: pointer;
    &__img {
      @include banner-height();
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 100%; 
    }
}