@import '../../../styles.scss';

.ConfirmBetPopUpp {
  pointer-events: all;
  .popover-wrapper {
    width: 22%;
    position: relative;
    left: 40%;
  }

  .popover-content {
    @include popover-content-centerr();
    overflow: hidden;
    background: transparent;
    // background: rgba(71, 70, 70, 0.2);
    box-shadow: none;
    margin: -34px;
    height: 395px;
    padding: 0 13px;
  }

  &__text {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
  }

  &__btn {
    @include full-width-button();

    &--disabled {
      --background: none;
      --color: var(--gray);
    }
  }

  &__name {
    justify-content: center;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    margin-bottom: 50px;
  }

  &__runner {
    font-size: 16px;
    margin: 15px 0;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  &__col {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  &__buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .pushable {
      position: relative;
      border: none;
      background: transparent;
      padding: 0;
      cursor: pointer;
      outline-offset: 4px;
      transition: filter 250ms;

      margin-top: 4px;
    }

    .front {
      display: block;
      position: relative;
      width: 80px;
      height: 28px;

      &.refreshBtn {
        background: #6a9772;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }

      &.editBtn {
        background: #40424f;
      }

      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #ffffff;

      border-radius: 3px;
      will-change: transform;
      transform: translateY(-4px);
      transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
    }

    .pushable:hover {
      filter: brightness(110%);

      .shadow {
        position: absolute;
        top: -2px;
        left: 2px;
        width: 80px;
        height: 28px;
        border-radius: 3px;
        background: rgba(0, 0, 0, 0.25);
        will-change: transform;
        transform: translateY(2px);
        transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
      }

      .edge {
        position: absolute;
        top: -2px;
        left: 2px;
        width: 80px;
        height: 28px;
        border-radius: 3px;

        &.refreshBtn {
          background: #6a9772;
        }

        &.editBtn {
          background: #40424f;
        }

        &.cancelBtn {
          background: #fff;
          color: #969696;
        }

        filter: brightness(75%);
      }
    }

    .pushable:hover .front {
      transform: translateY(-6px);
      transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
    }

    .pushable:active .front {
      transform: translateY(-2px);
      transition: transform 34ms;
    }

    .pushable:hover .shadow {
      transform: translateY(4px);
      transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
    }

    .pushable:active .shadow {
      transform: translateY(1px);
      transition: transform 34ms;
    }

    .pushable:focus:not(:focus-visible) {
      outline: none;
    }
    &-cancel {
      cursor: pointer;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #000000;

      &:hover {
        text-decoration: underline;
      }
    }
    &-confirm {
      width: 40%;
      height: 34px;
      background: #77a37f;
      box-shadow: inset -4px -4px 0px rgba(0, 0, 0, 0.25),
        inset -2px -2px 0px #54785b;
      border-radius: 3px;
      cursor: pointer;
      &:hover {
        background-color: #428d51;
      }
      &:active {
        box-shadow: inset 2px 2px 0px #237534;
      }
      &:disabled {
        background: #f5f5f5;
        color: #9a9a9a;
        box-shadow: none;
      }
    }
  }

  &__params {
    padding: 20px 0;
    border: 1px solid var(--input-border);
    border-radius: 2px;
    margin-bottom: 10px;
  }

  &__param {
    font-size: 11px;
  }

  &__value {
    font-size: 18px;
    font-weight: bold;
    margin-top: 5px;
  }

  &__timertext {
    font-size: 16px;
    font-weight: bold;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    animation: flicker 1s infinite;
    
    @keyframes flicker {
      0% {color: #fff; }
      50% { color: rgb(129, 128, 128); }
      100% { color: #fff;}
    }  
  }

  &__timer {
    height: 64px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    margin: 30px 0;
    position: relative;

    &__seconds {
      font-size: 25px;
      font-weight: 700;
      position: absolute;
      margin: 20px auto;
      color: #fff;
    }

    &__spinner {
      height: 64px;
      width: 64px;
    }
  }
}


.aaa{
  background: #fff;
  padding: 23px 20px;
  margin-top: 30px;
}

.bbb{
  margin-top: 80px;
}


@media screen and (min-width: 1290px) {
  .ConfirmBetPopUpp .popover-wrapper {
    width: 300px;
    position: absolute;
    left: 50%;
    //top: 28%;
    top: 310px;
    transform: translate(119%, 0);
    margin: 0;
  }
  .ConfirmBetPopUpp.betslipHasVideo .popover-wrapper {
    top: 390px;
  }
}
@media screen and (max-width: 1290px) and (min-width: 1024px) {
  .ConfirmBetPopUpp .popover-wrapper {
    top: 280px;
    width: 24%;
    position: absolute;
    left: unset;
    right: 12px;
  }
  .ConfirmBetPopUpp .popover-content {
    margin: 0px;
  }
  .ConfirmBetPopUpp.betslipHasVideo .popover-wrapper {
    top: 355px;
  }
}
