@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');

.ChangePassword {
  --padding-top: 15px;
  --padding-start: 15px;
  --padding-end: 15px;

  &__form {
    width: 100%;
    margin-top: 10px;
  }

  &__list {
    background: none !important;

    ion-item {
      --min-height: 36px;
      --padding-start: 0px;
      --padding-end: 0px;
      --inner-padding-end: 0px;
      --inner-border-width: 0px;
    }

    ion-item+ion-item {
      margin-top: 12px;
    }
  }

  &__button {
    width: 50%;
    margin: 20px 0 auto auto;
    text-transform: none;
    background: var(--secondary);
    color: #fff;
    border-radius: 2px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: box-shadow .3s cubic-bezier(.4, 0, .2, 1), background-color .3s cubic-bezier(.4, 0, .2, 1), color .3s cubic-bezier(.4, 0, .2, 1), -webkit-box-shadow .3s cubic-bezier(.4, 0, .2, 1);
    height: 36px;
    font-weight: 500;
    margin-top: 24px;

    &:hover {
      background: var(--cta-primary-darken);
    }

    &:disabled {
      background: var(--cta-disabled);
      cursor: default;
    }

    &:focus {
      outline: none;
    }
  }

  &__form-error {
    padding: 0 10px 5px;
    margin-top: -2px;
    font-size: 10px;
    color: var(--negative);

    ion-item+& {
      padding: 0 10px 10px;
      margin-top: 9px;
    }
  }

  ion-item {
    --highlight-background: var(--login-highlight-bg);
    --border-radius: 2px;
  }

  ion-input {
    border-radius: 2px;
    height: 100%;
  }

  .native-input {
    border-radius: 2px;
    height: 100%;

    &:focus {
      border: 1px solid var(--login-item-focused-border);
    }
  }
}


.terms-withdrow__container{
  margin: 35px 16px 16px 0;
  &-title{
    display: flex;
    justify-content: center;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #000000;
    margin-bottom: 20px;

  }
  &-p1{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #40424F;
    margin-bottom: 16px;
  }
  &-p2{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #40424F;
    margin-bottom: 16px;
  }
  &-p3{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #40424F;
  }
  &-buttonback{
    cursor: pointer;
    width: 94px;
    height: 37px;
    left: 92px;
    top: 438px;
    background: var(--secondary);
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    margin: 0 2px;
    padding-top: 7px;
    margin: 30px auto;
  }
}
.terms-support{
  color: var(--secondary);
  text-decoration: underline;
}

@media screen and (min-width: 1024px) {
  .terms-withdrow__container{
    margin: 145px 125px 45px ;
    background: #fff;
    padding: 80px 80px 30px 80px;
    &-title{
      width: 100%;
      font-size: 20px;
      line-height: 30px;
      color: #40424F;
      display: flex;
    justify-content: center;
      margin-bottom: 30px;  
    }
    &-p1{
      margin-bottom: 20px;
    }
    &-p2{
      margin-bottom: 20px;
    }
  }
}

@media screen and (min-width: 1366px) {
  .terms-withdrow__container{
    margin: 45px 125px;
    background: #fff;
    margin: 145px 125px 45px ;
    &-title{
      width: 100%;
      font-size: 29px;
      line-height: 30px;
      color: #40424F;
      // margin-left: 140px;
      display: flex;
    justify-content: center;
      margin-bottom: 50px;  
    }
    &-p1{
      margin-bottom: 25px;
      font-size: 20px;
      line-height: 30px;
    }
    &-p2{
      margin-bottom: 25px;
      font-size: 20px;
      line-height: 30px;
    }
    &-p3{
      margin-bottom: 25px;
      font-size: 20px;
      line-height: 30px;
    }
  }
}