.Competitions {
    font-family: 'Lato', sans-serif;
    font-style: normal;

    &__list {
        margin-top: 16px;
        background: transparent;
        padding: 0 4px;

        &__header {
            font-size: 18px;
            font-weight: bold;
            padding-inline-start: 11px;
        }

        &__empty {
            font-size: 14px;
            font-style: normal;
            font-weight: normal;
        }

        &__group {
            margin: 7px 0;

            &__divider {
                background: var(--accordion-background);
                border: 0;
                margin: 1px 0;
                font-family: 'Lato', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                color: var(--accordion-title-color);
            }

            &__item {
                --inner-border-width: 0;
                margin: 2px 0;
                display: flex;
                flex-flow: row nowrap;
                cursor: pointer;
                padding: 13px;
                background: var(--background);
                --min-height: 22px;
                padding-inline-start: 0;
                padding-inline-end: 0;

                &__label {
                    flex: 1 1;
                    display: flex;
                    flex-flow: column nowrap;

                    &__name {
                        font-style: normal;
                        font-size: 16px;
                        color: var(--black);
                    }

                    &__time {
                        margin: 0 0 2px;
                        overflow: inherit;
                        font-size: 14px;
                        line-height: normal;
                        color: var(--grey);
                    }
                }

                &__inplay {
                    font-family: 'apollo';
                    flex: 0 0;
                    color: var(--in-play);
                }
            }
        }
    }

    &__noEvent {
        padding-left: 11px;
    }
}
@media screen and (min-width: 1024px ){
    .Competitions {
        height: fit-content;
        min-height: 100%;
    }
}