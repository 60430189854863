@mixin banner-height() {
  height: 75px;

  @media (max-width: 425px) {
    height: 85px;
  }
  @media (max-width: 375px) {
    height: 76px;
  }

  @media (max-width: 320px) {
    height: 62px;
  }
}

.promo-banner {
  @include banner-height();
  width: 100%;
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 2px;

  &-sportsbook {
    background-color: #104C78;
  }

  &-casino {
    background-color: #005640;
  }

  &-exchange {
    background-color: #092966;
  }

  &-horse {
    background-color: #4947B6;
  }

  &-greyhound {
    background-color: #17A4A8;
  }
}

.promo-banner-body {
  @include banner-height();
  width: 100%;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
  cursor: pointer;
}
