.SportMarketStandard {
    margin-bottom: 20px;

    .SportEvent__market__row {
        transition: background-color ease 0.3s;
    }

    div.SportEvent__market__row:hover {
        background-color: #EEE;

        div.SportEvent__market__odds-wrapper {
            background-color: #EEE;
        }
    }

    &.short {
        margin-bottom: 5px;
    }

    &__header {
        display: flex;
        // height: 30px;
        // height: 43px;
        align-items: center;
        padding: 0px 8px 4px 0px;
        font-family: 'Lato';
        margin-bottom: 2px;
        position: relative;

        &__action {
            width: calc(100% / 6);
            font-weight: 700;
            margin-left: 2px;
            font-size: 12px;
            color: #0f2327;
            text-align: center;
        }
    }

    &__inningIcon {
        margin: 0 0 0 10px;
        font-weight: 400;
        font-family: 'apollo';

        &:before {
            content: "ꀕ";
        }

        &.disabled {
            color: var(--disabled-icon);
        }
    }

    .odd-button__volume {
        font-size: 9px;
    }

    // .BetPlacing {
    //     margin-top: -3px;
    // }

    .SportEvent__market__title-name {
        display: inline-block;
        //width: 57%;
        font-family: 'Lato';
        color: #0F2327;
        font-size: 12px;
        font-weight: 700;
    }
}

.SportMarketStandard.moreOdds {
    .SportMarketStandard__header {
        font-family: 'Lato';

        &-starImg {
            display: block;
            width: 20px;
            height: 20px;
            margin-left: -7px;
        }

        .SportEvent__market__title.withDelay {
            width: 50%;

            .SportEvent__market__title-name {
                display: inline-block;
                width: 57%;
                font-family: 'Lato';
                color: #0F2327;
                font-size: 12px;
                font-weight: 700;
            }
        }

        .SportMarketStandard__header__action-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            height: 100%;
            padding-left: 7px;
        }
    }

    .SportMarketStandard__title-timer {
        padding-left: 20px;
        display: flex;
        font-weight: 600;
        align-items: center;

        &-img {
            width: 12px;
            height: 12px;
            margin-right: 2px;
        }

        &-delay {
            padding: unset;
            font-weight: 700;
            font-family: 'Lato';
            font-size: 12px;
        }

        &-noCommissionIcon {
            display: block;
            margin-left: 8px;
            width: 20px;
            height: 20px;
            margin-right: 2px;
            cursor: pointer;
        }

        &-fasterBetAcceptanceIcon {
            display: block;
            width: 20px;
            height: 20px;
            margin-left: 30px;
            cursor: pointer;
        }

        .hover-before {
            position: relative;

            &:hover::after {
                content: "Faster bet acceptance";
                width: 150px;
                height: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: -25px;
                left: -145px;

                padding: 8px;

                background: #FFFFFF;
                border: 1px solid #DDDDDD;
                border-radius: 3px;

                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                display: flex;
                align-items: center;
                text-align: center;

                color: #000000;
            }
        }

        .hover-after {
            position: relative;

            &:hover::after {
                content: "No commission";
                width: 150px;
                height: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: -25px;
                right: -170px;

                padding: 8px;

                background: #FFFFFF;
                border: 1px solid #DDDDDD;
                border-radius: 3px;

                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                display: flex;
                align-items: center;
                text-align: center;

                color: #000000;
            }
        }

    }

    .SportEvent__market__title__text.withDelay {
        width: 100%;

        &>div {
            width: 100%;
        }

        p {
            display: flex;

            .SportEvent__market-item-name {
                display: inline-block;
                width: 100%;
                font-family: "Lato";
            }

            .SportMarketStandard__title-timer {
                display: flex;
                align-items: center;
                justify-content: center;

                &-delay {
                    padding: unset;
                }
            }
        }
    }

    .SportMarketStandard__markets {
        position: relative;

        .SportEvent__market__title {
            width: 50%;
            border-right: 1px solid #ddd;
        }

        .SportEvent__market__odds-wrapper {
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #F9F7F9;
            position: relative;

            .SportEvent__market__status {
                width: 100%;
                margin: unset;
            }

            .SportEvent__market__odds-wrapper-max-bets {
                width: calc((100% - 132px) / 2);
                color: #565457;
                font-size: 11px;
                font-weight: 400;
                padding-left: 11px;
                display: flex;
                flex-direction: column;

                span:first-child {
                    margin-bottom: 3px;
                }
            }
        }

        .SportEvent__market__odds-wrapper.fancy {
            justify-content: end;

            .SportEvent__market__statuslabel {
                right: 50%;
                transform: translateX(50%);
            }
        }

        .SportMarketStandard__markets-suspended {
            position: absolute;
            right: 0;
            top: 0;
            width: 50%;
            height: 100%;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #1e3f5a;
            background: #dddddd;
            font-size: 12px;
            border: 1px solid #ddd;
        }
    }
}
.SportMarketStandard__markets {
    position: relative;

    .SportEvent__market__title {
        width: 50%;
        border-right: 1px solid #ddd;
    }

    .SportEvent__market__odds-wrapper {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F9F7F9;
        position: relative;

        .SportEvent__market__status {
            width: 100%;
            margin: unset;
        }

        .SportEvent__market__odds-wrapper-max-bets {
            width: calc((100% - 132px) / 2);
            color: #565457;
            font-size: 11px;
            font-weight: 400;
            padding-left: 11px;
            display: flex;
            flex-direction: column;

            span:first-child {
                margin-bottom: 3px;
            }
        }
    }

    .SportEvent__market__odds-wrapper.fancy {
        justify-content: end;

        .SportEvent__market__statuslabel {
            right: 50%;
            transform: translateX(50%);
        }
    }

    .SportMarketStandard__markets-suspended {
        position: absolute;
        right: 0;
        top: 0;
        width: 50%;
        height: 100%;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #1e3f5a;
        background: #dddddd;
        font-size: 12px;
        border: 1px solid #ddd;
    }
}

.race-page {
    .SportEvent__market__row {
        height: 26px;
    }

    .SportEvent__market__title {
        min-height: 26px;
    }

    .SportEvent__market__title__text {
        font-size: 12px;
        font-family: "Lato";

        p {
            margin: 0;

            .SportEvent__market-item-name {
                font-weight: 700;
                font-family: "Lato";
            }

            .jockeyName__name {
                font-weight: 400;
            }
        }

        //p:last-child{
        //    span:last-child {
        //        font-weight: 400;
        //    }
        //}
        div {
            .SportEvent__market-item-number {
                width: 22px;
                display: inline-block;
                text-align: center;
                font-weight: 400;
            }

            span:last-child {
                padding-left: 10px;
            }

            span.STALL_DRAW-missed {
                padding-left: 32px;
            }
        }
    }

    .SportEvent__market__status {
        font-size: 12px;
    }

    .SportEvent__market__row.SUSPENDED {
        .SportEvent__market__title,
        .SportEvent__market__status {
            color: rgba(15,35,39, 0.5);
        }
    }

    .SportEvent__market__row.OPEN,
    .SportEvent__market__row.ACTIVE {
        height: 43px;
    }

    .SportEvent__market__row.showJockeyName {
        height: 43px;
    }

    .SportEvent__market__row.REMOVED {

        .SportEvent__market__title,
        .SportEvent__market__status {
            color: rgba(15, 35, 39, 0.5);
        }

        div.SportEvent__market__title__exposure {
            display: none;
        }
    }
}

.eventPage-racing {
    .SportEvent__market__row {
        height: 26px;
    }

    .SportEvent__market__row.WINNER {
        .SportEvent__market__title__text {
            div {
                span {
                    font-weight: 700;
                    color: #0f2327;
                }
            }
        }
    }

    .SportEvent__market__title {
        min-height: 26px;
    }

    .SportEvent__market__title__text {
        font-size: 12px;
        font-family: "Lato";
    }

    .SportEvent__market__status {
        font-size: 12px;
    }

    .SportEvent__market__row.REMOVED {

        .SportEvent__market__title,
        .SportEvent__market__status {
            color: rgba(15, 35, 39, 0.5);

            .SportEvent__market-showStatus {
                font-size: 12px;
                color: rgba(15, 35, 39, 0.5);
                //font-weight: 400;
            }

            .SportEvent__market-showStatus.greyhound-removed {
                font-weight: 700;
            }

            .SportEvent__market-showStatus.horse-removed {
                font-weight: 400;
            }
        }
    }

    .SportEvent__market__row.OPEN,
    .SportEvent__market__row.ACTIVE {
        height: 43px;
    }

    .SportEvent__market__title__text {
        font-size: 12px;

        p {
            margin: 0;

            .SportEvent__market-item-name {
                font-weight: 700;
                font-family: "Lato";
            }

            .jockeyName__name {
                font-weight: 400;
            }
        }

        //p:last-child{
        //    span:last-child {
        //        font-weight: 400;
        //    }
        //}

        div {
            .SportEvent__market-item-number {
                width: 22px;
                display: inline-block;
                text-align: center;
                font-weight: 400;
            }

            span:last-child {
                padding-left: 10px;
            }

            span.STALL_DRAW-missed {
                padding-left: 32px;
            }
        }
    }

    .SportEvent__market-showStatus {
        position: absolute;
        font-size: 12px;
        font-weight: 700;
        color: #0f2327;
        display: inline-block;
        margin-left: 50%;
    }

    //.SportEvent__market__row.REMOVED {
    //    height: 40px;
    //    .SportEvent__market__title {
    //        display: flex;
    //        color: #899192;
    //    }
    //    .SportEvent__market-showStatus-wrapper {
    //        width: 378px;
    //        display: flex;
    //        span.SportEvent__market-showStatus.removed {
    //            color: #899192;
    //            position: relative;
    //            margin-left: unset;
    //            padding-left: 7px;
    //        }
    //    }
    //    div.SportEvent__market__title__exposure {
    //        display: none;
    //    }
    //}
    //.SportEvent__market-showStatus.horse-removed {
    //    //font-size: 12px;
    //    color: rgba(15,35,39, 0.5);
    //    font-weight: 400;
    //}
}

.EventPage {
    .SportEvent__market__title__text {
        font-size: 12px;

        p {
            display: flex;
            margin: 0;
            margin-right: 10px;
        }
    }
}

@media screen and (max-width: 1023px) {
    .SportMarketStandard__title-timer {
        // padding-left: 10px;
        margin-left: auto;
        margin-right: 5px;
        display: flex;
        font-weight: 600;

        &-img {
            width: 12px;
            height: 12px;
            margin-right: 2px;
        }

        &-delay {
            padding: 0 !important;
            font-weight: 700;
            font-family: 'Lato';
            font-size: 12px;
        }

        &-noCommissionIcon {
            display: block;
            margin-left: 8px;
            width: 26px;
            height: 26px;
            margin-right: 2px;
        }

        &-fasterBetAcceptanceIcon {
            display: block;
            width: 26px;
            height: 26px;
        }
    }

    .SportMarketStandard__header__action {
        width: 66px;
        padding-left: 12px;
    }

    .SportEvent__market__title__text {
        font-family: "Lato";
    }
    .SportMarketStandard__winner{
        height: 92px;
        width: 100%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 10px 0 10px 0;
        &-block{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 120px;
            height: 72px;
            &-name{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 14px;
                color: #3E3E3E;
                text-align: center;
            }
            &-odds{
                .odd-button{
                    width: 145px !important;
                    height: 39px !important;
                    border-radius: 4px !important;
                    border: 1px solid #A7D8FD !important;
                }
                // background: #A7D8FD;
                // display: flex;
                // justify-content: center;
                // align-items: center;
                // font-family: 'Lato';
                // font-style: normal;
                // font-weight: 800;
                // font-size: 12px;
                // line-height: 14px;
                // display: flex;
                // align-items: center;
                // text-align: center;
                // color: #40424F;
            }
            &-odds1{
                .odd-button{
                    width: 110px !important;
                    height: 39px !important;
                    border-radius: 4px !important;
                    border: 1px solid #A7D8FD !important;
                }
                // background: #A7D8FD;
                // display: flex;
                // justify-content: center;
                // align-items: center;
                // font-family: 'Lato';
                // font-style: normal;
                // font-weight: 800;
                // font-size: 12px;
                // line-height: 14px;
                // display: flex;
                // align-items: center;
                // text-align: center;
                // color: #40424F;
            }
            &-suspended{
                width: 145px;
                height: 39px;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: 'Lato', sans-serif !important;
                color: #1e3f5a;
                background: rgba(30, 63, 90, 0.1);
                font-weight: 700;
                font-size: 12px;
            }
            &-suspended1{
                width: 110px;
                height: 39px;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: 'Lato', sans-serif !important;
                color: #1e3f5a;
                background: rgba(30, 63, 90, 0.1);
                font-weight: 700;
                font-size: 12px;
            }
            &-exposure{
                display: flex;
                justify-content: center;
            }
            &-exposure1{
                display: flex;
                justify-content: center;
                margin-left: -25px;
            }
        }
    }
    .SportMarketStandard__timer{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        color: #000000;
        padding: 0px 8px 4px 5px;
    }
}

.cashOutButton {
    background-color: #fb9201;
    height: 20px;
    min-width: 68px;
    font-size: 10px;
    border-radius: 10px;
    margin: 0 4px;
    justify-content: center;
    align-items: center;
}
