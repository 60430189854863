.betting-profit-and-loss-item+.betting-profit-and-loss-item {
  margin-top: 2px;
}

.betting-profit-and-loss-item {
  margin-top: 2px;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  background-color: white !important;
  font-family: 'Lato', sans-serif !important;
  padding: 15px 11px 20px;
}

.betting-profit-and-loss-item__content {
  background-color: white;
  color: black;
}

.betting-profit-and-loss-item__content-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.betting-profit-and-loss-item__content-top-left {
  width: 60%;
}

.selection {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  color: var(--back-color);

  &.lay {
    color: var(--lay-color);
  }
}

.selection-value {
  padding-left: 5px;

  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: block;
}

.betId {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 100px;
}

.betId-title {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;

  padding-right: 2px;

  color: var(--gray);
}

.betId-value {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;

  color: #000000;
}

.placed {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 100px;
}

.placed-title {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;

  padding-right: 2px;

  color: var(--gray);
}

.placed-value {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;

  color: #000000;
}

.betting-profit-and-loss-item__content-top-right {
  width: 40%;

  .stake {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    min-width: 100px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    .stake-title {
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 16px;

      display: -webkit-box;

      display: -ms-flexbox;

      display: flex;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      width: 80px;
    }

    .stake-value {
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 16px;

      display: -webkit-box;

      display: -ms-flexbox;

      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
  }
}

.status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  // min-width: 100px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.status-title {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  color: #000000;

  width: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.status-value {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  color: var(--negative);
}

.profit-loss {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 100px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.profit-loss-title {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  color: #000000;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 80px;
}

.profit-loss-value {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  color: var(--negative);
}

.betting-profit-and-loss-item__content-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 12px 0 0;

  &__column {
    width: calc(1/3*100%);
  }
}

.type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.type-title {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  margin: 0 0 7px;

  color: #000000;
}

.type-value {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;

  color: #000000;
}

.game-type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.game-type-title {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  margin: 0 0 7px;

  color: #000000;
}

.odds {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.odds-title {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  margin: 0 0 7px;

  color: #000000;
}

.odds-value {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;

  color: #000000;
}

.stake {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.stake-title {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  margin: 0 0 7px;

  color: #000000;
}

.stake-value {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;

  color: #000000;
}

.pnl-item-value--green {
  color: var(--positive);
}

.pnl-item-value--red {
  color: var(--negative);
}

.pnl-item-value--black {
  color: #000000;
}
