@mixin banner-height() {
  height: 75px;

  @media (max-width: 425px) {
    height: 85px;
  }
  @media (max-width: 375px) {
    height: 76px;
  }

  @media (max-width: 320px) {
    height: 65px;
  }
}

.mostPopular-baner {
  width: 100%;
  @include banner-height();
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  &__wrapper {
      width: 100%;
  }
}