.Settings {
  --padding-start: 20px;
  --padding-end: 20px;

  &__tooltip {
    width: 405px;
    max-width: 100%;

    p {
      margin: 0;
    }

    ul {
      padding-left: 15px;
      margin: 0;
    }
  }

  &__toggle {
    --background: 0;
    --border-style: 0;
    --inner-padding-end: 0;
    --padding-start: 0;
    --min-height: 32px;
    margin-bottom: 10px;
    font-size: 14px;

    &__button {
      --background-checked: #fff;
      --background: #fff;
      --handle-background-checked: var(--negative);
      --handle-background: #fff;
      --handle-box-shadow: 0px 0px 0px 1px #969696;
      --handle-height: 23px;
      --handle-width: 23px;
      height: 23px;
      width: 55px;
      border: 1px solid #969696;
      border-radius: 999px;
      padding: 0;
      padding-inline: 0;
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &::after {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        position: absolute;
        content: "off";
        padding: 0 6px;
        color: var(--gray);
        text-transform: uppercase;
      }

      &.toggle-checked {
        justify-content: flex-start;
        --handle-box-shadow: none;
        border: none;

        &::after {
          content: "on";
          color: var(--negative);
        }
      }
    }
  }

  .info-content {
    background: #FFFFFF;
    border: 2px solid var(--info);
    box-sizing: border-box;
    border-radius: 2px;
    padding: 12px 11px;
    font-size: 10px;
    margin-top: 4px;
  }

  &__fields {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin: 8px 0 13px;
  }

  &__input {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    height: 46px;
    flex: 0 1 calc(1/3*100% - 10px);
    border: 1px solid var(--one-click-stake);
    color: var(--one-click-stake);
    --placeholder-color: var(--one-click-stake);
    --placeholder-opacity: 1;

    >input:disabled {
      background: #fff;
      color: var(--one-click-stake) !important;
      opacity: 1 !important;
    }

    &.active-stake {
      border: 2px solid var(--one-click-stake);
      align-items: flex-start;
      padding-top: 5px !important;
      min-width: 0;
      font-weight: 700 !important;

      &:after {
        content: "Active Stake";
        font-weight: bold;
        font-size: 13px;
        color: var(--one-click-stake);
        position: absolute;
        bottom: 5px;
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }
    }
  }

  .on-edit &__input {
    --placeholder-color: var(--gray);
    --placeholder-opacity: 1;
    border: 1px solid var(--gray);
    color: var(--gray);

    &.has-focus {
      border: 2px solid var(--gray);
    }

    &.active-stake {
      align-items: center;
      padding-top: unset !important;
      min-width: 0;
      font-weight: 700 !important;

      &:after {
        display: none;
      }
    }
  }

  &__item {
    flex-direction: column;
    display: flex;
  }

  &__btn {
    text-transform: none;
    width: 50%;
    margin: 0 0 0 auto;
  }

  .apl-icon-info-sign {
    color: var(--info);
    font-size: 19px;
    margin-left: 10px;
    cursor: pointer;
    width: 21px;
    height: 20px;
    border-radius: 999px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-family: 'apollo';

    &:before {
      content: "";
    }
  }

  ion-input {
    --padding-start: 0px !important;
    font-size: 13px !important;
  }
}