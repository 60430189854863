.page-title {
  color: black;
  margin: 15px 0 0;
}

.betting-profit-and-loss-page__item-list {
  margin: 0 4px 10px 4px;
}

.betting-profit-and-loss-page-item__header {
  font-family: 'Lato', sans-serif !important;

  background-color: var(--accordion-background);
  color: var(--accordion-color);
  
  display: flex;
  align-items: center;

  padding: 8px 13px;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.betting-profit-and-loss-page__item-subtotal {
  background: var(--pnl-summary-background);

  margin: 0 4px 0 4px;
  padding: 15px 12px 20px;

  div {
    padding: 1px;

    &:last-child {
      font-weight: 700;
      margin-top: 12px;
      padding: 8px 0;
    }
  }
}

.betting-profit-and-loss-page__item-subtotal__row {
  display: flex;
  justify-content: space-between;

  font-family: 'Lato', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  align-items: center;

  color: #000000;
}

.net-session-total {
  padding-top: 8px;
}

.net-market-total {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.net-market-total__title {
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;

  color: #000000;
}

.betting-profit-and-loss-details-item__value {
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;

  color: var(--gray);
}