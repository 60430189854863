.AccountStatementTable {
  font-size: 12px;
  font-weight: 400;
  font-family: Lato;
  color: #000000;
  ul, li, p, h6 {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  h6 {
    padding-bottom: 15px;
  }
  &__table-title {
    font-size: 16px;
    font-weight: 800;
  }
  &__table-header {
    background-color: #DDDDDD;
    display: grid;
    grid-template-columns: 1.4fr 6.6fr 1fr 1fr 1fr;
    height: 50px;
    align-items: center;
    li {
      color: #0F2327;
      padding: 0 10px;
      span {
        //padding: 0 20%;
        //padding: 0 10px 0 35px;
        padding: 0 20px 0 0;
        white-space: nowrap;
      }
    }
    li:first-child {
      text-align: right;
    }
    li:nth-child(2) {
      padding-left: 20px;
    }
  }

  .info-right {
    text-align: right;
  }
  .green {
    color: #2aa033;
  }
  .red {
    //color: #d01901;
    color: #BF311C;
  }
  &__table-body {
    &-empty {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 60px;
      background-color: #F0F0F0;
    }
    ul {
      background-color: #FFFFFF;
    }
    &-time {
      background-color: #F9F9F9;
      height: 33px;
      display: flex;
      align-items: center;
      span {
        //width: 10.21%;
        font-weight: 700;
        text-align: right;
        white-space: nowrap;
        //padding-right: 10px;
        //padding-left: 30px;
        display: inline-block;
        width: 13%;
        padding: 0 10px 0 0;
      }
    }
    &-row {
      display: grid;
      grid-template-columns: 1.4fr 6.6fr 1fr 1fr 1fr;
      align-items: center;
      .info-right {
        justify-content: right;
        font-weight: bold;
        white-space: nowrap;
      }
      li {
        min-height: 33px;
        font-size: 12px;
        font-weight: 400;
        color: #0F2327;
        overflow-wrap: anywhere;
        padding: 5px 10px;
        display: flex;
        align-items: center;
      }
      &-date {
        display: flex;
      }
      &-description {

        &-action-name {
          white-space: nowrap;
          width: 25%;
        }
        &-name {
          cursor: pointer;
          width: 75%;
          line-height: 18px;
        }
      }
      .text-color {
        color: #919191;
      }
    }
  }
}

.AccountStatementTable__table-body-row-description {
  padding: 5px 0 5px 20px !important;
}
