@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');

.ChangePassword {
  --padding-top: 15px;
  --padding-start: 15px;
  --padding-end: 15px;

  &__form {
    width: 100%;
    margin-top: 10px;
  }

  &__list {
    background: none !important;

    ion-item {
      --min-height: 36px;
      --padding-start: 0px;
      --padding-end: 0px;
      --inner-padding-end: 0px;
      --inner-border-width: 0px;
    }

    ion-item+ion-item {
      margin-top: 12px;
    }
  }

  &__button {
    width: 50%;
    margin: 20px 0 auto auto;
    text-transform: none;
    background: var(----payment-primary-color);
    color: var(--btn-text-color);
    border-radius: 2px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      -webkit-box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    height: 36px;
    font-weight: 500;
    margin-top: 24px;

    &:hover {
      background: var(--cta-primary-darken);
    }

    &:disabled {
      background: var(--cta-disabled);
      cursor: default;
    }

    &:focus {
      outline: none;
    }
  }

  &__form-error {
    padding: 0 10px 5px;
    margin-top: -2px;
    font-size: 10px;
    color: var(--negative);

    ion-item+& {
      padding: 0 10px 10px;
      margin-top: 9px;
    }
  }

  ion-item {
    --highlight-background: var(--login-highlight-bg);
    --border-radius: 2px;
  }

  ion-input {
    border-radius: 2px;
    height: 100%;
  }

  .native-input {
    border-radius: 2px;
    height: 100%;

    &:focus {
      border: 1px solid var(--login-item-focused-border);
    }
  }


  .page-title-help {
    position: relative;
    float: right;
    font-family: 'Lato';
    font-weight: 500;
    font-size: 16px;
    text-decoration-line: underline;
    color: #000000;

    &::after {
      content: url('../../assets/images/help-icon.svg');
      position: relative;
      top: 2px;
      margin-left: 2px;
    }
  }

  .payments__container {
    padding: 34px 0;
    width: 100%;
    overflow-x: auto;
    max-width: 460px;
    margin: 0 auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .payments__row {
    display: flex;
    width: 100%;
    overflow-x: auto;
    padding-bottom: 10px;

    &-whatsapp__item {
      cursor: pointer;
      box-sizing: border-box;
      min-width: 140px;
      width: 140px;
      height: 90px;
      border-radius: 10px;
      background-image: url("../../assets/images/deposit/whatsapp_pay.png") ;
      background-size: cover;
      position: relative;
      margin-right: 20px;
    }

    &-item {
      cursor: pointer;
      box-sizing: border-box;
      min-width: 140px;
      width: 140px;
      height: 90px;
      border-radius: 10px;
      background-image: url("../../assets/images/depositPageIcons/card_waves.png") ;
      position: relative;
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background: linear-gradient(108.08deg, rgba(178, 178, 178, 0.4) 50%, rgba(255, 255, 255, 0.4) 100%);
        z-index: 1;
      }
      &-radio {
        position: absolute;
        top: 2px;
        right: 2px;
        height: 20px;
        z-index: 2;

        img {
          float: right;
          margin: 3px 3px 0 0;
        }
      }

      &:last-child {
        margin-right: 30px;
      }

      &-logo {
        display: flex;
        justify-content: center;
        //margin-top: 15px;
        // flex-direction: column;
        align-items: center;
        z-index: 2;
        position: relative;

        &-img {
          max-width: 70%;
          height: 50px;
        }

        &-title {
          margin-top: 2px;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          //color: #40424f;
          color: var(--black);
        }
      }
      .payments__row-item-depositMethod-promo {
        font-size: 10px;
        color: var(--payment-primary-color);
        position: absolute;
        left: 10px;
        bottom: 5px;
      }
    }
  }
  //.active {
  //  border: 1px solid var(--payment-primary-color);
  //}
  //

  .payments__noPayments-title {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 16px;
  }

  .payments__noPayments-subtitle {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #40424f;
  }

  .walletInfo {
    width: 100%;
    // height: 190px;
    background: #ffffff;
    //border: 1px solid #40424f;
    padding: 24px 16px;

    &__qr {
      max-height: 400px;
    }

    &__items-wrapper {
      width: 100%
    }

    &__no {
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: #40424f;
    }

    &__item {
      width: 100%;
      margin-bottom: 10px;

      &-title {
        //font-family: 'Source Sans Pro';
        font-style: normal;
        //font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        color: #40424f;

        &-inner {
          font-weight: bold;
        }
      }

      &-img {
        position: relative;
        float: right;

        svg {
          path {
            fill: red
          }

          fill: red
        }
      }

    }
  }

  .fireIcon {
    &::before {
      content: url(../../assets/images/fireIcon.svg);
      position: relative;
      float: left;
      top: -15px;
      left: -8px;
    }
  }

  .noLast {
    margin-top: 10px;
  }

  .inputMargin {
    margin-bottom: 24px;
  }



  .depositInfo {
    width: 100%;
    //height: 190px;
    background: #f4f4f4;
    border: 1px solid #40424f;
    padding: 24px 16px;
    margin-top: 4px;
    margin-bottom: 24px;
  }

  .deposit {
    &__title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 14px;
      &.required::after{
        content: '*';
        color: #ff0000;
        margin-left: 1px;
      }
    }

    &__min-max {
      font-size: 10px;
      margin-top: 1px;
    }

    &__amountInput {
      &-input {
        width: 100%;
        height: 45px;
        background: #ffffff;
        border: 1px solid #40424f;
        box-sizing: border-box;
        border-radius: 2px;
        padding: 0px 16px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #40424f;
      }

      &-currency {
        position: relative;
        top: -33px;
        float: right;
        right: 14px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        color: #40424f;
      }
    }

    &__amountButtons {
      display: flex;
      &-row{
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      // top: -14px;
      width: 100%;
      flex-wrap: wrap;

      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 102px;
        height: 24px;
        background: var(--payment-primary-color);
        border-radius: 3px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #ffffff;
        margin-bottom: 20px;
      }
    }

    .validation-message {
      position: relative;
    }

    .signUpNew_whattsUp {
      margin: 24px 0;
      &_title{
        margin: 0;
        text-align: center;
        // text-decoration: line-through #00A826;
        // text-decoration: underline #00A826;
        //border-bottom: 1px solid var(--login-button-color);
        //font-family: 'Montserrat';
        font-weight: 500;
        font-size: 16px;
        color: var(--black);
        line-height: 23px;
        //text-decoration: underline;
      }
      &_button{
        display: flex;
        margin: 0 auto;
        width: 295px;
        height: 54px;
        background: var(--primary);
        border-radius: 10px;
        margin-top: 27px;
        align-items: center;
        animation: blinker 1s linear infinite;
        &-icon{
          position: relative;
          margin-left: 15px;
          &:after {
            content: "";
            position: absolute;
            width: 45px;
            height: 45px;
            background-color: var(--white);
            top: 50%;

            transform: translateY(-50%);
            mask-image: url("../../assets/images/deposit/whatsApp.svg");
            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: 45px;
          }
        }
        &-number{
          font-family: 'Poppins';
          font-weight: 600;
          font-size: 16px;
          color: var(--white);
          margin-left: 30%;
        }
      }
        }
      }

  .blinking {
    animation: blink-animation 1s infinite;
    }

  @keyframes blink-animation {
    0% { opacity: 0;}
    100% { opacity: 1;}
  }

  .deposit-khelocard {
    font-family: 'Lato';
    margin-bottom: 10px;

    .validation-message.khelocard {
      top: 3px;
    }

    .deposit__expiresInput-title {
      font-weight: 400;
      font-size: 14px;
      color: #40424F;
      padding-bottom: 2px;
    }

    .deposit-cards-input {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .deposit__amountInput.deposit__expiresInput {
        width: 60%;

        .deposit__amountInput-input {
          width: 35%;
          min-width: 60px;
        }

        .deposit__expiresInput-title {
          font-weight: 400;
          font-size: 14px;
          color: #40424F;
          padding-bottom: 2px;
        }

        .deposit__expiresInput-item {
          font-size: 20px;
          font-weight: 400;
          padding: 0 2px;
        }
      }
    }

    .deposit__amountInput.deposit__cvvInput {
      position: relative;
      width: 40%;

      .deposit__amountInput-input {
        width: 100%;
        position: relative;
      }

      .deposit__cvvInput-title {
        font-weight: 400;
        font-size: 14px;
        color: #40424F;
        padding-bottom: 2px;
      }
    }

    .deposit__cvvInput:before {
      content: "";
      z-index: 1;
      position: absolute;
      top: 50%;
      right: 10px;
      background-image: url("../../assets/images/cardBank.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 34px;
      height: 22px;
    }
  }



  .rulesAccept {
    display: flex;
    width: 100%;
    align-items: flex-start;

    &__checkboxD {
      position: absolute;
      z-index: unset !important;
      opacity: 0;
      width: 22px;
      height: 22px;
    }

    &__text {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #40424f;

      &-underline {
        cursor: pointer;
        color: var(--payment-primary-color);
        text-decoration: underline;
      }
    }
  }

  .rulesAccept__checkboxD+label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    margin-top: 3px;
    margin-right: 10px;
  }

  .rulesAccept__checkboxD+label::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid var(--payment-primary-color);
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }

  .rulesAccept__checkbox_checked+label::before {
    content: url(../../assets/images/checkMark.svg);
    display: flex;
    justify-content: center;
    background-color: var(--payment-primary-color);
    border-radius: 2px;
    background-size: 17px;
  }

  .depositSubmit {
    position: relative;
    width: 100%;
    height: 35px;
    margin-top: 24px;
    margin-bottom: 40px;
    background: var(--payment-primary-color);
    border-radius: 3px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
  }
  .wallet-depositInfo__qrCodeImage-icons-wrapper {
    margin-top: 30px;
    .wallet-depositInfo__qrCodeImage-wrapper {
      width: 100%;
      max-width: 200px;
      margin: 0 auto;
      margin-left: 25%;
    }
    .wallet-depositInfo__qrCodeImage {
      width: 100%;
      height: auto;
    }
    .wallet-depositInfo__icons-wrapper {
      width: 100%;
      max-width: 300px;
      margin: 40px auto 60px auto;
      display: flex;
      justify-content: space-between;
    }
    .wallet-depositInfo__icon-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 63px;
      text-align: -webkit-center;
      span {
        font-size: 12px;
      }
    }
    .wallet-depositInfo__icon-inner {
      height: 44px;
      margin-bottom: 5px;

      img {
        height: 50px;
        width: 50px;
      }

    }
  }


  .wallet-depositInfo__bottom-upi-logo {
    width: 130px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 20px auto;
    span {
      font-size: 12px;
      color: var(--color-title-secondary-exchange);
      white-space: nowrap;
      padding-right: 7px;
    }
    img {
      height: 27px;
    }
  }
}

.depositNotValidBtn {
  position: relative;
  background: rgb(116, 112, 112);
  width: 100%;
  height: 35px;
  margin-top: 24px;
  margin-bottom: 30px;
  border-radius: 3px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  pointer-events: none;
}

.wallet-depositInfo__desctop {
  display: none;
}

.noPayment__desctop {
  display: none;
}

// Empty field check styles
.ChangePassword {
  .deposit__amountInput-currency {
    left: -35px;
  }

  .deposit__amountInput {
    input {
      outline: none;
    }
  }

  .rulesAccept.deposit__empty-input,
  .deposit__amountInput.deposit__empty-input {
    position: relative;

    .input-error-message {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      transform: translateY(-100%);
      font-size: 12px;
      color: #FF8787;
    }

    input.deposit__amountInput-input {
      border: 1px solid #FF8787;
    }
  }

  .rulesAccept.deposit__empty-input {
    label:before {
      border: 1px solid #FF8787;

    }
  }
}

// End Empty field check styles


@media screen and (min-width: 1024px) {
  .deposit__amountButtons {
    top: -14px
  }

  .deposit-desktop {
    display: flex;
    justify-content: center;
    color: black;
    margin-bottom: 90px;

    .validation-message {
      position: unset !important;
      margin-top: 30px;
    }

    &__leftpage {
      min-height: 780px;
      height: auto;
      width: 48%;
      background: #fff;
      box-shadow: -1px 1px 10px rgb(56, 56, 56);
      margin-top: 15px;
      padding: 36px 63px;
    }

    &__rightpage {
      margin-top: 15px;
      min-height: 780px;
      height: auto;
      width: 48%;
      background: #fff;
      box-shadow: 1px 1px 10px rgb(56, 56, 56);
      position: relative;
    }
  }

  .deposit__amountButtons{
    flex-direction: column;
  }

  .deposit-titlehelp {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .page-title {
      display: block;
      font-weight: 700;
      font-size: 22px;
      line-height: 36px;
      color: #102327;
      height: 40px;
    }

    .page-title-help {
      display: block;
      margin-top: 20px;
      font-size: 16px;
      line-height: 19px;
      cursor: pointer;
    }
  }
  .wallet-depositInfo__desctop {
    display: block;

    .walletInfo {
      border: 0px solid #000;

      &__items-wrapper {
        width: 100%
      }

      &__no {
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 8px;
        display: block;
      }

      &__item {
        &-title {
          font-size: 15px;
          line-height: 25px;
        }

        img {
          display: block;
          width: 16.35px;
          height: 18.69px;
        }
      }
    }

    .depositInfo {
      margin-top: 28px;
      padding: 15px 20px;
    }
  }

  .noPayment {
    display: none;
    padding: 0 16px;
  }

  .noPayment__desctop {
    display: block;
    margin-top: 20px;

    .payments__noPayments-title {
      font-size: 17px;
    }

    .payments__noPayments-subtitle {
      font-size: 17px;
    }
  }

  .deposit {
    margin: 0px 90px 30px 90px;

    .deposit__amountButtons {
      margin-bottom: -50px;
      margin-top: 20px;
    }

    .deposit__title {
      font-size: 15px;
      line-height: 21px;
    }
  }

  .rules-button {
    margin: 0 90px;

  }

  .deposit__amountButtons {
    .pushable {
      position: relative;
      border: none;
      background: transparent;
      padding: 0;
      cursor: pointer;
      outline-offset: 4px;
      transition: filter 250ms;

      margin-top: 4px;
    }

    .front {
      display: block;
      position: relative;
      width: 102px;
      height: 24px;

      &.refreshBtn {
        background: var(--payment-primary-color);
      }

      &.editBtn {
        background: #40424f;
      }

      font-style: normal;
      font-family: 'Lato';
      font-weight: 700;
      font-size: 9px;
      line-height: 11px;
      text-align: center;
      color: #ffffff;
      margin: 0 2px;
      padding-top: 7px;

      border-radius: 3px;
      will-change: transform;
      transform: translateY(-4px);
      transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
    }

    .pushable:hover {
      filter: brightness(110%);

      .shadow {
        position: absolute;
        top: -2px;
        left: 2px;
        width: 102px;
        height: 24px;
        border-radius: 3px;
        background: rgba(0, 0, 0, 0.25);
        will-change: transform;
        transform: translateY(2px);
        transition:
          transform 600ms cubic-bezier(.3, .7, .4, 1);
      }

      .edge {
        position: absolute;
        top: -2px;
        left: 2px;
        width: 102px;
        height: 24px;
        border-radius: 3px;

        &.refreshBtn {
          background: var(--payment-primary-color);
        }

        &.editBtn {
          background: #40424f;
        }

        &.cancelBtn {
          background: #fff;
          color: #969696;
        }

        filter: brightness(75%)
      }
    }

    .pushable:hover .front {
      transform: translateY(-6px);
      transition:
        transform 250ms cubic-bezier(.3, .7, .4, 1.5);
    }

    .pushable:active .front {
      transform: translateY(-2px);
      transition: transform 34ms;
    }

    .pushable:hover .shadow {
      transform: translateY(4px);
      transition:
        transform 250ms cubic-bezier(.3, .7, .4, 1.5);
    }

    .pushable:active .shadow {
      transform: translateY(1px);
      transition: transform 34ms;
    }

    .pushable:focus:not(:focus-visible) {
      outline: none;
    }
  }

  .submitBtnD {
    position: relative;

    .submitValidBtn {
      position: relative;
      border: none;
      background: transparent;
      padding: 0;
      cursor: pointer;
      outline-offset: 4px;
      transition: filter 250ms;
      width: 100%;
      height: 35px;
      margin-top: 24px;
      margin-bottom: 90px;

    }

    .front {
      display: block;
      position: relative;
      width: 100%;
      height: 35px;

      background: var(--payment-primary-color);

      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #ffffff;
      margin: 0 2px;
      padding-top: 6px;

      border-radius: 3px;
      will-change: transform;
      transform: translateY(-4px);
      transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
    }

    .submitValidBtn:hover {
      filter: brightness(110%);

      .shadow {
        position: absolute;
        top: -2px;
        left: 2px;
        width: 100%;
        height: 35px;
        border-radius: 3px;
        background: rgba(0, 0, 0, 0.25);
        will-change: transform;
        transform: translateY(2px);
        transition:
          transform 600ms cubic-bezier(.3, .7, .4, 1);
      }

      .edge {
        position: absolute;
        top: -2px;
        left: 2px;
        width: 100%;
        height: 35px;
        border-radius: 3px;

        background: var(--payment-primary-color);

        filter: brightness(75%)
      }
    }

    .submitValidBtn:hover .front {
      transform: translateY(-6px);
      transition:
        transform 250ms cubic-bezier(.3, .7, .4, 1.5);
    }

    .submitValidBtn:active .front {
      transform: translateY(-2px);
      transition: transform 34ms;
    }

    .submitValidBtn:hover .shadow {
      transform: translateY(4px);
      transition:
        transform 250ms cubic-bezier(.3, .7, .4, 1.5);
    }

    .submitValidBtn:active .shadow {
      transform: translateY(1px);
      transition: transform 34ms;
    }

    .submitValidBtn:focus:not(:focus-visible) {
      outline: none;
    }

    .depositNotValidBtn {
      top: -4px;
      left: 2px;

      background: rgb(116, 112, 112);
      width: 100%;
      height: 35px;
      margin-top: 24px;
      margin-bottom: 90px;
      border-radius: 3px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #ffffff;
      pointer-events: none;
    }

    .dep-submitNew {

      height: 35px;
      width: 100%;
      background: var(--payment-primary-color);
      border-radius: 3px;
      margin-top: 24px;
      margin-bottom: 90px;

      &__text {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #ffffff;
        padding-top: 0px;
      }
    }
  }

  .deposit-promocode {
    width: 70% !important;
    margin: 0 auto !important;
  }

  .ChangePassword {
    .payments__container.payments__container-khelocard {
      padding-bottom: 20px;
      margin-bottom: 0;
    }

    .deposit.deposit__amount-khelocard {
      margin-bottom: 15px;

      .validation-message.khelocard {
        margin-top: 30px;
      }
    }

    .deposit.deposit-khelocard {
      .validation-message.khelocard {
        margin-top: 10px;
      }
    }
  }
}

.deposit-promocode {
  width: 100%;
  height: 98px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 6px;
  border: 2px solid #DBDBDB;
  background-color: #FFFFFF;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 400;
  color: #313131;
  position: relative;
}

.deposit-promocode.active {
  border: 2px solid var(--payment-primary-color) !important;
}

.deposit-promocode.hover {
  border: 2px solid #003499 !important;
}

.deposit-promocode-select {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  height: 100%;
  padding: 10px;
}

.deposit-promocode-select-button {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.deposit-promocode-select-button-circle {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 2px solid #DBDBDB;
}

.deposit-promocode-select-button-circle-active {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 2px solid #DBDBDB;
  background: var(--payment-primary-color);
  background-image: url("../../../src/assets/images/done_select.svg");
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center;
}

.deposit-promocode-select-gray {
  color: #7E7E7E;
  font-size: 14px;
}

.deposit-promocode-select-details {
  color: var(--black);
  font-family: monospace;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.deposit-promocode-tagline {
  color: var(--black);
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

@media (min-width: 1440px) {
  .deposit-promocode {
    width: 63%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1440px) {
  .deposit-desktop {
    display: flex;
    justify-content: center;

    .validation-message {
      position: unset !important;
      margin-top: 10px;
    }

    &__leftpage {
      width: 40%;
      background: #fff;
      box-shadow: -1px 1px 10px rgb(56, 56, 56);
      margin-top: 15px;
      padding: 36px 63px;
    }

    &__rightpage {
      margin-top: 15px;
      width: 40%;
      background: #fff;
      box-shadow: 1px 1px 10px rgb(56, 56, 56);
      position: relative;
    }
  }

  .deposit-titlehelp {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .page-title {
      display: block;
      font-weight: 700;
      font-size: 29px;
      line-height: 36px;
      color: #102327;
      height: 40px;
    }

    .page-title-help {
      display: block;
      margin-top: 20px;
      font-size: 16px;
      line-height: 19px;
    }
  }
  .wallet-depositInfo__desctop {
    display: block;

    .walletInfo {
      border: 0px solid #000;

      &__items-wrapper {
        width: 100%
      }

      &__no {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 8px;
        display: block;
      }

      &__item {
        &-title {
          font-size: 18px;
          line-height: 26px;
        }

        img {
          display: block;
          width: 16.35px;
          height: 18.69px;
        }
      }
    }

    .depositInfo {
      margin-top: 28px;
      padding: 15px 10px;
    }
  }

  .noPayment {
    display: none;
    padding: 16px
  }

  .noPayment__desctop {
    display: block;
    margin-top: 20px;

    .payments__noPayments-title {
      font-size: 17px;
    }

    .payments__noPayments-subtitle {
      font-size: 17px;
    }
  }

  .deposit {
    margin: 0px 137px 30px 137px;

    .deposit__amountButtons {
      margin-bottom: -30px;
    }

    .deposit__title {
      font-size: 18px;
      line-height: 21px;
    }
  }

  .rules-button {
    margin: 0 137px;

  }
}

.ggg {
  path {
    fill: var(--payment-primary-color);

  }
}

.unSelectedRadio {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid grey;
  color: grey;
  float: right;
  position: relative;
  top: 3px;
  right: 3px;
}

.selectedRadio {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  color: var(--payment-primary-color);
  background-color: var(--payment-primary-color);
  float: right;
  position: relative;
  top: 3px;
  right: 3px;

  &::before {
    content: url(../../assets/images/checkMark.svg);
    position: relative;
    top: -1px;
    left: 2px;
  }
}


@media screen and (min-height: 1000px) and (min-width: 1024px) {
  .deposit-desktop__leftpage {
    height: auto;
    min-height: 780px;
  }

  .deposit-desktop__rightpage {
    height: auto;
    min-height: 780px;
  }
}

.scrollOff {
  overflow-y: hidden !important;
}

.ion-page-invisible {
  opacity: 1 !important;
}

.ChangePassword .payments__row::-webkit-scrollbar {
  background: #EFEFEF;
  border-radius: 1px;
  height: 4px;
}

.ChangePassword .payments__row::-webkit-scrollbar-thumb {
  background: #C4C1C1;
  border-radius: 1px;
}

@media (max-width: 1024px) {

  .ChangePassword {
    --padding-start: 5px !important;
    --padding-end: 5px !important;

    .walletInfo {
      padding: 5px 16px;
      border-radius: 10px;
      background-color: var(--white);
      margin-bottom: 10px;
      height: unset;

      &__items-wrapper {
        width: 100%
      }

      span {
        font-family: monospace;
      }

    }

    .walletInfo.walletInfo__with-qrCode {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .wallet-depositInfo__qrCodeImage-wrapper {
        margin-left: 25%;
        width: 50%;
      }
      .walletInfo__no {
        font-weight: 600;
      }

      .walletInfo__item-title {
        padding-right: 10px;
        font-size: 16px;
        .walletInfo__item-title-inner {
          font-weight: bold;
        }
      }
    }
    .wallet-depositInfo__icons-wrapper {
      display: flex;
      justify-content: space-evenly;
      margin: 0 0 20px 0;
      .wallet-depositInfo__icon-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 63px;
        text-align: -webkit-center;

        span {
          font-size: 12px;
        }
      }
      .wallet-depositInfo__icon-inner {
        height: 44px;
        margin-bottom: 5px;
        text-align: -webkit-center;

        img {
          height: 40px;
          width: 40px;
        }
      }
    }
    .deposit {
      background: var(--white);
    }
    .deposit-titlehelp {
      font-size: 16px;
      margin: 0 0 35px 0;
    }
    .deposit {
      padding: 5px 16px;
      border-radius: 10px;
      background-color: var(--white);
      margin-bottom: 10px;
    }
    .page-title {
      font-size: 16px;
    }
    .deposit__title {
      font-size: 16px;
      margin-top: 10px;
    }
    .deposit.top-cards_wrapper {
      padding: 5px 11px;
      .page-subtitle.notes::after{
        content: none;
      }
    }
    .payments__container {
      padding: 0;
    }
    .deposit__title.deposit__icons-title {
      padding: 5px 21px;
      border-radius: 10px;
      background-color: var(--white);
      margin-bottom: 10px;

    }
  }
}

.depositNotes{
  width: 365px;
  height: 900px;
  background: #fff;
  border-radius: 10px;
  margin: 0 auto;
  padding-left: 16px;
  padding-top: 5px;
  margin-bottom: 90px;

  &-title{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #40424F;
    margin-top: 5px;
    margin-bottom: 14px;
  }
  &-content{
    width: 254px;
    height: 300px;
    padding: 0 13px;
    width: 100%;
    position: relative;
    margin-left: 10px;
    &-text{
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      color: #40424F;
      &__submit{
        margin-top: 23px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 30px;
        color: #40424F;
      }
    }
    &-img{
      position: absolute;
      left: -5px;
      top: 11px;
    }
  }
}

.imageUploader{
  border: dashed 2px var(--payment-primary-color) !important;
  margin-bottom: 15px;
  svg{
    path{
      fill: var(--payment-primary-color) !important;

    }
    fill: var(--payment-primary-color) !important;
  }
}

.slick-slider.deposit-content-infoSlides.slick-initialized{
  .slick-slide{
    height: 168px;
    display: flex;
    align-items: center;
    margin: 0 5px;
  }
}

@media screen and (min-width: 1024px) {
  .depositNotes{
    width: 100%;
  }
}
