.BannerPopUp {
  .BannerPopUp_wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    z-index: 5;

    .BannerPopUp {
      max-width: 50%;
      border-radius: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      padding: 25px;
    }

    .BannerPopUp-content {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;

      &__image {
        border-radius: 9px;
      }

      &_title {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 27px;
        text-align: center;
        color: #000000;
        // margin-top: 5px;
        margin-bottom: 20px;
      }

      &_button {
        width: 150px;
        height: 40px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 23px;
        text-align: center;
        background: var(--cta-primary-darken);
        color: #fff;
        border-radius: 5px;
      }
    }
  }
}

.closePopUpButtonn {
  cursor: pointer;
  position: absolute;
  right: 5%;
  top: -10%;
}

@media screen and (max-width: 600px) {
  .BannerPopUp {
    .BannerPopUp_wrapper {
      .BannerPopUp {
        max-width: 100%;
        width: 100%;
        padding: 0;
      }
    }
  }
  .BannerPopUp-content__image {
    height: 200px;
    width: 350px;
    border-radius: 9px;
  }
  .closePopUpButtonn {
    position: absolute;
    right: 5%;
    top: -20%;
    height: 20px;
    width: 20px;
  }
}
