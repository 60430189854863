.top-baner-wrapper {
  width: 100%;
  margin-top: 14px;
  overflow-y: hidden;
  height: 150px;

}
@media (min-width: 1024px) {
  .topBannerPadding{
    padding-right: 10px;
  }
}

@media (max-width: 460px) {
  .top-baner-wrapper {
    height: 200px;
  }
}

@media (max-width: 420px) {
  .top-baner-wrapper {
    height: 180px;
  }
}
@media (max-width: 375px) {
  .top-baner-wrapper {
    height: 160px;
  }
}
