.TimeSettings {
  --padding-start: 4px;
  --padding-end: 4px;

  .item ion-label {
    font-size: 12px;
  }

  &__option {
    margin-bottom: 2px;

    &--first {
      margin-top: 10px;
    }

    &--last {
      margin-bottom: 25px;
    }

    &.item-radio-checked ion-label {
      color: var(--cta-primary);
    }
  }

  ion-list-header {
    min-height: 56px;
    padding: 0 14px;

    .date {
      font-family: 'Lato', sans-serif;
      font-size: 14px;
      font-weight: normal;

      .time {
        font-family: inherit;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
      }
    }
  }

  ion-item {
    --inner-border-width: 0;
    --min-height: 32px;
    height: 32px;
    padding: 0 14px;
    padding-inline-start: 0;
    padding-inline-end: 0;

    ion-label {
      margin: auto
    }
  }

  .item-native {
    height: 32px !important;
    --height: 32px !important;
  }

  ion-list {
    :first-child {
      --inner-border-width: 0 0 1px 0;
    }
  }

  ion-radio {
    width: 16px;
    height: 16px;
    --color-checked: var(--ion-color-dark-shade);
  }
}