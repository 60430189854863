.promo-details-pop-up {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.promo-details-pop-up-main {
  width: 90%;
  height: 455px;
  background-color: #FFFFFF;
  border-radius: 17px;
  padding: 20px 0;
  position: relative;
  overflow: hidden;
  /*z-index: 1;*/
}

.promo-details-pop-up-main-content {
  padding: 0 20px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  font-weight: 400;
  font-size: 16px;
  color: black;
}

.promo-details-pop-up-main-close {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background-image: url("../../../../src/assets/images/close1.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.promo-details-pop-up-main-img {
  margin: 0 auto;
  width: 393px;
  height: 195px;
}

.promo-details-pop-up-main-img img {
  width: 100%;
  height: 100%;
}

.promo-details-pop-up-main ol {
  list-style: auto;
  padding: 20px;
}
