.popularGames-content {
  width: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: 100px 100px;
  grid-template-rows: auto auto;
  grid-row-gap: 5px;
  grid-column-gap: 12px;
  margin-top: 12px;
  grid-auto-flow: column;
  overflow-x: scroll;
  justify-content: space-between;
  padding-bottom: 10px;
  &::-webkit-scrollbar {
    display: none;
  }
  & .popularGames-item {
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    width: 100px;
  }
  .popularGames-img-body {
    width: 100%;
    height: 125px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
    position: relative;
  }
  .popularGames-item-name {
    position: relative;
    font-weight: 500;
    font-size: 9px;
    text-transform: capitalize;
    color: #FFFFFF;
    top: 97px;
    left: 5px;
  }
}

@media(min-width: 1024px) {
  .popularGames-content {
    grid-template-columns: 130px 130px;
    & .popularGames-item {
      width: 130px;
      height: 170px;
    }
    .popularGames-img-body {
      height: 100%;
    }
    .popularGames-item-name {
      position: relative;
      font-weight: 500;
      font-size: 14px;
      text-transform: capitalize;
      color: #FFFFFF;
      top: 137px;
      left: 5px;
    }
  }
}

