.deposit-wrrap {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    border-radius: 0.5rem;
    align-items: center;
    flex-direction: column;
    max-width: 24rem;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    aspect-ratio: 360/168;
    display: flex;
    z-index: 10;
    position: relative;
}

.you-bonus-wrrap {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    --tw-bg-opacity: 1;
    background-color: rgb(22 163 74 / var(--tw-bg-opacity));
    border-radius: 0.5rem;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
    display: flex;
    margin-top: 0.75rem;
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
}

.shimmer-animation {
    position: absolute;
    top: -50%;
    left: -50%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.2) 60%, rgba(255, 255, 255, 0) 100%);
    animation: shimmer 2s infinite;
    transform: rotate(90deg);
}

.you-bonus-text {
    font-weight: 700;
}

.you-bonus-arrow {
    overflow: hidden;
    width: 1.5rem;
    height: 1.5rem;
    display: block;
    vertical-align: middle;
}

.active-bonus {
    margin-top: 1rem;
    margin-left: 10px;
    margin-right: 10px;
}

.info-icon {
    overflow: hidden;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    z-index: 20;
    top: 0.25rem;
    right: 0.25rem;
    position: absolute;
    display: block;
    vertical-align: middle;
}

.deposit-container {
    background-image: url(https://cdn.mac1j.com/gstatic/gemexchimages/bonus-1.svg);
    background-size: cover;
    filter: grayscale(0%);
    padding: 1rem;
    gap: 0.25rem;
    flex-direction: column;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 10;
}

.deposit-title {
    gap: 0.5rem;
    align-items: center;
    width: 100%;
    display: flex;
}

.deposit-title-text {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    letter-spacing: 0.025em;
    font-weight: 800;
    font-size: 1.5rem;
    line-height: 2rem;
}

.deposit-descr {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.deposit-timer {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
    gap: 0.5rem;
    align-items: center;
    display: flex;
}

.line {
    flex-grow: 1;
    width: 100%;
}

.procent {
    --tw-text-opacity: 1;
    color: rgb(34 197 94 / var(--tw-text-opacity));
    letter-spacing: 0.05em;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.deposit-bonus {
    gap: 0.5rem;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    display: flex;
}

.deposit-bonus-conteiner {
    gap: 0.25rem;
    align-items: center;
    width: 100%;
    display: flex;
}

.deposit-bonus-text-start {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.deposit-bonus-line-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    border-radius: 9999px;
    flex-grow: 1;
    height: 0.5rem;
    display: flex;
}

.deposit-bonus-line-green {
    width: 0%;
    --tw-bg-opacity: 1;
    background-color: rgb(34 197 94 / var(--tw-bg-opacity));
    border-radius: 9999px;
    height: 100%;
}

.deposit-bonus-text-end {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.bonuses {
    gap: 1rem;
    flex-direction: column;
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    margin: 0.5rem;
}

.bonuses-wrapp {
    padding: 1rem;
    width: 100%;
}

.bonuses-conteiner {
    --tw-text-opacity: 1;
    color: rgb(30 41 59 / var(--tw-text-opacity));
    gap: 0.5rem;
    align-items: center;
    display: flex;
}

.bonuses-text {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
}

.bonuses-deposit {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    gap: 1rem;
    flex-wrap: wrap;
    width: 100%;
    display: flex;
}

.deposit-text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-left: auto;
}

.deposit-claim {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    --tw-bg-opacity: 1;
    background-color: rgb(22 163 74 / var(--tw-bg-opacity));
    border-radius: 9999px;
    overflow: hidden;
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-left: auto;
    position: relative;
}

.deposit-claim-activ {
    gap: 0.5rem;
    align-items: center;
    width: 100%;
    display: flex;
}

.deposit-claim-text-orange {
    --tw-text-opacity: 1;
    color: rgb(249 115 22 / var(--tw-text-opacity));
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
}

.bonus-succes {
    gap: 0.5rem;
    align-items: center;
    width: 100%;
    display: flex;
}

.bonus-succes-text {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    --tw-bg-opacity: 1;
    background-color: rgb(22 163 74 / var(--tw-bg-opacity));
    border-radius: 0.5rem;
    width: 100%;
}

.bonuses-active-now {
    gap: 0.5rem;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    display: flex;
}

.bonuses-active-now-text {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
}

.bonuses-active-now-bonus {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    --tw-bg-opacity: 1;
    background-color: rgb(249 115 22 / var(--tw-bg-opacity));
    border-radius: 9999px;
    overflow: hidden;
    cursor: pointer;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    position: relative;
}

.info-wrrap {
    --tw-backdrop-blur: blur(4px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    background-color: rgb(255 255 255 / 0.3);
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    display: flex;
    z-index: 50;
    left: 0px;
    top: 0px;
    position: fixed;
}

.info-close {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
    overflow-y: auto;
    max-width: 42rem;
    width: 100%;
}

.info-close-icon {
    overflow: hidden;
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: auto;
    display: block;
    vertical-align: middle;
}

.info-descr {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
    padding: 1rem;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    border-radius: 0.5rem;
    overflow-y: auto;
    max-width: 42rem;
    width: 100%;
    height: 50%;
}

.deposit-expired {
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 40;
    top: 0px;
    left: 0px;
    position: absolute;
}

.deposit-expired-text {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    font-weight: 700;
    text-align: center;
    padding: 0.25rem;
    --tw-bg-opacity: 1;
    background-color: rgb(239 68 68 / var(--tw-bg-opacity));
    rotate: 45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    width: 16rem;
    z-index: 50;
    top: 1.5rem;
    right: -5rem;
    position: absolute;
}

.expired {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    letter-spacing: 0.05em;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 9999px;
    overflow: hidden;
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    z-index: 20;
    top: -10px;
    left: 0.75rem;
    position: absolute;
    margin-bottom: 5px;
}

.plus-bg-slate-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(51 65 85 / var(--tw-bg-opacity));
}

.plus-bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.plus-bg-orange-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(249 115 22 / var(--tw-bg-opacity));
}
