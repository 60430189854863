.OpenBetsItem {
    padding: 16px 12px;
    background: var(--white);
    margin: 0 0 4px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    &__matchName{
        font-family: 'Lato';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 14px;

color: #000000;
    }

    &.nomatch {
        font-size: 14px;
        font-weight: 400;
    }
    &__title {
        font-size: 14px;
        font-weight: 700;
        color: var(--back-color);
        margin-bottom: 6px;
        text-decoration: underline;

        &.lay {
            color: var(--lay-color);
        }
    }
    &__info{
        color: black;
    }

    &__button {
        margin-top: 14px;
        width: 100%;
        height: 36px;
        border-radius: 2px;
        background: var(--cta-secondary);
        color: var(--cta-disabled);
        border: 1px solid var(--cta-disabled);
        &:hover {
            background: var(--cta-secondary-darken);
        }
    }
}

@media screen and (max-width: 1024px) {
    .OpenBetsItem {
      margin: 4px 4px 4px;
  }
}