.exchangeGamesMobile{
    &-header{
        width: 100%;
        height: 100%;
        position: relative;
        &-img{
            display: block;
            width: 100%;
            height: 100%;
        }
        &-anim {
            min-width: 375px;
            min-height: 352px;
            background: linear-gradient(180deg, #065824 0%, #063E39 100%);
        }
        &-text{
            position: unset;
            display: flex;
            align-items: center;
            height: 133px;
            width: 100%;
            padding: 30px 32px;
            background-color: #86AA16;
            text-align: center;
            z-index: 1;
        }
        &__credo{
            position: absolute;
            top: 7.5%;
            height: 102px;
            width: 81px;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 25px;
            line-height: 34px;
            letter-spacing: 0.09em;
            text-align: left;
            color: #FFFFFF;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
        &__description{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 18px;
            text-align: center;
            letter-spacing: 0.09em;
            color: #FFFFFF;
        }
    }
    &-content{
        width: 100%;
        //height: 100%;
        background: #142326;
        position: relative;
        overflow: hidden;
        &-top_lines {
            position: absolute;
            width: 70%;
            top: 0;
            right: 0;
            z-index: 1;
            &-lines{
                display: block;
                width: 100%;
            }
        }
        &-bottom_lines {
            position: absolute;
            top: 140vw;
            width: 100%;
            z-index: 1;
            &-lines{
                display: block;
                width: 100%;
                background: transparent;
            }
        }
        &__allgames{
            padding: 10px 32px;
        }
        &__livegames{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 25px;
            line-height: 30px;
            letter-spacing: 0.09em;
            color: #09D2EC;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            margin-bottom: 15px;
            position: relative;
            z-index: 3;
        }
        &__items{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 15px;
            margin-bottom: 15px;
        }
        &__item{
            width: 150px;
            height: 121px;
            border: 1px solid #000;
            margin-bottom: 15px;
            border-radius: 15px;
            background: #40424F;
            &-name{
                font-family: 'Lexend';
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 15px;
                text-align: center;
                color: #FFFFFF;
                padding-bottom: 7px;
            }
        }
    }
    .exchangeGamesMobile-content__mobile-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .exchangeGamesMobile-content__mobile-background-img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
    .exchangeGamesMobile-content__title {
        font-family: 'Lato';
        font-weight: 700;
        font-size: 25px;
        letter-spacing: 0.09em;
        color: #86AA16;
        margin: 0;
        text-align: left;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    @media (max-width: 370px) {
        .exchangeGames-contentDesktop {
            justify-content: space-between;
        }
        .exchangeGames-itemDesktop {
            width: 130px;
            height: 110px;
        }
    }
    @media (max-width: 330px) {
        .exchangeGames-itemDesktop {
            width: 122px;
            height: 100px;
        }
    }
}

.exchangeGamesMobile.unauthorized {
    //overflow: hidden;
    width: 100%;
    height: 100%;
    .exchangeGamesMobile-header {
        height: unset;
    .exchangeGamesMobile-header-img {
        height: auto;
    }
    }
}

.banner-mobile{
    width: 150px;
    height: 101px;
}

.exchangeGamesMobile-header-anim {
    position: relative;
    z-index: -1;

    img {
        max-width: unset !important;
    }

    .commonForC {
        position: absolute;
        height: 100%;
        width: 100%;
        top: -50%;
        right: -20%;

        $total: 500;
        $size: 10;

        .c {
            position: absolute;
            width: $size+px;
            height: $size+px;
            margin-top: -$size/2+px;
            margin-left: -$size/2+px;
            transform: translate3d(50vw, 50vh, -1000px);
            border-radius: 50%;
            filter: blur(0.5vh)
        }

        @for $i from 1 through $total {
            $color: hsl(($i * .1)+70, 100%, 50%);

            .c:nth-child(#{$i}) {
                animation: anim#{$i} 1.5s infinite alternate;
                animation-delay: $i * -.015s;
                background: $color;
                background: radial-gradient(circle at top left, lighten($color, 15%), $color);
                box-shadow: 0 0 25px 3px lighten($color, 5%);
                border: 1px solid $color;
            }

            @keyframes anim#{$i} {
                80% {
                    opacity: 1;
                }

                100% {
                    transform: translate3d(random(100)+vw, random(100)+vh, 0);
                    opacity: 0;
                }
            }
        }
    }

    .insideRouletteM,
    .outsideRouletteM {
        position: absolute;
        width: 500px;
        left: -50vw;
        top: 23vh;
    }

    .insideRouletteM {
        animation: spin 17s infinite linear;
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    .girl {
        height: 100%;
        position: absolute;
        z-index: 1;
        bottom: 0;
        right: 0;
    }
}
