.cardContainer {
  width: 100%;
  margin-top: 12px;
  background-image: linear-gradient(90deg, white 0%, #f5dba0 100%);
  max-width: 800px;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  font-weight: 600;
  border-radius: 8px;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  gap: 2px;
}
@media only screen and (min-width: 768px) {
  .cardContainer {
    flex-direction: row;
    align-items: center;
  }
}
.cardImg {
  height: 100%;
  width: 120px;
  object-fit: contain;
}
.cardContainer .text {
  font-weight: 800;
  font-size: 14px;
  padding: 4px;
  color: #505050;
}
.cardContainer .Button {
  width: fit-content;
  font-weight: 600;
  font-size: 12px;
  padding: 4px 12px;
  color: white;
  display: flex;
  //   margin-top: 12px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #00a826;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  &:after {
    background-color: #fff;
    content: '';
    height: 155px;
    left: -75px;
    opacity: 0.2;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: 10;
  }
}
.newBadge {
  color: white;
  background-color: #ff7a40;
  position: absolute;
  top: -12px;
  left: 12px;
  font-size: 10px;
  padding: 4px 12px;
  z-index: 20;

  border-radius: 15px;
  &::after {
    border: 2px solid #ff7a40;
    content: '';
    height: 100%;
    top: -2px;
    left: -2px;
    border-radius: 15px;
    position: absolute;
    width: 100%;
    z-index: 10;
    transform-origin: center;
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
}
.cardContainer .Button {
  &:after {
    left: 120%;
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  }
}

.shimmer {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, 0.2) 60%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 2s infinite;
  transform: rotate(90deg);
}
@keyframes shimmer {
  0% {
    transform: translateX(-100%) rotate(45deg);
  }
  100% {
    transform: translateX(150%) rotate(45deg);
  }
}

@keyframes ping {
  100% {
    transform: scale(1.1);
    opacity: 0;
  }
}
