.cancelPopUpDelete {
  .pop-upDelete_wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 10;
    overflow: auto;

    .pop-upDelete {
      width: 345px;
      // height: 247px;
      background: #ffffff;
      border-radius: 10px;
      position: absolute;
      top: 203px;
      left: 15px;

      padding: 6px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .pop-upDelete-content {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;

      &_title {
        // font-family: 'Lato';
        // // font-style: normal;
        // font-weight: 600;
        // font-size: 14px;
        // line-height: 21px;
        // text-align: center;
        // color: #000000;
        // margin-bottom: 8px;

        color: #000;
        font-family: 'Lato';
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      &_text {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;

        color: #000000;

        width: 100%;
        margin-bottom: 10px;

        textarea {
          width: 100%;
          min-height: 80px;
          display: block;
          // width: 100%;
          // height: calc(2.25rem + 2px);
          padding: 0.375rem 0.75rem;
          // font-family: inherit;
          // font-size: 1rem;
          // font-weight: 400;
          // line-height: 1.5;
          // color: #212529;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #bdbdbd;
          border-radius: 0.25rem;
          transition: border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;

          overflow: auto;
          resize: none;
          outline: none;
          text-align: start;
        }
      }
    }

    .pop-upDelete-content {
      &__buttons {
        display: flex;
        justify-content: flex-end;

        &-send {
          width: 94px;
          height: 37px;
          // left: 92px;
          // top: 438px;
          background: #86aa16;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          color: #ffffff;
          margin: 0 2px;
          padding-top: 7px;

          border-radius: 3px;
        }
        &-edit {
          width: 94px;
          height: 37px;
          // left: 92px;
          // top: 438px;
          background: #40424f;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          color: #ffffff;
          margin: 0 2px;
          padding-top: 7px;

          border-radius: 3px;
        }
        &-cancel {
          width: 94px;
          height: 37px;

          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          color: #969696;
          border: 1px solid #969696;
          border-radius: 3px;
          margin: 0 2px;
          padding-top: 7px;
        }
      }
    }
  }
}

.editPopUpDelete {
  .pop-upDelete_wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 10;
    overflow: auto;
    margin-bottom: 50px;

    .pop-upDelete {
      width: 90%;
      // height: 247px;
      background: #ffffff;
      border-radius: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      padding: 8px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .pop-upDelete-content {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;

      // ------------------------
      .text-field {
        width: 100%;
        margin-bottom: 7px;
      }
      /* стили для label */
      .text-field__label {
        display: block;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;

        color: #000000;
        margin-bottom: 14px;
      }
      /* стили для input */
      .text-field__input {
        display: block;
        width: 100%;
        height: 45px;
        padding: 12px 54px 12px 16px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #40424f;

        border: 1px solid #40424f;
        box-sizing: border-box;
        border-radius: 2px;

        margin-bottom: 10px;

        outline: none;
      }

      .curInput {
        position: relative;
        top: -43px;
        float: right;
        right: 14px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        color: #40424f;
      }

      // IFSC000055 5554785489447 FX PVT LTD

      // ------------------------
      &_title {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #000000;
        margin-bottom: 8px;
        margin-top: 26px;
      }
    }

    .pop-upDelete-content {
      &__buttons {
        display: flex;
        justify-content: space-evenly;
        padding-top: 36px;
        margin-bottom: 28px;

        &-delete {
          cursor: pointer;
          width: 94px;
          height: 37px;

          background: #F44336;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          color: #ffffff;
          margin: 0 2px;
          padding-top: 7px;

          border-radius: 3px;
          }
        &-canc {
          width: 94px;
          height: 37px;

          background: #40424f;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          color: #ffffff;
          margin: 0 2px;
          padding-top: 7px;

          border-radius: 3px;
        }
        &-cancel {
          cursor: pointer;
          width: 94px;
          height: 37px;

          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          color: #969696;
          border: 1px solid #969696;
          border-radius: 3px;
          margin: 0 2px;
          padding-top: 7px;
        }
      }
    }
  }
}
.pop-upDelete-content_title-big{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  margin: 15px 0;
}
.pop-upDelete-content_title-desc{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 11px;
}
.pop-upDelete-third {
  width: 345px;
  // height: 247px;
  background: #ffffff;
  border-radius: 10px;
  position: absolute;
  top: 72px;
  left: 15px;

  padding: 8px 8px 90px 8px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


@media screen and (min-width: 1024px) {
  .editPopUpDelete .pop-upDelete_wrapper .pop-upDelete {
    width: 32%;
    left: 34%;
  }
}

.successPopUpTitle{
  color: #000;
  font-family: Lato;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 15px;
}

.successPopUpSubTitle{
  color: #8B8B8B;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 16.8px */
  margin-top: 13px;
  span{
    color: #000
  }
}

.successPopUpButton{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 90%;
  border-radius: 2px;
  background: #1E8067;
  color: #F9DF0F;
  text-align: center;
  font-family: 'Lato';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 87.5% */
  letter-spacing: 0.84px;
  text-transform: capitalize;
}