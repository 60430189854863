@media (min-width: 1025px) {
  .betting-profit-and-lose-item-list__header {
    background-color: #F9F9F9;
    //color: var(--accordion-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 14px;
  }
  .betting-profit-and-lose-item-list__header-date {
    font-family: 'Lato', sans-serif !important;
    font-weight: 700;
    font-size: 14px;
    //color: var(--accordion-color);
  }
  .betting-profit-and-lose-item-list__header-total-pl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .betting-profit-and-lose-item-list__header-total-pl-title {
    font-family: 'Lato', sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    color: var(--accordion-color);
    padding-right: 4px;
  }
  .betting-profit-and-lose-item-list__header-total-pl-value {
    font-family: 'Lato', sans-serif !important;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    color: var(--positive);
  }

  .pnl-total-value--green {
    color: var(--positive);
  }

  .pnl-total-value--red {
    color: var(--negative);
  }

  .pnl-total-value--black {
    color: var(--black);
  }
}

@media (max-width: 1024px) {
  .betting-profit-and-lose-item-list {
    padding-bottom: 14px;
  }
  .betting-profit-and-lose-item-list__header {
    background-color: var(--accordion-background);
    color: var(--accordion-color);
    height: 31px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 3px 14px;
  }
  .betting-profit-and-lose-item-list__header-date {
    font-family: 'Lato', sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    //color: var(--accordion-color);
  }
  .betting-profit-and-lose-item-list__header-total-pl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .betting-profit-and-lose-item-list__header-total-pl-title {
    font-family: 'Lato', sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--accordion-color);
    padding-right: 4px;
  }

  .betting-profit-and-lose-item-list__header-total-pl-value {
    font-family: 'Lato', sans-serif !important;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--positive);
  }
  .pnl-total-value--green {
    color: var(--positive);
  }
  .pnl-total-value--red {
    color: var(--negative);
  }
  .pnl-total-value--black {
    color: var(--black);
  }
}



