@import '../../styles.scss';

.SessionPopover {
  z-index: 100000 !important;
  pointer-events: all;

  .popover-wrapper {
    width: 100%;
    max-width: 500px;
  }

  .SessionPopover__content-wrapper {
    text-align: center;
    margin-bottom: 35px;
  }

  .popover-content {
    @include popover-content-center();
    overflow: hidden;
    padding: 40px 25px 30px 25px;

    .popover-viewport {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__text {
    //font-family: 'Lato', sans-serif;
    font-style: normal;
    font-size: 23px;
    text-align: center;
    line-height: 20px;

    span {
      display: inline-block;
      margin-top: 15px;
    }
  }

  &__btn {
    @include full-width-button();

    &--disabled {
      --background: none;
      --color: var(--gray);
    }
  }

  .grecaptcha-badge {
    visibility: hidden;
    width: 1px !important;
    height: 1px !important;
    margin: -200px;
  }

  ion-backdrop {
    --ion-backdrop-color: rgba(0, 0, 0, 0.6) !important;
  }

  .SessionPopover__btn {
    background: #6A9772;
    box-shadow: inset -2px -2px 0px #54785B;
    border-radius: 3px;
    width: 200px;
    height: 32px;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 14px;
    --background: #6A9772;
    margin: 0 auto 10px;
  }
}