.SportMarketTabs {
    border-bottom: 1px solid #666666;
    margin-bottom: 15px;

    &__header {
        display: flex;
        border-bottom: 1px solid #666666;
        margin-bottom: 20px;

        &__item {
            padding: 15px 20px;
            text-transform: uppercase;
            cursor: default;
            font-size: 14px;

            &.active {
                border-bottom: 3px solid var(--gray);
            }
        }
    }
    &__markets_fancy-title {
        font-weight: 700;
        font-size: 12px;
        font-family: Lato;
        color: #000000;
        display: flex;
        margin-bottom: 5px;
        div {
            width: 50%;
        }
        .SportMarketTabs__markets_fancy-title-block {
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                display: inline-block;
                width: 66px;
                text-align: center;
            }
        }
    }
}
