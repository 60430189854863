ion-popover {
  --width: 100% !important;
}

.popover-content {
  top: unset !important;
  bottom: -1px;
  left: 0 !important;

  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.login-popover__content-wrapper {
  font-family: 'Lato', sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 21.6px !important;

  padding: 29px 26px 13px 26px;

  color: #595B6A;
}

.login-popover__content-header {
  font-size: 24px !important;

  display: flex;
  align-items: center;

  span {
    padding-left: 23px;
  }
}

.login-popover__header-logo {
  width: 40px;
  height: 40px;
}

.login-popover__footer {
  display: flex;

  height: 57px;
}

.login-popover__left-btn, .login-popover__right-btn {
  height: auto;
  margin: 0 !important;

  flex: 1;

  font-family: 'Lato', sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 24px !important;
  line-height: 29px !important;

  --border-radius: 0;

  box-sizing: border-box;

  letter-spacing: unset;

  --box-shadow: none !important;
  --transition: unset !important;

  --border-width: 2px !important;
  --border-style: solid !important;
  --border-color: #86AA16 !important;

  text-transform: unset;
}

.login-popover__left-btn {
  --background: white;
  color: #86AA16;
}

.login-popover__right-btn {
  --background: #86AA16;
  color: white;
}
