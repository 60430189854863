.EventHeader {
    background: var(--event-info-background-color);
    padding: 4px 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: var(--white);
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    &__details {
        -webkit-box-flex: 1;
            -ms-flex: 1 1 0px;
                flex: 1 1 0;
        margin-right: 10px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;

        &__main {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;

            &__title {
                font-size: 18px;
                font-weight: 700;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                        flex-direction: column;

                &__item {
                    font-size: 14px;
                    font-weight: normal;
                    height: 17px;
                }
            }
        }

        &__score {
            padding: 0 5px;
            background-color: var(--event-header-background-color);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            -webkit-box-pack: space-evenly;
                -ms-flex-pack: space-evenly;
                    justify-content: space-evenly;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            margin: -5px 8px -6px 0px;
            min-width: 30px;

            &__item {
                height: 32px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;

                &:first-child {
                    padding-top: 4px;
                }

                &:last-child {
                    padding-bottom: 6px;
                }
            }

            &__time {
                margin-right: 5px;
            }
        }

        &__additional-container {
            margin-top: 2px;
        }

        &__additional {
            font-size: 10px;
            margin-bottom: 2px;
            font-weight: 400;

            &:last-child {
                margin-bottom: 0;
            }

            &.with-padding {
                padding-left: 24px;
            }
        }
    }

    &__tennis {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: end;
            -ms-flex-align: end;
                align-items: flex-end;
        margin-right: 10px;
        font-size: 14px;
        -webkit-box-pack: space-evenly;
            -ms-flex-pack: space-evenly;
                justify-content: space-evenly;
        margin: -5px 8px -5px 0px;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;

        &__row {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            height: 17px;

            &__item {
                width: 20px;
                text-align: center;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
            }
        }

        &__row:first-child &__current {
            margin-top: -7px;
            padding-top: 7px;
        }

        &__row:last-child &__current {
            margin-bottom: -7px;
            padding-bottom: 7px;
        }

        &__current {
            width: 33px;
            background: var(--event-header-background-color);
            padding: 0 8px;
        }

        &__serving {
            width: 16px;

            .inplay-item__player-batting {
                position: static;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: var(--white);
            }
        }

        &__set {
            &--disabled {
                opacity: .5;
            }
        }
    }

    &__info {
        -webkit-box-flex: 0;
            -ms-flex: 0 1 16px;
                flex: 0 1 16px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        cursor: pointer;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;

        &__icon {
            color: white;
            width: 18px;
            height: 18px;
        }
    }

    .header-icon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        margin-right: 7px;
        font-family: 'apollo';

        &--inplay:before {
            content: '\F144';
            height: 20px;
        }

        &--timer:before {
            content: 'ꀔ';
            height: 20px;
        }
    }
    .fade-in{
         animation: fadeIn 1s;
    }
}
