.container{
    height: 100%;
    display: flex;
    &_first{
        width: 0%;
        display: none;
    }
    &_sec{
        width: 100%;
    }
    &_third {
        width: 0%;
        display: none;
     }
}
.wrapper{
    overflow: scroll;
    min-height: 100%;
    position: relative;
}

@media screen and (min-width: 1024px) {
    .container{
        display: flex;
        &_first{
            width: 15%;
            display: unset;
        }
        &_sec{
            width: 55%;
        }
        &_third {
           width: 28%;
           display: unset;
        }
    }
}

.router-outlet {
    position: relative;
    // left: 20%;
}

.scrollOff{
    overflow: hidden !important;
  }

.default-page__content {
    --padding-bottom: 0 !important;
    &-iframe{
        width: 100%;
        padding: 12px 10px;
        height: 630px;
        border: none;
    }
}
