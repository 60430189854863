.profile {
  padding: 16px;
  &-title {
    font-size: 18px;
    font-family: Lato;
    font-weight: 700;
    margin-left: 16px;
  }
  &_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    background: #fff;
    padding: 24px 16px;
    margin: 16px;
    &_image {
      width: 80px;
      height: 80px;
      background-size: contain;
      background-image: url('../../assets/images/profile//profileFrame.png');
    }
    &_formItem {
      width: 100%;
      font-family: Lato;
      font-weight: 700;
      font-size: 16px;
      &-title {
        margin-bottom: 6px;
        position: relative;
        &.required::after {
          content: '*';
          color: #ff0000;
          margin-left: 1px;
        }
        span {
          color: #858585;
          font-size: 12px;
          font-weight: 400;
        }
        &-verified {
          display: flex;
          justify-self: flex-end;
          align-items: center;
          position: absolute;
          right: 0;
          top: 0;
          color: #4caf50;
          font-size: 14px;
          gap: 5px;
        }
      }
      &-errorMsg {
        color: #ff0000;
        font-size: 12px;
      }
      &-input {
        width: 100%;
        height: 46px;
        background: #f1f1f1;
        border-radius: 4px;
        position: relative;
        font-family: Lato !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        color: #000000 !important;
        letter-spacing: 0.3px !important;
        display: flex;
        align-items: center;
        padding-right: 35px !important;
        input {
          &.native-input[disabled].sc-ion-input-md {
            opacity: 1 !important;
          }
        }
        .placeholder {
          position: unset;
        }
        .react-date-picker__wrapper {
          top: unset !important;
          width: 100% !important;
          background: transparent !important;
          color: #000000 !important;
          font-family: Lato !important;
          font-size: 16px !important;
          font-weight: 600 !important;
          background: #f1f1f1 !important;
          .react-date-picker__inputGroup {
            text-align: left;
            padding-left: 10px;
            span {
              color: #000000 !important;
            }
            input,
            .input,
            .input:disabled {
              cursor: default;
              color: black !important;
            }
          }
        }
        .react-date-picker__calendar {
          z-index: 5;
        }
        &.mob {
          // content: '+91';
          // margin-left: 10px;
          .native-input.sc-ion-input-md {
            padding-inline-start: 3px !important;
            max-width: 40% !important;
          }
        }
        &.errored {
          border: 1px solid #ff0000;
        }
        &-editImg {
          z-index: 5;
          top: 13px;
          position: absolute;
          right: 10px;
          mask-image: url('../../assets/images/profile/profileEdit.svg');
          mask-repeat: no-repeat;
          background-color: #c0c0c0;
          opacity: 0.6;
          &.editable {
            background-color: #000000;
            opacity: 1;
          }
        }
      }
      &-mobInputBox {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
      }
      &-verifyBtn {
        width: 101px;
        height: 35px;
        color: #f9df0f;
        font-size: 14px;
        font-weight: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        transition: all 1s;
        position: absolute;
        right: 5px;
        background: var(--primary);
        border-radius: 4px;
        border: 1px solid var(--primary);
        z-index: 1;
        &.verified {
          width: 35%;
          background: var(--primary);
          color: #fff;
          transition: all 1s;
          img {
            margin-right: 5px;
            transition: all 1s;
          }
        }
      }
      &-verifyBtnsRow {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        &-item {
          height: 35px;
          width: 50%;
          margin-top: 12px;
          font-size: 14px;
          font-weight: normal;
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--primary);
          border-radius: 4px;
          border: 1px solid var(--primary);
          color: #fff;
          transition: all 1s;
          img {
            margin-right: 5px;
          }
          &.loading::after {
            pointer-events: none;
            opacity: 0.5;
            content: '';
            display: block;
            width: 15px;
            height: 15px;
            margin-left: 5px;
            border: 4px solid rgba(0, 0, 0, 0.1);
            border-radius: 50%;
            border-top-color: #000;
            animation: spin 1s ease-in-out infinite;
          }

          @keyframes spin {
            to {
              transform: rotate(360deg);
            }
          }
        }
      }
      .profileInputWrapper {
        position: relative;
        width: 100%;
        .PhoneInput {
          margin-left: 5px;
          input {
            display: none !important;
          }
          &.arrowDisabled {
            .PhoneInputCountrySelectArrow {
              display: none;
            }
          }
        }
      }
    }
  }
  &_submit {
    width: calc(100% - 32px);
    margin: 0 16px 40px;
    color: #f9df0f;
  }
}
