@import '../../styles.scss';

.BetPlacingPopover {
  .popover-wrapper {
    width: 100%;
  }

  .popover-content {
    @include popover-content-center();
    overflow: hidden;
  }

  &--back {
    .popover-content {
      border: 1px solid var(--back-background);
      border-bottom-width: 7px;
    }
  }

  &--lay {
    .popover-content {
      border: 1px solid var(--lay-background);
      border-bottom-width: 7px;
    }
  }

  &__text {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
  }

  &__btn {
    @include full-width-button();

    &--disabled {
      --background: none;
      --color: var(--gray);
    }
  }

  &__name {
    font-size: 18px;
  }

  &__runner {
    font-size: 16px;
    margin: 15px 0;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }

  &__col {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  &__params {
    padding: 20px 0;
    border: 1px solid var(--input-border);
    border-radius: 2px;
    margin-bottom: 10px;
  }

  &__param {
    font-size: 11px;
  }

  &__value {
    font-size: 18px;
    font-weight: bold;
    margin-top: 5px;
  }

  &__timertext {
    font-size: 14px;
    font-weight: bold;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  &__timer {
    height: 64px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    margin: 30px 0;
    position: relative;

    &__seconds {
      font-size: 21px;
      position: absolute;
      margin: 20px auto;
    }

    &__spinner {
      height: 64px;
      width: 64px;
    }
  }
}

.betPlacingPop-upContainer {
  .betPlacingPop-up_wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    .betPlacingPop-up {
      width: 95%;
      background: #ffffff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      // padding: 10px;

      &--back {
        .betPlacingPop-up-content {
          border: 1px solid var(--back-background);
          border-bottom-width: 7px;
        }
      }

      &--lay {
        .betPlacingPop-up-content {
          border: 1px solid var(--lay-background);
          border-bottom-width: 7px;
        }
      }
    }

    .betPlacingPop-up-content {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 14px;
      color: black;

      .BetPlacing__row.BetPlacingPopover__text {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
      }

      .BetPlacing__row.BetPlacingPopover__btn {
        @include full-width-button();

        &--disabled {
          --background: none;
          --color: var(--gray);
        }
      }

      .BetPlacing__row.BetPlacingPopover__name {
        font-size: 18px;
      }

      .BetPlacing__row.BetPlacingPopover__runner {
        font-size: 16px;
        margin: 15px 0;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
      }

      .BetPlacing__row.BetPlacingPopover__col {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
      }

      .BetPlacing__row.BetPlacingPopover__params {
        padding: 20px 0;
        border: 1px solid var(--input-border);
        border-radius: 2px;
        margin-bottom: 10px;
      }

      .BetPlacing__row.BetPlacingPopover__param {
        font-size: 11px;
      }

      .BetPlacing__row.BetPlacingPopover__value {
        font-size: 18px;
        font-weight: bold;
        margin-top: 5px;
      }

      .BetPlacing__row.BetPlacingPopover__timertext {
        font-size: 14px;
        font-weight: bold;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
      }

      .BetPlacing__row.BetPlacingPopover__timer {
        height: 64px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 100%;
        margin: 30px 0;
        position: relative;

        &__seconds {
          font-size: 21px;
          position: absolute;
          margin: 20px auto;
        }

        &__spinner {
          height: 64px;
          width: 64px;
        }
      }
    }
  }

}
