@import '../../styles.scss';

.LadderPopover {
  .popover-wrapper {
    width: 100%;
  }

  &-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);

    &-content {
      width: 95%;
      background: #ffffff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      padding: 15px;
    }
  }

  &__title {
    font-family: var(--ion-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 10px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin: 5px 0;

    &__row {
      display: flex;

      &__item {
        height: 29px;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        font-family: var(--ion-font-family);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        // text-align: center;
        color: #000000;
      }

      &__value {
        border: 1px solid #ddd;

        &.positive {
          color: var(--positive);
        }

        &.negative {
          color: var(--negative);
        }
      }
    }
  }

  &__btn {
    @include full-width-button();
    --background: none;
    --color: var(--gray);
    border: var(--ladder-close-button-border-color-color);

    &:hover {
      --background: #327eff;
    }
  }
}

@media screen and (min-width: 1024px) {
  .LadderPopover-wrapper-content {
    width: 40%;
  }
}
