.casino-content {
  width: 100%;
  margin-top: 8px;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, 120px);
  grid-row-gap: 2px;

  & .casino-item {
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
  }

  & .casino-img-body {
    width: 100%;
    height: 70px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
  }
}

.inplay-item-list-casino__header {
  min-height: 32px;
  padding: 5px 0;
  margin-top: 8px;
  --background: white !important;
  --color: var(--text-color);

  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }

  &-logo {
    display: flex;
    align-items: center;

    padding-left: 9px;

    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }
}

.inplay-content__logo-icon {
  position: relative;
  padding-right: 8px;
  font-weight: normal;
  font-style: unset;
  width: 24px;
  height: 24px;
}


.inplay-content__logo-icon {
  font-family: 'apollo';
}

.inplay-content__logo-icon--casino:before {
  content: "";
}

.inplay-content__logo-icon--exchange-games:before {
  content: "";
}

.inplay-content__logo-icon--election {
  padding-top: 4px;

  &:before {
    content: url(../../assets/images/menu-icons/election-icon.svg);
  }
}

.inplay-color-icon {
  color: var(--in-play-block-color);
}



@media(min-width: 500px) {
  .casino-content {
    grid-template-columns: repeat(auto-fit, 117px);
    grid-column-gap: 4px;
    grid-row-gap: 4px;

    & .casino-img-body {
      height: 70px;
    }
  }
}

@media (min-width: 1024px) {
  .casino-content {
    justify-content: space-between;
    width: 100%;
    display: grid;
    grid-template-rows: auto auto;
    grid-auto-flow: column;
    overflow-x: auto;
    grid-row-gap: 2px;
    grid-column-gap: 2px;

    & .casino-item {
      width: 117px;
      height: 70px;

      & .casino-img-body {
        height: 70px;
      }
    }

    /* Webkit scrollbar styles for .casino-content */
    &::-webkit-scrollbar {
      height: 0.5em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
  }
}

@media(max-width: 1024px) {
  .casino-content {
    justify-content: space-between;
    width: 100%;
    display: grid;
    grid-template-rows: auto auto;
    grid-auto-flow: column;
    overflow-x: scroll;
    grid-row-gap: 2px;
    grid-column-gap: 2px;

    // &::-webkit-scrollbar {
    //   display: none;
    // }
    & .casino-item {
      width: 117px;
      height: 70px;

      & .casino-img-body {
        height: 70px;
      }
    }
  }
}