@import '../../styles.scss';

.DesktopBetPlacing {
  padding: 0px 5px;
  margin-bottom: 8px;
  background: var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: var(--gray);
  position: relative;

  &__topMark {
    display: flex;
    margin: 25px 0 7px 7px;
    &-back {
      background-color: var(--back-background);
      width: 12px;
      height: 12px;
    }
    &-lay {
      background-color: var(--lay-background);
      width: 12px;
      height: 12px;
    }
    &-title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #000000;
      margin-left: 4px;
    }
  }
  &__matchName {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    margin-bottom: 5px;
    margin-left: 11px;
  }

  &__betName {
    display: flex;
    // width: 100%;
    justify-content: space-between;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    margin-top: 6px;
    margin-left: 5px;
  }

  &-container {
    overflow: hidden;
    margin-top: -2px;
    z-index: 2;
    position: relative;
    background-color: white;
    padding: 3px;

    .DesktopBetPlacing__empty-message {
      font-size: 12px;
      text-align: center;
      padding: 25px 10px;
      color: #000000;
    }
  }

  &--back {
    border: 1px solid var(--back-background);
    background-color: var(--back-background);
    border-bottom-width: 7px;
  }

  &--lay {
    border: 1px solid var(--lay-background);
    background-color: var(--lay-background);
    border-bottom-width: 7px;
  }

  &__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    // margin-top: 6px;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;

    &--actions {
      display: flex;
      flex-direction: column;
      &-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2px;
        &-clear {
          display: flex;
          justify-content: flex-end;
          padding-right: 3px;
          align-items: center;
          width: 24.5%;
          height: 28px;
          cursor: pointer;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 12px;
          color: black;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      background-color: white;
      padding: 5px;
      margin-top: 9px;
      margin-bottom: 6px;
    }

    &-oddchanged {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 20px;
      text-align: center;
      color: #694b1c;
      background: #fef8d3;
      align-items: center;
      border: 1px solid #f6d69b;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 2px;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      &::before {
        content: url('../../assets/images/infoLogo.svg');
        position: relative;
        margin-right: 2px;
        top: 3px;
      }

      @media (max-width: 360px) {
        font-size: 14px;
      }

      @media (max-width: 320px) {
        font-size: 12px;
      }
    }
  }

  &__col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    margin-right: 15px;
    //width: 21%;
    width: 22.75%;

    &-delete {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      //width: 100%;
      width: 20px;
      margin-left: auto;
      padding-bottom: 5px;

      &-btn {
        width: 19px;
        height: 19px;
        box-shadow: inset -2px -2px 0px #8d3a31;
        border-radius: 3px;
        background-color: #b2493e;
        cursor: pointer;
        &::after {
          content: 'x';
          color: white;
          position: relative;
          bottom: 2px;
          left: 5px;
        }
        &:hover {
          background-color: #b33426;
        }
        &:active {
          box-shadow: inset 2px 2px 0px #8d3a31;
        }
      }
    }

    &--small {
      width: 100px;
      margin-right: 10px;
    }
  }

  &__addstake {
    margin: 0;
    width: 24.5%;
    height: 28px;
    background: var(--alt-secondary);
    box-shadow: inset -2px -3px 0px var(--cta-primary-darken);
    // inset -2px -3px 0px
    border-radius: 3px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    color: var(--bet-form-stake-buttons-color);
    &:hover {
      box-shadow: inset -2px -2px 0px 0px var(--cta-primary-darken), 1.5px 1.5px 0px 0px var(--cta-primary-darken);
      background-color: var(--alt-secondary);
      // inset -2px -2px 0px 0px #030404, 1px 2px 0px 0px #030404;
    }
    &:active {
      box-shadow: inset -2px -2px 0px 0px #030404;
    }
  }

  &__btn {
    @include full-width-button();
    height: 45px;

    &--cancel {
      --background: var(--cta-secondary);
      --color: var(--cta-disabled);
      --background-hover: var(--cta-secondary-darken);
      --background-hover-opacity: 1;
      border: 1px solid var(--cta-disabled);
    }

    &--place {
      background: var(--secondary);
      color: #fff;
      border-radius: 2px;
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-top: 4px;
      transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1),
        background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
        color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
        -webkit-box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);

      &.nb {
        margin-top: 0;
      }

      &:hover {
        background: var(--cta-primary-darken);
      }

      &:disabled {
        background: var(--cta-disabled);
        cursor: default;
      }

      &:focus {
        outline: none;
      }
    }
  }

  &__profit {
    margin-top: 3px;
    font-size: 11px;
  }

  &__delay {
    position: absolute;
    right: 3px;
    top: 5px;
    font-size: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
  }

  &__input {
    height: 35px;
    border-radius: 2px;
    border: 1px solid var(--input-border);
    padding: 9px;
    --padding-end: 13px !important;

    &.has-focus {
      --color: var(--black);
      border: 2px solid var(--gray);
    }
  }
  .DesktopBetPlacing__stake.DesktopBetPlacing__col--large {
    .DesktopBetPlacing__stake {
      width: 100%;
      margin: 0;
      .DesktopBetPlacing__input.DesktopBetPlacing__stake__input {
        margin: 0 !important;
        width: 100%;
        background-color: white;
        input.native-input  {
          padding: 0 5px;
        }
      }
    }
  }
  .DesktopBetPlacing__col.profit--column {
    width: 100%;
    max-width: 79px;
    overflow: hidden;
    .DesktopBetPlacing__odd__text {
      //line-height: 1;
      font-size: 10px;
      height: 8px;
    }
    .DesktopBetPlacing__odd {
      align-items: flex-start;
    }
  }

  &__stake {
    width: 28%;
    margin-right: 8px;

    input.native-input {
      &::-webkit-input-placeholder {
        text-align: left;
        font-size: 8px;
      }

      &::-moz-placeholder {
        text-align: left;
        font-size: 8px;
      }

      &:-ms-input-placeholder {
        text-align: left;
        font-size: 8px;
      }

      &::-ms-input-placeholder {
        text-align: left;
        font-size: 8px;
      }

      &::placeholder {
        text-align: left;
        font-size: 8px;
      }
    }
    &__input {
      height: 27px;
      width: 85px;
      border-radius: 3px 0 0 3px;
      transition: box-shadow 0.2s ease-out, -webkit-box-shadow 0.2s ease-out;
      //margin-right: 20px;
      border-color: white;
      border-radius: 3px;
      

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type='number'] {
        -moz-appearance: textfield;
        appearance: textfield;
      }

      input.native-input {
        text-align: left;
        color: #666666;
        font-size: 12px;

        &:focus {
            font-weight: 700;
            color: #0F2327;
            border-color: #0F2327;
            // border: 2px solid #0F2327;
          }
      }

      &.incremented {
        -webkit-box-shadow: inset 0 0 8px rgba(68, 10, 71, 0.4);
        box-shadow: inset 0 0 8px rgba(68, 10, 71, 0.4);
      }
      &--rounded {
        border-radius: 3px;
        //margin-right: 20px;
        width: 70px;
        color: #666666;
      }
    }
  }

  &__odd {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: 5px;

    &__input {
      height: 28px;
      width: 50px;
      border-radius: 3px 0 0 3px;
      transition: box-shadow 0.2s ease-out, -webkit-box-shadow 0.2s ease-out;
      border-color: white;
      background-color: white;
      // margin-left: 5px;

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type='number'] {
        -moz-appearance: textfield;
        appearance: textfield;
      }

      input.native-input {
        text-align: left;
        color: #666666;
        font-size: 12px;
        padding: 7px 0 6px 6px!important;

        &:focus {
          font-weight: 700;
          color: #0F2327;
          border-color: #0F2327;
        }
      }

      

      &.incremented {
        -webkit-box-shadow: inset 0 0 8px rgba(68, 10, 71, 0.4);
        box-shadow: inset 0 0 8px rgba(68, 10, 71, 0.4);
      }
      &--rounded {
        border-radius: 2px;
      }
    }

    &__text {
      height: 25px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;

      color: #000000;

      &--small {
        width: 100px;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        margin-right: 10px;
      }
    }
  }

  &__changeodd {
    margin: 0;
    height: 14px;
    width: 14px;
    font-size: 20px !important;
    background-color: #59b6ff;
    color: var(--bet-form-stepper-buttons-color);
    transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      -webkit-box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    overflow: hidden;
    padding: 0;

    &.lay {
      background: #ffa3b8;
    }

    &-buttonsCol {
      display: flex;
      flex-direction: column;
      &-minus {
        border-radius: 0 0 3px;
        &::after {
          content: url('../../assets/images/stakeUpArrow.svg');
          position: relative;
          top: -8px;
        }
        &:hover {
          background: #c7d1db;
          box-shadow: 2px 1px 0px rgba(0, 0, 0, 0.25);
        }
        &:active {
          background: #a8afb7;
          box-shadow: inset 2px 2px 0px rgba(0, 0, 0, 0.25);
        }
      }

      &-plus {
        border-radius: 0 3px 0 0;
        &::after {
          content: url('../../assets/images/stakeUpArrow.svg');
          position: relative;
          top: 2px;
          right: 3px;
          display: flex;
          transform: rotate(180deg);
        }
        &:hover {
          background: #c7d1db;
          box-shadow: 2px 1px 0px rgba(0, 0, 0, 0.25);
        }
        &:active {
          background: #81878d;
          box-shadow: inset 2px 2px 0px rgba(0, 0, 0, 0.25);
        }
      }
    }

    &:focus {
      outline: none;
    }

    &__text {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    span {
      position: absolute;
      background: var(--primary);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      pointer-events: none;
      border-radius: 50%;
      -webkit-animation: animate 0.7s linear forwards;
      animation: animate 0.7s linear forwards;
    }
  }
  ion-button {
    margin: 0;
    width: 23%;
    height: 28px;
    background: #13272c;
    box-shadow: inset -2px -2px 0px #030404;
    border-radius: 3px;
  }

  .oneClickToggle {
    --handle-background-checked: var(--white);
    border: 1px solidvar(--gray);
    background-color: var(--positive);
    --background-checked: var(--gray);

    &.toggle-checked {
      --background-checked: var(--positive);
      border: 1px solid var(--positive);
      --handle-box-shadow: 0px 0px 0px 1px var(--positive);

      &::after {
        content: 'on';
        color: var(--white);
        background: var(--positive);
      }
    }
  }

  .small1 {
    width: 60px;
  }

  .small2 {
    width: 40px;
  }
  &__bottomBtns {
    display: flex;
    justify-content: flex-end;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
    color: #ffffff;
    margin-bottom: 5px;

    .betPlacingPushable {
      &:disabled {
        background: #f5f5f5;
        color: #9a9a9a;
        box-shadow: none;
      }
      position: relative;
      width: 30%;
      height: 28px;
      margin-right: 13px;
      border: none;
      background: transparent;
      padding: 0;
      cursor: pointer;
      outline-offset: 4px;
      transition: filter 250ms;

      margin-top: 4px;
    }
    .betPlacingFront {
      display: block;
      position: relative;
      height: 28px;

      &.betPlacingRefreshBtn {
        background: #6a9772;
      }

      &.betPlacingEditBtn {
        background: #40424f;
      }

      // font-family: 'Lato';
      // font-style: normal;
      // font-weight: 700;
      // font-size: 12px;
      line-height: 24px;
      // text-align: center;
      // color: #FFFFFF;

      border-radius: 3px;
      will-change: transform;
      transform: translateY(-4px);
      transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
    }

    .betPlacingPushable:hover {
      &:disabled {
        background: #f5f5f5;
        color: #9a9a9a;
        box-shadow: none;
      }
      filter: brightness(110%);

      .betPlacingShadow {
        position: absolute;
        top: -2px;
        left: 0px;
        width: 100%;
        height: 28px;
        border-radius: 3px;
        background: rgba(0, 0, 0, 0.25);
        will-change: transform;
        transform: translateY(2px);
        transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
      }

      .betPlacingEdge {
        position: absolute;
        top: -2px;
        left: 0px;
        width: 99.5%;
        height: 28px;
        border-radius: 3px;

        &.betPlacingRefreshBtn {
          background: #6a9772;
        }

        &.betPlacingEditBtn {
          background: #40424f;
        }

        &.betPlacingCancelBtn {
          background: #fff;
          color: #969696;
        }

        filter: brightness(75%);
      }
    }

    .betPlacingPushable:hover .betPlacingFront {
      transform: translateY(-6px);
      transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
    }

    .betPlacingPushable:active .betPlacingFront {
      transform: translateY(-2px);
      transition: transform 34ms;
    }

    .betPlacingPushable:hover .betPlacingShadow {
      transform: translateY(4px);
      transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
    }

    .betPlacingPushable:active .betPlacingShadow {
      transform: translateY(1px);
      transition: transform 34ms;
    }

    .betPlacingPushable:focus:not(:focus-visible) {
      outline: none;
    }

    &-remove {
      // width: 32%;
      width: 81px;
      height: 28px;
      background: #b2493e;
      box-shadow: inset -2px -2px 0px #8d3a31;
      border-radius: 3px;
      margin-right: 13px;

      font-size: 12px;
      font-weight: 500;

      cursor: pointer;
      color: white;
      &:hover {
        background-color: #cb4c43;
      }
      &:active {
        box-shadow: inset 2px 2px 0px #8d3a31;
      }
    }
    &-placeBet {
      // width: 30%;
      width: 75px;
      height: 28px;
      color: white;
      margin-right: 13px;
      background-color: #5c996f;
      box-shadow: inset -2px -2px 0px #497958;
      border-radius: 3px;
      border-bottom: 1px solid #497958;
      border-right: 1px solid #497958;

      font-size: 12px;
      font-weight: 500;

      cursor: pointer;
      &:hover {
        background-color: #69a57c;
        box-shadow: 1.5px 1.5px 0px 0px #3f694c;
      }
      &:active{
        background-color: #58936a;
        color: #FFFFFF;
        border-top: 1px solid #477656;
        border-left: 1px solid #477656;
        box-shadow: none;
        border-bottom: none;
        border-right: none;
      }
      &:disabled {
        background: #f5f5f5;
        color: #9a9a9a;
        box-shadow: none;
        border: none;
      }
    }
  }
  &__confirm {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    align-items: center;
    cursor: pointer;
    &-checkbox {
      width: 16px;
      height: 16px;
      background: #ffffff;
      border: 2px solid #dddddd;
      box-sizing: border-box;
      margin-right: 2px;
      &.checked {
        &::after {
          content: 'L';
          color: black;
          font-size: 14px;
          display: inline-block;
          position: relative;
          transform: matrix(-0.7, -0.71, -0.71, 0.7, 0, 0);
          bottom: 6px;
          left: 3px;
        }
      }
    }
    &-title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #000000;
    }
  }
  &__changedOdds {
    &-accept {
      position: relative;
      float: right;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      width: 43%;
      height: 28px;
      background: #69a57c;
      // box-shadow: inset -4px -4px 0px rgba(0, 0, 0, 0.25),
      //   inset -2px -2px 0px #497958;
      box-shadow: inset -2px -2px 0px #497958;
      border-radius: 3px;
      margin-top: 8px;
      cursor: pointer;
      // &:hover {
      //   background-color: #497958;
      // }
      // &:active {
      //   box-shadow: inset 2px 2px 0px #497958;
      // }
            &:hover {
              background-color: #69a57c;
              box-shadow: 1.5px 1.5px 0px 0px #3f694c;
            }
      
            &:active {
              background-color: #58936a;
              color: #FFFFFF;
              border-top: 1px solid #477656;
              border-left: 1px solid #477656;
              box-shadow: none;
              border-bottom: none;
              border-right: none;
            }
    }
  }
}

.f10 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #000000;

  padding-left: 11px;
  margin-top: 6px;
  margin-bottom: 4px;
}

.apl-icon-info-stopwatch {
  margin-bottom: 1px;

  &:before {
    font-style: normal;
    font-family: 'apollo';
    content: '';
  }
}

@-webkit-keyframes animate {
  0% {
    width: 0px;
    height: 0px;
    opacity: 0.7;
  }

  100% {
    width: 100px;
    height: 100px;
    opacity: 0;
  }
}

@keyframes animate {
  0% {
    width: 0px;
    height: 0px;
    opacity: 0.7;
  }

  100% {
    width: 100px;
    height: 100px;
    opacity: 0;
  }
}
