@mixin banner-height() {
  height: 75px;

  @media (max-width: 360px) {
    height: 71px;
  }

  @media (max-width: 320px) {
    height: 62px;
  }
}
.inplay-item-list {

  &_exchangeGames {
    background-color: transparent;
  }
  &_popularGames {
    background-color: transparent;
  }
  &_casino {
    background-color: transparent;
  }
}

.inplay-item-list__header {
  min-height: 32px;
  padding: 5px 0;
  --background: white !important;
  --color: var(--text-color);
}

.inplay-item-list__header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.inplay-item-list__header-logo {
  display: flex;
  align-items: center;

  padding-left: 9px;

  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}

.inplay-content__logo-icon {
  position: relative;
  padding-right: 8px;
  font-weight: normal;

  font-style: unset;

  width: 24px;
  height: 24px;
}

.inplay-item-list__header-title {
  font-family: 'Lato', sans-serif !important;
  font-style: normal !important;
  font-size: 10px !important;
  line-height: 19px !important;
  color: #000;

  width: 165px;

  display: flex;

  span {
    flex: 1;
    text-align: center;
  }
}

.inplay-content__logo-icon {
  font-family: 'apollo';
}

.inplay-content__logo-icon--football:before {
  content: "ꀄ";
}

.inplay-content__logo-icon--tennis:before {
  content: "ꀅ";
}

.inplay-content__logo-icon--cricket:before {
  content: "ꀃ";
}

.inplay-content__logo-icon--casino:before {
  content: "";
}

.inplay-content__logo-icon--horse:before {
  content: "ꀂ";
}

.inplay-content__logo-icon--greyhound:before {
  content: "ꀁ";
}

.inplay-content__logo-icon--exchange-games:before {
  content: "";
}

.inplay-content__logo-icon--election {
  padding-top: 4px;

  &:before {
    content: url(../../assets/images/menu-icons/election-icon.svg);
  }
}

.inplay-color-icon {
  color: var(--in-play-block-color);
}

.popular-color-icon {
  color: var(--most-popular-block-color);
}

@media screen and (min-width: 1024px){
  .inplay-item-list__header-content {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;

    .inplay-item-list__header-logo {
      width: calc((100% - 34px) / 2);
    }

    .inplay-item-list__header-title {
      width: calc((100% - 34px) / 2);
    }
  }
}
