@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap');

.ChangePassword {
  --padding-top: 15px;
  --padding-start: 15px;
  --padding-end: 15px;

  &__form {
    width: 100%;
    margin-top: 10px;
  }

  &__list {
    background: none !important;

    ion-item {
      --min-height: 36px;
      --padding-start: 0px;
      --padding-end: 0px;
      --inner-padding-end: 0px;
      --inner-border-width: 0px;
    }

    ion-item+ion-item {
      margin-top: 12px;
    }
  }

  &__button {
    width: 50%;
    margin: 20px 0 auto auto;
    text-transform: none;
    background: var(--secondary);
    color: #fff;
    border-radius: 2px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: box-shadow .3s cubic-bezier(.4, 0, .2, 1), background-color .3s cubic-bezier(.4, 0, .2, 1), color .3s cubic-bezier(.4, 0, .2, 1), -webkit-box-shadow .3s cubic-bezier(.4, 0, .2, 1);
    height: 36px;
    font-weight: 500;
    margin-top: 24px;

    &:hover {
      background: var(--cta-primary-darken);
    }

    &:disabled {
      background: var(--cta-disabled);
      cursor: default;
    }

    &:focus {
      outline: none;
    }
  }

  &__form-error {
    padding: 0 10px 5px;
    margin-top: -2px;
    font-size: 10px;
    color: var(--negative);

    ion-item+& {
      padding: 0 10px 10px;
      margin-top: 9px;
    }
  }

  ion-item {
    --highlight-background: var(--login-highlight-bg);
    --border-radius: 2px;
  }

  ion-input {
    border-radius: 2px;
    height: 100%;
  }

  .native-input {
    border-radius: 2px;
    height: 100%;

    &:focus {
      border: 1px solid var(--login-item-focused-border);
    }
  }
}

.thankswithdraw-container {
  margin-top: 180px;

  &__content {
    text-align: center;
  }
}

.thanks-content {
  &__image {
    margin-bottom: 32px;

    &-big {
      display: none;
    }
  }

  &__thankyou {
    font-family: 'Lato';
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    color: #40424F;
  }

  &__description {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #40424F;
    margin: 16px 36px;
  }

  &__buttons {
    display: flex;
    justify-content: center;

    &-back {
      width: 94px;
      height: 37px;
      left: 92px;
      top: 438px;
      background: var(--secondary);
      ;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #FFFFFF;
      margin: 0 2px;
      padding-top: 7px;
    }

    &-transaction {
      width: 94px;
      height: 37px;
      left: 92px;
      top: 438px;
      background: var(--secondary);
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #FFFFFF;
      margin: 0 2px;
      padding-top: 7px;
    }
  }
}

.favourites-header {
  position: relative;

  font-size: 24px;
  line-height: 36px;

  color: #0f2327;

  margin: 0 10px;

  margin-bottom: 10px;
  padding: 10px 0px;
  border-bottom: 1px solid rgba(15, 35, 39, 0.4);
}

@media screen and (min-width: 1024px) {
  .thankswithdraw-container {
    width: 80vw;
    height: 70vh;
    background: #fff;
     margin: unset;
    transform: translate(9vw, 20vh);
    box-shadow: -1px 1px 10px rgb(56, 56, 56);

    &__content {
      padding: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }
  }

  .thanks-content__image {
    margin: 50px 70px;

    &-small {
      display: none;
    }

    &-big {
      margin: 0 auto;
      display: block;
    }
  }

  .thanks-content__thankyou {
    font-size: 30px;
    line-height: 35px;
  }

  .thanks-content__description {
    font-size: 20px;
    line-height: 17px;
    margin: 33px 25% 60px 25%;
  }

  .thanks-content__buttons {
    margin-bottom: 40px;

    &-back {
      font-size: 16.6486px;
      line-height: 23px;
    }

    &-transaction {
      font-size: 16.6486px;
      line-height: 23px;
    }
  }

  .thanks-content__buttons {

    // -------------------------
    .pushable {
      position: relative;
      border: none;
      background: transparent;
      padding: 0;
      cursor: pointer;
      outline-offset: 4px;
      transition: filter 250ms;
      margin-top: 4px;
    }

    .front {
      display: block;
      position: relative;
      width: 130px;
      height: 39px;

      background: var(--secondary);

      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16.6486px;
      line-height: 23px;
      text-align: center;
      color: #ffffff;
      margin: 0 2px;
      padding-top: 8px;

      border-radius: 3px;
      will-change: transform;
      transform: translateY(-4px);
      transition: transform 600ms cubic-bezier(.3, .7, .4, 1);
    }

    .pushable:hover {
      filter: brightness(110%);

      .shadow {
        position: absolute;
        top: -2px;
        left: 2px;

        width: 130px;
        height: 39px;

        border-radius: 3px;
        background: rgba(0, 0, 0, 0.25);
        will-change: transform;
        transform: translateY(2px);
        transition:
          transform 600ms cubic-bezier(.3, .7, .4, 1);
      }

      .edge {
        position: absolute;
        top: -2px;
        left: 2px;

        width: 130px;
        height: 39px;

        border-radius: 3px;

        background: var(--secondary);


        filter: brightness(75%)
      }
    }

    .pushable:hover .front {
      transform: translateY(-6px);
      transition:
        transform 250ms cubic-bezier(.3, .7, .4, 1.5);
    }

    .pushable:active .front {
      transform: translateY(-2px);
      transition: transform 34ms;
    }

    .pushable:hover .shadow {
      transform: translateY(4px);
      transition:
        transform 250ms cubic-bezier(.3, .7, .4, 1.5);
    }

    .pushable:active .shadow {
      transform: translateY(1px);
      transition: transform 34ms;
    }

    .pushable:focus:not(:focus-visible) {
      outline: none;
    }
  }
}


@media screen and (min-width: 1366px) {
  .thankswithdraw-container {
    background: #fff;

    // margin: 90px 130px 50px 130px;
    &__content {
      padding: 110px 0;
    }
  }

  .thanks-content__image {
    &-small {
      display: none;
    }
  }

  .thanks-content__thankyou {
    font-size: 36px;
    line-height: 43px;
  }

  .thanks-content__description {
    font-size: 24px;
    line-height: 21px;
    margin: 33px 27% 80px 27%;
  }

  .thanks-content__buttons {
    &-back {
      font-size: 19px;
      line-height: 22px;
      width: 12%;

    }

    &-transaction {
      font-size: 19px;
      line-height: 22px;
      width: 12%;
    }
  }
}

.StartEventTimeCounter {
  color: #0f2327;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: right;
}
