@import '../../styles.scss';

.LegalAgePopover {
  @include full-size-popover;

  .popover-wrapper {
    background: var(--gray);
  }

  .popover-content {
    @include popover-content-center();
  }

  &__content {
    text-align: center;
    margin-top: 15px;
  }

  &__text {
    font-size: 18px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
  }

  &__btn {
    &--no {
      @include full-width-button($height: 42px,
        $font-size: 18px,
      );
      --background: none;
      --color: var(--gray);
    }
    height: 42px;
    flex-basis: calc(50% - 7px);
    flex-grow: 0;
  }
}