.RulesPage {
    &__header {
        display: flex;
        color: var(--white);
        align-items: center;
        background: var(--primary);
        padding: 12px 16px;

        &__title {
            font-size: 18px;
            font-weight: 700;
        }

        &__btn {
            margin: 0 16px 0 0;
            height: 25px;
            --background: transparent;
        }

        .menu-icon.go-back-icon:before {
            content: "";
            width: 19px;
            height: 13px;
            position: absolute;
            background-color: var(--icon-color-in-buttons);
            mask-image: url("../../assets/images/goback.svg");
            mask-size: cover;
            mask-position: center;
            mask-repeat: no-repeat;
            top: 0;
            left: 0;
        }
    }

    .rules-popupSportEvents__content {
        font-family: 'Lato';
        font-weight: 400;
        font-size: 13px;
        line-height: 22px;
        color: #000000;
        overflow-y: auto;
    }

    .rulesContainer {
        padding: 0 13px 13px;

        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;

        color: #000000;

    }

    .rulesTitle {
        font-family: 'Lato';
        font-size: 18px;
        font-weight: bold;
        padding: 15px 13px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .arrowIcon {
        width: 15px;
        height: 15px;
        margin-right: 15px;
        transform: rotate(180deg);
    }

    .arrowIconRot {
        width: 15px;
        height: 15px;
        margin-right: 15px;
    }

    .rules-accordion__icon {
        width: 19px;
        height: 19px;
        margin-right: 10px;
        border-radius: 50%;
        border: 1px solid #bababa;
        position: relative;
        display: inline-block;
        vertical-align: bottom;

        &::before {
            content: '';
            width: 5px;
            height: 5px;
            border-top: 1px solid #bababa;
            border-left: 1px solid #bababa;
            position: absolute;
            left: 6px;
            top: 6px;
            transition: transform 0.3s linear;
            transform: rotate(45deg);
        }
    }

    .rules-accordion__iconRot {
        width: 19px;
        height: 19px;
        margin-right: 10px;
        border-radius: 50%;
        border: 1px solid #bababa;
        position: relative;
        display: inline-block;
        vertical-align: bottom;

        &::before {
            content: '';
            width: 5px;
            height: 5px;
            border-top: 1px solid #bababa;
            border-left: 1px solid #bababa;
            position: absolute;
            left: 6px;
            top: 6px;
            transition: transform 0.3s linear;
            transform: rotate(-135deg);
        }

    }

    .show {
        display: block;
        height: 100%;
        animation: sss 0.5s linear;

        .rulesContainer {
            div {
                white-space: break-spaces !important;
                font-family: 'Lato, Avenir, Verdana, Nunito' !important;
                font-size: 14px !important;
                font-weight: normal !important;
                line-height: 17px !important;
                color: #0f2327 !important;
            }

            span {
                color: #0f2327 !important;
            }
        }
    }

    @keyframes sss {
        0% {
            opacity: 0;
            height: 0px;
        }

        100% {
            opacity: 1;
            height: 600px;
        }
    }

    .showFancy {
        display: block;
        height: 100%;
        animation: sss 0.5s linear;

        .rulesContainer {
            div {
                font-family: 'Lato, Avenir, Verdana, Nunito' !important;
                font-size: 14px !important;
                font-weight: normal !important;
                line-height: 17px !important;
                color: #0f2327 !important;
            }
        }
    }

    .notShow {
        opacity: 0;
        top: -100%;
    }

    .dropdown {
        margin-bottom: 4px;
        border-top: 1px solid #bababa;
        border-bottom: 1px solid #bababa;
        background: linear-gradient(#fff, #fafafa);
    }

    .dropdownFancy {
        margin-bottom: 4px;
        border-top: 1px solid #bababa;
        border-bottom: 1px solid #bababa;
        background: linear-gradient(#fff, #fafafa);
    }
}