.MyMarket {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: bold;

    &__list {
        margin-top: 16px;
        background: transparent;
        padding: 0 4px;
        &__header {
            font-size: 18px;
            padding-inline-start: 11px;
        }
        &__empty {
            font-size: 14px;
            font-style: normal;
            font-weight: normal;
            padding-inline-start: 11px;
        }

        &__item {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            position: relative;
            cursor: pointer;
            --border-style: none;
            --padding-start: 11px;
            --padding-end: 11px;
            margin-bottom: 2px;
            
            &__icon {
                position: relative;
                top: -3px;
                margin-right: 15px;
                font-family: 'apollo';
            }
        }
    }
}
