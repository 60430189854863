@import '../../styles.scss';

.Search {
    &__results {
        margin: 2px;
        display: flex;
        flex-flow: column nowrap;
        background-color: #142326;
        cursor: pointer;
        &__item {
            border: 1px solid transparent;
            &__type {
                font-size: 12px;
                align-self: center;
            }
        }
    }
    &__empty {
        display: flex;
        margin: 20px;
    }
}

@media screen and (max-width: 1024px) {
    .Search__empty{
        margin: 0;
    }
}