
.odd-button {
  border-left: 1px solid #ddd;
}
.odd-button.non-background {
  border-right: 1px solid #ddd;
}

.odd-button__inner {
  font-family: 'Lato', sans-serif;
  font-style: normal;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 1px;
  min-width: 57px;
  min-height: 43px;
  &.hide-zero {
    .odd-button__price,
    .odd-button__volume {
     display: none;
    }
  }
  &--centered {
    justify-content: center;
  }
}

.odd-button__price {
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
}

.odd-button__volume {
  font-size: 9px;
  line-height: 11px;
  min-height: 11px;
  border: none !important;
  margin-top: 3px;
}

.back-color {
  background-color: var(--back-background);
  &.secondary {
    background-color: var(--back-light-background);
  }
  &.not-active {
    //background-color: var(--back-light-background);
    opacity: 0.5;
  }
  &.non-background {
    background-color: transparent;
  }
}

.lay-color {
  background-color: var(--lay-background);
  &.secondary {
    background-color: var(--lay-light-background);
  }
  &.not-active {
    //background-color: var(--lay-light-background);
    opacity: 0.5;
  }
  &.non-background {
    background-color: transparent;
  }
}
.odd-button.non-background.secondary {
    border: unset;
}

//.not-active .odd-button__inner {
//  color: var(--disabled-odd-text);
//}

.change-color {
  animation: highlight 300ms ease-out;
}

@keyframes highlight {
  from {
    background-color: var(--highlights);
  }
}

.odd-button {
  display: flex;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  position: relative;


  &.active {
    //&.back-color {
      //border: 1px solid var(--back-background);
    //}
    //&.lay-color {
      //border: 1px solid var(--lay-background);
    //}
    background: var(--white);
    border-bottom: 0 !important;
  }
}

@media (max-width: 1024px) {
  .odd-button {
    border: 1px solid #FFFFFF;
  }
  .odd-button.non-background {
    border-right: 1px solid  #FFFFFF;
  }
}
