@import '../../styles.scss';

.MoreRulesPopover {
  @include full-size-popover;

  .popover-wrapper {
    background: var(--gray);
  }

  .popover-content {
    @include popover-content-fullwidth();
  }

  &__content {
    padding: 25px 17px;
    box-sizing: border-box;
    max-height: calc(100vh - 60px);
    overflow: overlay;
  }

  &__item {
    padding: 0;
    border: 0;
    --padding-start: 0;
    --border-style: none;
  }

  &__title {
    font-size: 18px;
    margin-bottom: 25px;
    font-weight: bold;
  }

  &__subtitle {
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: 700;
  }

  &__text {
    font-size: 13px;
    margin-bottom: 16px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
  }

  &__label {
    margin-left: 5px;
    font-size: 14px !important;
    --color:  #86AA16 !important;
    &--disabled {
      --color:  var(--cta-disabled) !important;
    }
  }

  &__link {
    color:  #86AA16;
  }

  &__btn {
    @include bottom-fixed-button();
    --background: #86AA16;
    --color: #FFF;
    &.button-disabled {
      --background: var(--cta-disabled);
    }
  }

  ul {
    padding-left: 20px;
    ::marker {
      font-size: 20px;
    }
  }
}