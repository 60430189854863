.sport-buttons {
  padding: 10px 10px 0 10px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 5px;

  .sport-button {
    border-radius: 3px;
    height: 36px;
    background-color: transparent;
  }
  .sport-button__content {
    width: 100%;
    height: 100%;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 12px;
    color: #ffffff;
    position: relative;

    .sport-button__background-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .sport-button__content-text {
      position: absolute;
      top: 5px;
      left: 5px;
      display: flex;
      flex-direction: column;
      img{
        width: 16px;
        height: 16px;
      }
      span {
        text-transform: none;
      }

    }
  }
}
