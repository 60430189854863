.AccountStatementDetailsTable {
  font-size: 12px;
  font-weight: 400;
  font-family: Lato;
  color: #000000;

  ul, li, p, h6 {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  h6 {
    padding-bottom: 15px;
  }
  p {
    padding-bottom: 10px;
    .text-bold {
      cursor: pointer;
    }
  }
  &__table-title {
    font-size: 16px;
    font-weight: 800;
  }

  &__table-header {
    background-color: #DDDDDD;;
    display: grid;
    grid-template-columns: 16.88% 14.38% 18.38% 14.38% 8.2% 9.2% 9% 8.5%;
    height: 33px;
    align-items: center;
    padding: 9px 15px !important;

    li {
      color: #0F2327;
      font-weight: 700;
      white-space: nowrap;
    }
    li:not(:last-child) {
      padding-right: 15px;
    }
  }

  .info-right {
    text-align: right;
  }
  .green {
    color: #2aa033;
  }
  .red {
    //color: #d01901;
    color: #BF311C;
  }
  .text-color {
    color: #666666;
  }
  .text-bold {
    font-weight: 700;
  }

  &__table-body {
    ul:nth-child(odd) {
      background: #F0F0F0;
    }
    ul:nth-child(even) {
      background: #FFFFFF;
    }
    &-row {
      margin: 0;
      list-style: none;
      font-size: 12px;
      display: grid;
      grid-template-columns: 16.88% 14.38% 18.38% 14.38% 8.2% 9.2% 9% 8.5%;
      padding: 9px 15px !important;
      align-items: center;
      li:not(:last-child) {
        padding-right: 15px;
      }
    }
    .info-right {
      justify-content: right;
      font-weight: bold;
      white-space: nowrap;
    }
  }

  &__info {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    &-wrapper {
      width: 290px;
      background-color: #FFFFFF;
      align-items: center;
      justify-content: center;
      padding: 20px !important;
      margin-top: 45px !important;
      li {
        padding: 0 0 7px 0;
      }
    }
    &-title {
      display: inline-block;
      text-align: flex-end;
      width: 60%;
    }
    &-price {
      display: inline-block;
      padding-left: 20px;
    }
    &-price.negative-value {
      margin-left: -5px;
    }
    &-price-with-border {
      padding: 3px 0 12px 20px;
      margin-top: 5px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 95%;
        height: 100%;
        bottom: 0;
        left: 10px;
        border-bottom: 1px solid;
        border-top: 1px solid;
      }
    }
  }
}
