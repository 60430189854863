.favourites-header__rules-button-wrapper {
  margin: 0 25px 0 auto;
  width: 50px;
  font-family: 'Lato';
  font-weight: 700;
  font-size: 13px;
  color: #142326;
  cursor: pointer;
  .favourites-header__rules-button-icon {
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
  }
  .favourites-header__rules-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .favourites-header__rules-button-text {
    line-height: 16px;
  }
}

.rules-popup {
  .rules-popup__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;

    // pointer-events: all;
  }

  .rules-popup__wrapper{
    background: #FFFFFF;
    border-radius: 5px;
    width: 100%;
    max-width: 940px;
    padding: 20px;
    max-height: 65%;
    // overflow-x: auto;
    // overflow: hidden;

    pointer-events: all;
  }
  .rules-popup__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

  }
  .rules-popup__rules-button-icon {
    margin-right: 7px;
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rules-popup__header-text {
    font-family: 'Lato';
    font-weight: 700;
    font-size: 13px;
    color: #142326;
    display: flex;
    align-items: center;
  }
  .rules-popup__close-button {
    width: 25px;
    height: 25px;
    position: relative;
    top: -10px;
    cursor: pointer;
  }
  .rules-popup__content {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: #000000;
  }

    .rulesContainer {
      max-height: 355px;
      overflow-x: auto;
      padding-bottom: 30px;
      // overflow-y: scroll;

      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;

      color: #000000;

      img {
        display: block;
        width: 25%;
        height: auto;
        margin: 15px auto;
      }

      table {
        border-collapse: collapse;
        width: 100%;
      }

      td,
      th {
        border: 1px solid rgb(0, 0, 0);
        padding: 5px;
        width: 50%;
        // padding-left: 10px;
      }

      .twoCardTeenpattiTable {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
}
@media screen and (max-width: 1024px) {
  .rules-popup .rules-popup__wrapper{
    width: 80%;
    // height: 80%;
    overflow-x: auto;
    max-height: 80%;
  }
  .rules-popup .rulesContainer{
    // max-height: unset;
  }
  .rules-popup .rulesContainer img{
    width: 50%;
  }
}
@media screen and (min-width: 1024px) {
  .rules-popup .rules-popup__wrapper{
    overflow: hidden;
  }
}

.rulesContainer::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 20px;
}
.rulesContainer::-webkit-scrollbar-thumb {
  -webkit-border-radius: 20px;
  border-radius: 10px;
  background: var(--primary);;
  -webkit-box-shadow: inset 0 0 6px #818181;
}
.rulesContainer::-webkit-scrollbar-thumb:window-inactive {
  background: #818181;
}
.rulesContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #818181;
  -webkit-border-radius: 20px;
  border-radius: 10px;
}
