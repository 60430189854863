.bettingProfitAndLossDetailsTable {
  font-family: 'Lato';
  &__table-breadcrumbs {
    font-weight: 400;
    font-size: 12px;
    margin: 30px 0 10px 0;
    span:first-child {
      font-weight: 700;
      cursor: pointer;
      transition: 0.3s;
    }
    span:first-child:hover {
      opacity: 0.7;
    }
  }
  &__table-header {
    margin: 0;
    list-style: none;
    font-weight: 700;
    font-size: 12px;
    background-color: #DDDDDD;
    display: grid;
    grid-template-columns: 16.38% 16.38% 16.38% 16.38% 8.2% 8.2% 9% 9%;
    padding: 9px 15px;
    align-items: center;
    li:not(:last-child) {
      padding-right: 15px;
    }
  }
  &__table-body-row:nth-child(odd) {
    background: #F0F0F0;
  }
  &__table-body-row:nth-child(even) {
    background: #FFFFFF;
  }
  &__table-body-row {
    margin: 0;
    list-style: none;
    font-size: 12px;
    display: grid;
    grid-template-columns: 16.38% 16.38% 16.38% 16.38% 8.2% 8.2% 9% 9%;
    padding: 9px 15px;
    align-items: center;
    li:not(:last-child) {
      padding-right: 15px;
    }
  }
  .info-right {
    text-align: right;
  }
  .text-bold {
    font-weight: 700;
  }
  li.green {
    color: #539f45;
  }
  li.red {
    color: #C0331E;
  }
  &__info-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-top: 45px;
  }
  &__info {
    font-weight: 400;
    font-size: 12px;
    list-style: none;
    padding: 25px 0;
    background: #ffffff;
    width: 100%;
    max-width: 290px;
    //margin: 45px 0 0 auto;
    align-items: center;
    justify-content: center;
    li {
      padding: 0 0 5px 0;
    }
  }
  &__info-title {
    display: inline-block;
    text-align: end;
    width: 60%;
  }
  &__info-price {
    display: inline-block;
    padding-left: 20px;
  }
  &__info-price.negative-value {
    margin-left: -5px;
  }
  &__info-price-with-border {
    padding: 3px 0 12px 20px;
    margin-top: 5px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 95%;
      height: 100%;
      bottom: 0;
      left: 10px;
      border-bottom: 1px solid;
      border-top: 1px solid;
    }
  }
}
