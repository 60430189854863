.ion-page.ion-page-scorecard {
    justify-content: flex-start;
}
.Scorecard {
    overflow: auto;
    &__table {
        display: block;
        color: black;
        background-color: var(--white);
        padding: 16px 8px;
        margin: 5px 0 8px;
        font-size: 11px;
        cursor: default;

        table {
            width: 100%;
        }
    }

    &__name {
        color: var(--ion-color-primary, #3880ff);
    }

    th, td {
        text-align: center;
        padding: 8px;
        border-bottom: 1px solid var(--border);
        @media (max-width: 360px) {
            padding: 8px 4px;
        }
        &:first-child {
            text-align: left;
        }

        a {
            text-decoration: none;
        }
    }

    &__extras {
        padding: 6px 8px 2px;
        color: var(--black);

        &__title {
            font-weight: 700;
        }
    }
}

// scorecardTopInfo
.scorecardTopInfo {
    background: var(--event-info-background-color);
    color: #fff;
    margin: 140px 0px 0 0px;
    width: 100%;
    z-index: 999;
    position: relative;

    .scorecardTopInfo__inner-wrapper {
        padding: 10px 10px 0 10px;
        margin-bottom: 10px;
    }
    .scorecardTopInfo__back-button-title {
        display: flex;
    }
    .scorecardTopInfo__title {
        margin: 0;
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
    }
    .scorecardTopInfo__content {
        display: flex;
    }
    .scorecardTopInfo__back-button {
        width: 19px;
        display: flex;
        align-items: center;
        margin-right: 15px;
    }
    .scorecardTopInfo__teamsScore,
    .scorecardTopInfo__content-additional {
        font-family: 'Lato';
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
    }
    .scorecardTopInfo__content-additional {
        padding-left: 35px;
    }
    .ion-toolbar__wrapper.toolbar__tabs {
        width: 100%;
    }
    .toolbar__tabs__bar {
        width: 100%;
    }
}
