.oneClickToggle {
  --background-checked: #fff;
  --background: #fff;
  --handle-background-checked: var(--negative);
  --handle-background: #fff;
  --handle-box-shadow: 0px 0px 0px 1px #969696;
  --handle-height: 23px;
  --handle-width: 23px;
  --handle-max-height: 23px;
  height: 23px;
  width: 55px;
  border: 1px solid #969696;
  border-radius: 999px;
  padding: 0;
  padding-inline: 0;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &::after {
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    position: absolute;
    content: "off";
    padding: 0 6px;
    color: var(--gray);
    text-transform: uppercase;
  }

  &.toggle-checked {
    justify-content: flex-start;
    --handle-box-shadow: none;
    border: none;

    &::after {
      content: "on";
      color: var(--negative);
    }
  }
}