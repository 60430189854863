.verification {
  margin: 10px 10px 0 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  &.shake {
    animation: shake 0.1s infinite;
  }

  @keyframes shake {
    0% {
      transform: translateX(-10px);
    }

    100% {
      transform: translateX(10px);
    }
  }

  .verification-button {
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    text-transform: none;
    margin: 0 0 0 auto;
    color: #FFFFFF;
    font-family: Lato !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    width: 100%;

    &.button-disabled {
      opacity: 1;
      --background: #1E8067;
      border-color: var(--cta-disabled) !important;
    }

    img {
      margin-right: 5px;
    }

    &.deposit {
      --background: #17C964;
    }

    &.submit {
      align-self: center;
      color: #F9DF0F;
      height: 35px;
    }

    &.submit-phone {
      align-self: center;
      color: #F9DF0F;
      height: 35px;
      max-width: 100px;
    }
  }

  .verification-input {
    border-radius: 3px;
    width: 100%;
    position: relative;
    display: flex;
    background-color: #F1F1F1;
    height: 45px;
    padding: 0 10px;
    align-items: center;
    position: relative;

    .verification-input-done {
      color: #1E8067;
      text-align: center;
      font-family: Lato;
      font-size: 14px;
      font-weight: 700;
      line-height: 14px; /* 100% */
      text-transform: capitalize;
      display: flex;
      gap: 5px;
      width: auto;
      margin-right: 10px;
    }

    .countryPlaceholder {
      color: #9E9E9E;
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;

      &.active {
        color: #1C1C1C;
      }
    }

    .verificationInput {
      color: #1C1C1C;
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      padding-left: 10px;
      border: unset;
      width: 100%;
      height: 100%;
      border: unset;
      background-color: transparent;
      position: relative;

      &:after {
        content: 'OFF';
        position: relative;
        top: 4px;
        right: 5px;
        z-index: 0;
        float: right;
        transition-delay: 0.1s;
        color: #656565;
        font-size: 11px;
      }

      &:focus-visible {
        outline: none;
        border: unset;
      }
    }
  }

  .verification-error {
    color: #F3123F;
    font-family: Lato;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
  }

  .verification-title {
    text-align: center;
    color: #1C1C1C;
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;

    &.left {
      width: 100%;
      text-align: left;
    }

    span {
      color: #F3123F;
      font-size: 12px;
      font-weight: 400;
    }
  }


  .verification__main {
    border-radius: 8px;
    background: linear-gradient(90deg, #FFF 50%, #F5DBA0 100%);
    display: flex;
    width: 100%;
    padding: 12px 20px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    position: relative;

    &-new {
      display: flex;
      padding: 4px 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 17px;
      background: #FF7A40;
      position: absolute;
      color: #FFFFFF;
      font-family: Roboto;
      font-size: 10px;
      font-weight: 700;
      left: 20px;
      top: -8px;
    }

    .verification__main-title {
      text-align: center;
      color: #505050;
      font-family: Lato;
      font-size: 12px;
      font-weight: 700;
    }

    .verification__main-buttons {
      display: flex;
      gap: 8px;
      width: 100%;
      justify-content: center;
    }
  }

  .verification-number {
    border-radius: 8px;
    background: white;
    display: flex;
    width: 100%;
    padding: 12px 20px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .verification-otp {
    border-radius: 8px;
    background: white;
    display: flex;
    width: 100%;
    padding: 12px 20px;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .verification-otp-input-wrapper {
      width: 100%;
    }

    &-otpBox {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 3fr;
      column-gap: 7px;

      &-item {
        border-radius: 6px;
        width: 100%;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: #F1F1F1;
        border: unset;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;

        &::placeholder {
          color: #9E9E9E;
          font-size: 1.5em;
        }

        &:focus-visible {
          outline: none;
          border: 1px solid var(--primary);
        }
      }
    }

    .verification-otp-resend-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &-resend {
      color: #9E9E9E;
      font-family: Lato;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;

      .active {
        color: #1E8067;
        font-family: Lato;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }

  .verification-finish {
    border-radius: 8px;
    background: white;
    display: flex;
    width: 100%;
    padding: 12px 20px;
    align-items: center;
    gap: 8px;

    span {
      color: #1E8067;
      text-align: center;
      font-family: Lato;
      font-size: 14px;
      font-weight: 700;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  .PhoneInput {
    input {
      display: none !important;
    }

    &.arrowDisabled {
      .PhoneInputCountrySelectArrow {
        display: none;
      }
    }
  }
}
