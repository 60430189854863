.MenuEvent {
  --padding-start: 4px;
  --padding-end: 4px;

  &__list {
    &.in-play {
      margin-top: 12px;
    }

    background: transparent;

    &__subtitle {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-size: 14px;
      min-height: unset;
      margin: 5px 0;
    }

    &__header {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-size: 18px;
      min-height: unset;
      margin: 11px 0;

      &__green {
        color: #257b24;
        margin: 0 16px 11px;

        &__name {
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }

    &__event {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      height: 40px;
      cursor: pointer;
      background: transparent;
      margin: 2px 0;
      --border-width: 1px;
      --border-style: solid;
      --border-color: transparent;
      --min-height: 100%;
      min-width: 0;

      .more {
        display: flex;
        justify-content: center;
        width: 100%;
        position: relative;
        font-size: 10px;

        &::after {
          top: 1px;
          margin-left: 15px;
          content: ' ';
          position: relative;
          width: 5px;
          height: 5px;
          border: 2px solid #000;
          border-top: none;
          border-left: none;
          transform: rotate(45deg);
        }
      }

      &__name {
        text-overflow: ellipsis;
        word-break: keep-all;
        overflow: hidden;
        white-space: nowrap;
        display: flex;
        flex-flow: column nowrap;
      }

      &__in-play-icon {
        margin-left: auto;
      }
    }
  }

  .apl-icon-custom-play:before {
    font-family: 'apollo';
    content: 'ꀆ';
    color: var(--in-play);
    position: relative;
    font-weight: normal;
    top: -3px;
  }

  .apl-icon-custom-live:before {
    font-family: 'apollo';
    content: 'ꀆ';
    color: var(--in-play);
    position: relative;
    font-size: 16px;
    font-weight: normal;
    right: 4px;
  }
}
