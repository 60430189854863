.VerifyPopUp {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);

  &_content {
    width: 95%;
    background: #ffffff;
    position: absolute;
    border-radius: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    padding: 26px 18px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    &_header {
      position: absolute;
      top: 18px;
      right: 14px;
      &_title {
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        color: #000000;
        margin-bottom: 10px;
      }
    }
    &_subTitle {
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      color: #1c1c1c;
      &.mob {
        color: #676b72;
      }
    }
    &_otpBox {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 7px;
      margin: 8px 0;
      &-item {
        border-radius: 4px;
        background-color: #f1f1f1 !important;
        border: unset !important;
        width: 25% !important;
        height: 43px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: Roboto;
        width: 600px;
        font-size: 16px;
        &:focus-visible {
          outline: none;
          border: 1px solid var(--primary);
        }
      }
    }
    &_numberBox {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 8px;
      gap: 6px;
      &-number {
        font-family: Lato;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        border-bottom: 1px solid #676b72;
        color: #212121;
        line-height: 18px;
        padding: 6px 20px;
      }
    }
    &_otpBtns {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: Lato;
      font-size: 12px;
      font-weight: 600;
      &_resendBtn {
        color: var(--primary);
        font-family: Lato;
        font-size: 12px;
        font-weight: 600;
        text-decoration: underline;
        cursor: pointer;
        &.otpError {
          color: #f3123f;
          text-decoration: none;
        }
      }
    }
    &_btn {
      width: 100%;
      height: 40px;
      border-radius: 2px;
      background: var(--primary);
      color: #f9df0f;

      font-weight: 700 !important;
    }
  }

  // .popover-content {
  //   @include popover-content-center();
  //   overflow: hidden;
  //   font-size: 14px;
  // }
}

@media screen and (min-width: 1024px) {
  .LadderPopover-wrapper-content {
    width: 40%;
  }
}
