.inplay-popular-header {
  height: 42px;

  display: flex;
  align-items: center;

  justify-content: space-between;
  padding: 0 10px 0 10px;
}

.inplay-header-color {
  background-color: var(--in-play-block-color);
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.popular-header-color {
  background-color: var(--most-popular-block-color);
}
.future-header-color {
  background-color: #c2af51;
}

.inplay-header-icon:before {
  font-family: 'apollo';
  content: '\F144';
}

.inplay-popular-header__logo {
  display: flex;
  align-items: center;
}

.inplay-popular-header__logo-icon {
  font-size: 20px;

  width: 24px;
  height: 32px;

  font-style: initial;

  padding-right: 3px;
  color: var(--white);
}

.popular-header-icon {
  height: 26px;

  &:before {
    font-family: 'apollo';
    content: '\E903';
  }
}

.inplay-popular-header__logo-text {
  font-family: 'Lato', sans-serif !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 21.6px !important;

  color: var(--white);
}

.inplay-popular-header__bets {
  display: flex;
  align-items: center;
}

.inplay-popular-header__bets-text {
  font-family: 'Lato', sans-serif !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 13px !important;
  line-height: 15.6px !important;
  color: var(--white);
}

.inplay-popular-header__bets-count {
  background-color: var(--open-bets-buble-bg);

  width: 21px;
  height: 21px;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;

  border-radius: 50%;

  position: relative;

  top: -11px;
  left: -2px;

  font-family: 'Lato', sans-serif !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 13px !important;
  line-height: 16px !important;

  color: var(--white);
}