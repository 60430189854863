@media (min-width: 1024px) {
  .betting-profit-and-loss-page__page-menu-filter-content-error {
    position: absolute;
    bottom: -20px;
    left: 15px;
    font-size: 12px;
    color: #d01901;
  }

  .page-title {
    color: black;
  }

  .page-title-error {
    position: absolute;
    bottom: -20px;
    left: 0;
    font-size: 12px;
    color: #d01901;
  }

  .betting-profit-and-loss-page__item-list {
    margin: 0 4px 0 4px;
  }

  .betting-profit-and-loss-page {
    font-family: 'Lato';
    color: #000000;

    .page-title {
      padding-bottom: 15px;
      border-bottom: 1px solid #9AA1A4;
      font-size: 24px;
      width: 100%;
      max-width: calc(100% - 292px);
    }

    &__filters-sports-buttons-total {
      padding: 0 15px;
      margin-bottom: 30px;
      display: flex;
      align-items: flex-end;
    }

    &__filters-sports-buttons-list {
      margin: 0 15px 0 0;
      padding: 0;
      list-style: none;
      display: flex;
      color: #000000;
      font-size: 12px;

      li:not(:first-child) {
        padding: 0 18px;
      }

      li:first-child {
        padding-right: 18px;
      }

      li:not(:last-child) {
        border-right: 2px solid #DDDDDD;
      }
    }

    &__filters-sport-button-name {
      font-weight: 400;
    }

    &__filters-sport-amount {
      font-weight: 700;
    }

    &__filters-sport-button {
      cursor: pointer;

      .positive-amount {
        color: #519e43;
      }

      .negative-amount {
        color: #BF311C;
      }
    }

    &__filters-total {
      cursor: pointer;
      font-weight: 700;
      font-size: 16px;

      .positive-amount {
        color: #519e43;
      }

      .negative-amount {
        color: #BF311C;
      }
    }

    &__filters-sport-button {
      position: relative;
    }

    &__filters-sport-button:after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 18px;
      right: 18px;
      height: 1px;
      background: #000000;
    }

    &__filters-sport-button:first-child:after {
      left: 0;
    }

    &__filters-sport-button.active-button:after {
      background: transparent;
    }

    &__filters-total {
      position: relative;
    }

    &__filters-total:after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0px;
      right: 0px;
      height: 1px;
      background: #000000;
      z-index: 1;
    }

    &__filters-total.active-button:after {
      background: transparent;
    }

    &__table {
      padding: 0 15px;
    }

    &__table-head {
      list-style: none;
      margin: 0;
      padding: 9px 15px;
      background: #dddddd;
      font-weight: 400;
      font-size: 12px;
      display: grid;
      grid-template-columns: 58% 15% 15% 6% 6%;
      align-items: center;

      li:not(:last-child) {
        padding-right: 15px;
      }

      li:last-child {
        text-align: right;
      }

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  // Date Inputs, Button, and Date Popup
  .betting-profit-and-loss-page {
    &__content--empty {
      background-color: #F0F0F0;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 60px;
      font-size: 12px;
    }

    &__filters-date {
      margin-bottom: 45px;
      width: 100%;
      max-width: 360px;
      padding: 0 15px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      position: relative;

      p {
        margin: 0 0 10px 0;
        font-family: 'Lato';
        font-weight: 400;
        font-size: 12px;
      }

      ion-item {
        padding: 0 !important;
        padding-inline-start: 0 !important;
        padding-inline-end: 0 !important;
        --padding-start: 0px !important;
        --inner-padding-end: 0 !important;
        --padding-top: 0 !important;
        --padding-end: 0 !important;
        --padding-bottom: 0 !important;
        --min-height: 30px;

        .item-native {
          padding: 0 !important;
          padding-inline-start: 0 !important;
          padding-inline-end: 0 !important;
          --padding-start: 0 !important;
          --inner-padding-end: 0 !important;
        }
      }

      ion-datetime {
        --padding-start: 20px !important;
        --inner-padding-end: 0 !important;
        --padding-top: 0 !important;
        --padding-end: 0 !important;
        --padding-bottom: 0 !important;
        font-family: 'Lato';
        font-weight: 400;
        font-size: 11px;
        color: #686868;
      }

      .item-datetime {
        width: 100px;
        height: 30px;

        .in-item {
          width: 100%;
        }

        .item-native {
          padding-left: 0;
        }

        button {
          width: 100%;
        }
      }
    }

    &__date-search-button {
      background: var(--cta-primary-darken);
      border-right: 2px solid rgba(0, 0, 0, 0.25);
      border-bottom: 2px solid rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      width: 73px;
      height: 30px;
      font-weight: 700;
      font-size: 12px;
      color: #FFFFFF;
      padding: 0 5px;
      display: inline-flex;
      align-items: center;
      justify-content: space-evenly;

      &:hover {
        background-color: var(--secondary);
        box-shadow: 1.5px 1.5px 0px 0px var(--cta-primary-darken);
      }

      &:active {
        background-color: var(--secondary);
        border-top: 2px solid rgba(0, 0, 0, 0.25);
        border-left: 2px solid rgba(0, 0, 0, 0.25);
        box-shadow: none;
      }
    }

    &__date-search-button:disabled {
      background: #DDDDDD;
      box-shadow: none;
    }
  }

  ion-backdrop {
    --ion-backdrop-color: rgba(0, 0, 0, 0.3) !important;
  }

  ion-picker {
    transform: none !important;

    .picker-wrapper.sc-ion-picker-md {
      top: 30%;
      bottom: auto;
    }
  }
  // End Date Inputs, Button, and Date Popup
}

@media (max-width: 1024px) {
  .page-title {
    color: black;
    // margin: 32px 0 13px 18px;
  }
  .betting-profit-and-loss-page__item-list {
    margin: 0 4px 0 4px;
  }

.betting-profit-and-loss-page__content--empty {
  margin: 0 15px;
}

}

.react-calendar{
  width: 327px !important;
  button{
    color: black;
  }
  &__navigation{
    background-color: #0f2327;
    margin-bottom: 0rem !important;
    button{
      color: white;
      &:hover{
        background-color: #163238 !important;
      }
      &:focus{
        background-color: #163238 !important;
      }
    }
  }
  &__month-view__weekdays{
    background-color: #398696;
    color: white;
  }
  abbr[title] {
    border-bottom: none !important;
    cursor: inherit !important;
    text-decoration: none !important;
  }
}

.react-date-picker__inputGroup{
  width: 100px !important;
  height: 29px;
  padding-left: 15px;
  width: 120px;
  .react-date-picker__inputGroup__leadingZero,
  .react-date-picker__inputGroup__input {
    color: #686868;
    font-weight: 400;
  }
  .react-date-picker__inputGroup__input::-webkit-input-placeholder {
    color: #686868;
  }
  .react-date-picker__inputGroup__input::-moz-placeholder {
    color: #686868;
  }
}


.react-date-picker__inputGroup__year{
  width: 39px !important;
}


.react-calendar__month-view__days__day--weekend {
  color: #d10000 !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575 !important;
}

// date picker style fix
.react-date-picker__wrapper {
  border: 1px solid #DDDDDD;
  background: #ffffff;
  font-size: 11px;
}
.react-date-picker__inputGroup {
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  input {
    outline: none;
    cursor: pointer;
  }
}
.react-date-picker__inputGroup__divider {
 pointer-events: none;
}

