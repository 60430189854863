.rules-popupSportEvents {
  .rules-popupSportEvents__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;

    // pointer-events: none;
    pointer-events: all;
  }

  .rules-popupSportEvents__wrapper {
    // background: #FFFFFF;
    // border-radius: 5px;
    // width: 100%;
    // max-width: 940px;
    // padding: 20px;
    // max-height: 65%;
    // // overflow-x: auto;
    // overflow: hidden;

    // pointer-events: all;

    height: 100%;
    display: flex;
    flex-direction: column;

    position: absolute;
    width: auto;
    max-width: 80%;
    max-height: 80%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    color: #0f2327;
    background: #fff;
    z-index: 2000;
  }

  .rules-popupSportEvents__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  // .rules-popupSportEvents__rules-button-icon {
  //   margin-right: 7px;
  //   width: 14px;
  //   height: 14px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }

  .rules-popupSportEvents__header-text {
    // font-family: 'Lato';
    // font-weight: 700;
    // font-size: 13px;
    // color: #142326;
    // display: flex;
    // align-items: center;
    padding: 15px 0;
    margin-bottom: -1px;
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #bababa;
    // margin-left: auto;
    // margin-right: auto;
    width: 100%;
  }

  .rules-popupSportEvents__close-button {
    // width: 25px;
    // height: 25px;
    // position: relative;
    // top: -10px;
    // cursor: pointer;
    // padding-right: 15px;
    position: absolute;
    top: 20px;
    right: 20px;
    // font-size: 40px;
    cursor: pointer;
  }

  .rules-popupSportEvents__content {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: #000000;
    overflow-y: auto;
  }

  .rulesContainer {
    // max-height: 355px;
    // max-height: 90%;

    // overflow-y: scroll;
    // overflow-y: auto;
    padding: 0 13px 13px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;

    color: #000000;

    // &.generalFont {
    //   font-family: 'Lato, Avenir, Verdana, Nunito'!important;
    // }
  }

    .rulesTitle {
      font-family: 'Lato';
      // font-weight: 700;
      // font-size: 13px;
      // color: #142326;
      // display: flex;
      // align-items: center;
        font-size: 18px;
        font-weight: bold;
        padding: 15px 13px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
  
    .arrowIcon {
      width: 15px;
      height: 15px;
      margin-right: 15px;
      transform: rotate(180deg);
    }
  
    .arrowIconRot {
      width: 15px;
      height: 15px;
      margin-right: 15px;
      // transform: rotate(180deg);
    }

      .rules-accordion__icon {
        width: 19px;
        height: 19px;
        margin-right: 10px;
        border-radius: 50%;
        border: 1px solid #bababa;
        position: relative;
        display: inline-block;
        vertical-align: bottom;
         &::before {
          content: '';
          width: 5px;
          height: 5px;
          border-top: 1px solid #bababa;
          border-left: 1px solid #bababa;
          position: absolute;
          left: 6px;
          top: 6px;
          transition: transform 0.3s linear;
          transform: rotate(45deg);
         }
      }

      .rules-accordion__iconRot {
        width: 19px;
          height: 19px;
          margin-right: 10px;
          border-radius: 50%;
          border: 1px solid #bababa;
          position: relative;
          display: inline-block;
          vertical-align: bottom;
        
          &::before {
            content: '';
            width: 5px;
            height: 5px;
            border-top: 1px solid #bababa;
            border-left: 1px solid #bababa;
            position: absolute;
            left: 6px;
            top: 6px;
            transition: transform 0.3s linear;
            transform: rotate(-135deg);
          }
        
      }
  
    .show {
      display: block;
      height: 100%;
      // padding-top: 10px;
      // transition: all 0.8s linear;
      animation: sss 0.5s linear;
  
      .rulesContainer {
        div {
          white-space: break-spaces !important;
          font-family: 'Lato, Avenir, Verdana, Nunito' !important;
          font-size: 14px!important;
          font-weight: normal!important;
          line-height: 17px!important;
          color: #0f2327!important;
        }
        span {
          color: #0f2327 !important;
        }
      }
    }

    @keyframes sss {
      0% {
        opacity: 0;
        height: 0px;
      }
      100% {
        opacity: 1;
        height: 600px;
      }
    }
  
    .showFancy {
      display: block;
      height: 100%;
      // padding-top: 10px;
      // transition: all 0.8s linear;
      animation: sss 0.5s linear;

      .rulesContainer {
          div {
            font-family: 'Lato, Avenir, Verdana, Nunito' !important;
            font-size: 14px !important;
            font-weight: normal!important;
            line-height: 17px!important;
            color: #0f2327!important;
          }
        }
    }
  
    .notShow {
      // display: none;
      opacity: 0;
      top: -100%;
      // transition: all 0.8s linear;
    }
  
    .dropdown {
      // border-top: 1px solid grey;
      // border-bottom: 1px solid grey;
      // padding-left: 5px;
      // margin-bottom: 3px;
          margin-bottom: 4px;
            border-top: 1px solid #bababa;
            border-bottom: 1px solid #bababa;
            background: linear-gradient(#fff, #fafafa);
    }
  
    .dropdownFancy {
      // margin-top: 10px;
  
      // border-top: 1px solid grey;
      // border-bottom: 1px solid grey;
      // padding-left: 5px;
      margin-bottom: 4px;
      border-top: 1px solid #bababa;
      border-bottom: 1px solid #bababa;
      background: linear-gradient(#fff, #fafafa);
    }

    .rules-time {
      font-size: 16px;
      padding-left: 13px;
      margin-bottom: 15px;
    }
    .rules-time__title {
      font-weight: bold;
    }
    .rules-time__date {
      margin: 5px 0;
    }
}

