.casino-provider-container {
    padding: 12px;
    position: relative;
    
    .casino-provider-header-list {
        overflow: hidden;

        ul {
            white-space: nowrap;
            display: block;
            width: auto;
            padding: 0;
            border: 1px solid #1e8067;
            margin-bottom: 5px;
            width: stretch;
            width: -webkit-fill-available;
            overflow: overlay;
        }
        ul, li {
            display: inline-block;
            border-right: 1px solid #1e8067;
        }
        a {
            border-radius: 0 !important;
            color: #000;
            text-transform: capitalize;
            font-size: 12px;
            text-decoration: none;
        }
        a:hover{
            background: #1e8067;
            color: #fff;
        }
        .nav-link {
            display: block;
            padding: .5rem 1rem;
        }

        .nav-link.selected {
            background: #1e8067 !important;
            color: #fff !important;
        }
    }

    .casino-games-container {
        margin: 0;
        width: 100%;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        min-height: 300px;
        position: relative;
        
        .casino-game-box {
            padding: 2px;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 25%;
            flex: 0 0 25%;
            max-width: 25%;
            position: relative;
            width: 100%;
            min-height: 1px;
            cursor: pointer;
            transition: transform 0.3s ease-in-out;

            .casino-icon-box {
                width: 100%;
                display: inline-block !important;

                img {
                    height: 120px;
                    object-fit: unset;
                    width: 100%;
                    max-width: 100%;
                }

                .casino-name {
                    color: #fff;
                    display: block;
                    border-radius: 0;
                    position: relative;
                    font-size: 10px;
                    text-transform: uppercase;
                    background: #1e8067;
                    padding: 6px;
                    font-weight: 600;
                    width: 70%;
                    left: 0;
                    z-index: 9;
                    bottom: 0;
                    top: -4px;
                    text-align: center;

                    &::before {
                        content: "";
                        clip-path: polygon(0 -1px, 100% -1px, 1px 100%, 0 100%);
                        width: 21px;
                        height: calc(100% + 0px);
                        background: linear-gradient(180deg, #1e8067 0, #1e8067);
                        position: absolute;
                        right: -20px;
                        top: 0;
                        // height: 19px;
                        box-sizing: border-box;
                    }
                }
            }
            &:hover {
                transform: scale(1.03);
            }
        }

    }

    .casino-spinner-cont {
        position: absolute;
        inset: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.3);

        ion-spinner {
            width: 3em;
            height: 3em;
        }
    }
}

.spinner-container {
    position: absolute;
    inset: 0;
    z-index: 10;
    display: flex;
    // align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.05);
}

@media (max-width: 1024px) {

    .casino-provider-header-list {
        li {
            width: 120px;
            text-align: center;
        }
    }

    .casino-games-container {
        .casino-game-box {
            -ms-flex: 0 0 33% !important;
            flex: 0 0 33% !important;
            max-width: 33% !important;
        }
    }
}


@media (max-width: 600px) {
    .casino-games-container {
        .casino-game-box {
            .casino-icon-box {
                img {
                    height: auto !important;
                    aspect-ratio: 3/2 ;
                }
            }
        }
    }
}