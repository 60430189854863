@mixin popover-content-center() {
    width: 100%;
    max-width: calc(100vw - 20px) !important;
    position: relative;
    top: unset !important;
    bottom: unset;
    left: unset !important;
    padding: 14px;
    border-radius: 1px;
    margin: auto;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

@mixin popover-content-centerr() {
    width: 100%;
    max-width: calc(100vw - 20px) !important;
    position: relative;
    top: unset !important;
    bottom: unset;
    left: unset !important;
    border-radius: 1px;
    margin: auto;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

@mixin popover-content-fullwidth() {
    width: 100%;
    height: 100%;
    position: relative;
    top: unset !important;
    bottom: unset;
    left: unset !important;
    border-radius: 1px;
    margin: auto;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

@mixin full-size-popover() {
    width: 100%;
    height: 100%;
    --max-height: 100%;

    .popover-wrapper {
        width: 100%;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}

@mixin full-width-button($height: 32px,
    $borderColor: none,
    $font-size: 13px,
) {
    --box-shadow: 0;
    width: 100%;
    height: $height;
    border: 1px solid $borderColor;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 3px;
    --border-radius: 3px;
    font-size: $font-size !important;
    text-transform: none;
}

@mixin bottom-fixed-button($height: 57px,
    $font-size: 24px,
) {
    width: 100%;
    height: $height;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    --border-radius: 0;
    font-size: $font-size !important;
    text-transform: none;
    position: fixed;
    bottom: 0;
}

@mixin menu-item() {
    -webkit-padding-end: 15px !important;
            padding-inline-end: 15px !important;
    -webkit-padding-start: 15px !important;
            padding-inline-start: 15px !important;
    --padding-start: 0;
    cursor: pointer;

    &:first-child {
        padding-top: 10px !important;
    }


    &__icon {
        width: 14px !important;
        height: 20px;
        margin-right: 5px;
    }

    &__label {
        font-family: 'Lato', sans-serif !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 16px !important;
        line-height: 19px !important;
        padding-left: 11px;
        color: var(--primary);
    }

    &__label_Whatsapp {
        font-family: 'Lato', sans-serif !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 16px !important;
        line-height: 19px !important;
        color: var(--primary);
    }
}
