@media (min-width: 1025px) {
  .betting-pnl-item {
    background: var(--white);
    color: var(--black);
    display: grid;
    grid-template-columns: 58% 15% 15% 6% 6%;
    list-style: none;
    margin: 0;
    padding: 9px 15px;
    li {
      height: 15px;
      display: flex;
      align-items: center;
      .comm__value,
      .net-win__value {
        font-weight: 700;
      }
    }
    li:not(:last-child) {
      padding-right: 15px;
    }
    li:last-child {
      justify-content: flex-end;
    }
  }
  .betting-pnl-item__cell__value {
    font-family: 'Lato', sans-serif !important;
    color: var(--black);
    font-weight: 400;
    font-size: 12px;
  }
  .betting-pnl-item__title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 15px;
    cursor: pointer;
    &:hover {
      opacity: .7;
    }
  }
  .pnl-item-value--green {
    color: var(--positive);
  }
  .pnl-item-value--red {
    color: var(--negative);
  }
  .pnl-item-value--black {
    color: var(--black);
  }
}

@media (max-width: 1024px) {
  .betting-pnl-item {
    background: var(--white);

    color: var(--black);

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    padding: 11px 11px 15px 12px;
    margin-top: 3px;
  }

  .betting-pnl-item+.betting-pnl-item {
    margin-top: 4px;
  }

  .betting-pnl-item__left-side {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 60%;
  }

  .betting-pnl-item__title {
    font-family: 'Lato', sans-serif !important;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;

    -webkit-box-align: center;

    -ms-flex-align: center;

    align-items: center;
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;

    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: block;

    color: var(--back-color);

    padding-right: 15px;
    cursor: default;

    &:hover {
      opacity: .7;
    }
  }

  .start-time {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .start-time__title {
    font-family: 'Lato', sans-serif !important;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    color: var(--black);

    padding-right: 2px;
  }

  .start-time__value {
    font-family: 'Lato', sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--black);
  }

  .settled-time {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .settled-time__title {
    font-family: 'Lato', sans-serif !important;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--black);
    padding-right: 2px;
  }

  .settled-time__value {
    font-family: 'Lato', sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--black);
  }

  .betting-pnl-item__right-side {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 0.25;
    -ms-flex: 0.25;
    flex: 0.25;
    @media (max-width: 540px) {
      -webkit-box-flex: 0.4;
      -ms-flex: 0.4;
      flex: 0.4;
    }
  }

  .comm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .comm__title {
    font-family: 'Lato', sans-serif !important;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .comm__value {
    font-family: 'Lato', sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--black);
  }

  .net-win {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .net-win__title {
    font-family: 'Lato', sans-serif !important;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    color: var(--black);
  }

  .net-win__value {
    font-family: 'Lato', sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .pnl-item-value--green {
    color: var(--positive);
  }

  .pnl-item-value--red {
    color: var(--negative);
  }

  .pnl-item-value--black {
    color: var(--black);
  }
}


